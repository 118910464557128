import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Common from '../Common/common';
import constClass from '../../Constants/Constants';
import axios from 'axios';
import util from 'util';

const Auth = ({ siteId, page, setting, my_customer, liff_access_token, liff_user_id, setBack, setNext, setMyCustomer }) => {
  const [customerNo, setCustomerNo] = useState(my_customer.customer_no || '');
  const [customerTel, setCustomerTel] = useState(my_customer.customer_tel || '');
  const [message, setMessage] = useState({});
  const [lockData, setLockData] = useState(false);
  const history = useHistory();

  const changeCustomerNo = (e) => {
    setCustomerNo(e.target.value);
    // setMessage({ ...message, customerNo: Common.alphanumericValidation(e.target.value, true) });
  }

  const changeCustomerTel = (e) => {
    setCustomerTel(e.target.value);
    // setMessage({ ...message, customerTel: Common.mobileNoValidation(e.target.value, true) });
  }

  const blurCustomerNo = (e) => {
    setMessage({ ...message, customerNo: Common.alphanumericValidation(e.target.value, true) });
  }

  const blurCustomerTel = (e) => {
    setMessage({ ...message, customerTel: Common.mobileNoValidation(e.target.value, true) });
  }

  const checkInvalid = useCallback(() => {
    return !customerNo || !customerTel || message.customerNo || message.customerTel;
  }, [customerNo, customerTel, message]);

  const submit = useCallback(async () => {
    if (checkInvalid() || lockData) {
      return;
    }
    // API実行
    setLockData(true);
    try {
      const params = { site_id: siteId, line_id: liff_access_token, customer_no: customerNo, customer_tel: customerTel };
      const result = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/line/auth`, params)).data;
      const data = result.find(r => r.customer_no === customerNo); // 配列から1件を取得
      if (!data) {
        alert('会員番号・携帯番号が一致しません。');
        setLockData(false);
      } else if (data.status === constClass.STATUS.CCL) {
        alert('既に退会されています。');
        setLockData(false);
      } else if (data.line_id && data.line_id !== liff_user_id) {
        alert('既に別のアカウントで利用されています。');
        setLockData(false);
      } else {
        setMyCustomer(data);
        history.push(`/${siteId}/?page=${constClass.AUTHVERIFY}`);
      }
    } catch (err) {
      console.log(util.inspect(err));
      if (err.response && err.response.data) {
        alert(err.response.data);
      } else {
        alert('エラーが発生しました。');
      }
    } 
  }, [checkInvalid, lockData, customerNo, customerTel, history, liff_user_id, liff_access_token, siteId, setMyCustomer]);

  useEffect(() => {
    setBack({
      title: '戻る',
      render: false,
      disabled: lockData,
      onClick: () => {
        if (history.length <= 1) {
          history.push(`/${siteId}/`);
        } else {
          history.goBack();
        }
      }
    });
    setNext({
      title: '次へ',
      render: true,
      disabled: checkInvalid() || lockData,
      onClick: submit
    });
  }, [history, setBack, setNext, siteId, checkInvalid, lockData, submit])

  return (
    <div id="auth" className="row mx-3 my-3 px-0-env">
      <div className="col bg-white px-0">
        <div>
          {(setting['AUTH_HEADER']) &&
            <div className="customer-header row mx-0 pt-2 pb-1 text-left">
              <div className="col py-0 px-3 align-self-center">
                {Common.getBrString(setting['AUTH_HEADER'])}
              </div>
            </div>
          }
          <div className="customer-input row mx-0 pt-2 pb-1">
            <div className="col py-0 px-0 align-self-center">
              <React.Fragment>
                <div className="input-label row mx-0 px-3-env">
                  <div className="col px-0">
                    <span>会員番号</span>
                  </div>
                </div>
                <div className="input-body form-group">
                  <div className="input-control row mx-0 px-3-env text-left">
                    <div className={`col px-0 align-self-center`}><input type={'text'} className="form-control" id={'customer_no'} name={'customer_no'} value={customerNo} onChange={changeCustomerNo} placeholder={'例）○○'} maxLength={10} onBlur={blurCustomerNo}></input></div>
                  </div>
                  {message.customerNo &&
                    <div className="input-message row mx-0 px-3-env text-left">
                      <div className="col px-0 text-danger font-weight-normal">{Common.getBrString(message.customerNo)}</div>
                    </div>
                  }
                </div>
              </React.Fragment>
              <React.Fragment>
                <div className="input-label row mx-0 px-3-env">
                  <div className="col px-0">
                    <span>携帯番号(半角数字、半角ハイフン要)</span>
                  </div>
                </div>
                <div className="input-body form-group">
                  <div className="input-control row mx-0 px-3-env text-left">
                    <div className={`col px-0 align-self-center`}><input type={'text'} className="form-control" id={'customer_tel'} name={'customer_tel'} value={customerTel} onChange={changeCustomerTel} placeholder={'例）080-1234-5678'} maxLength={20} onBlur={blurCustomerTel}></input></div>
                  </div>
                  {message.customerTel &&
                    <div className="input-message row mx-0 px-3-env text-left">
                      <div className="col px-0 text-danger font-weight-normal">{Common.getBrString(message.customerTel)}</div>
                    </div>
                  }
                </div>
              </React.Fragment>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Auth;