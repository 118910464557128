import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const SpecifiedCommercial = ({ siteId, page, setting, my_customer, liff_access_token, liff_user_id, setBack, setNext, setMyCustomer }) => {
  const history = useHistory();

  useEffect(() => {
    setBack({
      title: '戻る',
      render: true,
      disabled: false,
      onClick: () => {
        if (history.length <= 1) {
          history.push(`/${siteId}/`);
        } else {
          history.goBack();
        }
      }
    });
    setNext({
      title: '次へ',
      render: false,
      disabled: false,
      onClick: () => { }
    });
  }, [history, setBack, setNext, siteId])

  return (
    <div id="specified_commercial" className="row mx-3 my-3 px-0-env">
      <div className="col bg-white px-0">
        <div>
          <div className="row mx-0 pt-2 pb-1">
            <div className="col py-0 px-0 align-self-center text-center">
              <h5>特定商取引法に基づく表記</h5>
            </div>
          </div>
          <div className="row mx-2 pt-2 pb-0">
            <div className="col py-0 px-0 align-self-center">
              販売業者
            </div>
          </div>
          <div className="row mx-2 pt-1 pb-2">
            <div className="col py-2 px-2 align-self-center border">
              グラシアス
            </div>
          </div>
          <div className="row mx-2 pt-2 pb-0">
            <div className="col py-0 px-0 align-self-center">
              販売責任者
            </div>
          </div>
          <div className="row mx-2 pt-1 pb-2">
            <div className="col py-2 px-2 align-self-center border">
              山口　雅美
            </div>
          </div>
          <div className="row mx-2 pt-2 pb-0">
            <div className="col py-0 px-0 align-self-center">
              所在地
            </div>
          </div>
          <div className="row mx-2 pt-1 pb-2">
            <div className="col py-2 px-2 align-self-center border">
              〒665-0845　兵庫県宝塚市栄町1-13-9
            </div>
          </div>
          <div className="row mx-2 pt-2 pb-0">
            <div className="col py-0 px-0 align-self-center">
              連絡先
            </div>
          </div>
          <div className="row mx-2 pt-1 pb-2">
            <div className="col py-2 px-2 align-self-center border">
              070-5266-5072
            </div>
          </div>
          <div className="row mx-2 pt-2 pb-0">
            <div className="col py-0 px-0 align-self-center">
              商品代金以外の必要金額
            </div>
          </div>
          <div className="row mx-2 pt-1 pb-2">
            <div className="col py-2 px-2 align-self-center border">
              送料
            </div>
          </div>
          <div className="row mx-2 pt-2 pb-0">
            <div className="col py-0 px-0 align-self-center">
              お支払方法
            </div>
          </div>
          <div className="row mx-2 pt-1 pb-2">
            <div className="col py-2 px-2 align-self-center border">
              クレジットカード　PayPay
            </div>
          </div>
          <div className="row mx-2 pt-2 pb-0">
            <div className="col py-0 px-0 align-self-center">
              商品のお届け時期
            </div>
          </div>
          <div className="row mx-2 pt-1 pb-2">
            <div className="col py-2 px-2 align-self-center border">
              商品がご用意でき次第発送いたします。
            </div>
          </div>
          <div className="row mx-2 pt-2 pb-0">
            <div className="col py-0 px-0 align-self-center">
              返品・交換について
            </div>
          </div>
          <div className="row mx-2 pt-1 pb-2">
            <div className="col py-2 px-2 align-self-center border">
              【返品】<br />
              商品の返品は承っておりません。ご了承ください。<br />
              【交換】<br />
              到着後の商品に不具合があった場合、下記メールアドレスにご連絡ください。<br />
              zen2west@yahoo.co.jp<br />
              <br />
              在庫を確認の上原則として商品を交換させていただきます。<br />
              なお、商品の返品及び再送に係る費用は、gRaciasにて負担いたします。<br />
            </div>
          </div>
          <div className="row mx-2 pt-2 pb-0">
            <div className="col py-0 px-0 align-self-center">
              資格・許可及び許可番号
            </div>
          </div>
          <div className="row mx-2 pt-1 pb-2">
            <div className="col py-2 px-2 align-self-center border">
              2140005023690
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SpecifiedCommercial;