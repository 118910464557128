import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import constClass from '../../Constants/Constants';
import { Link } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { CSVLink } from "react-csv";
import Select from 'react-select';
import { useParams, generatePath } from 'react-router';
import Common from '../Common/common';
import moment from 'moment';
import { useToasts } from 'react-toast-notifications';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import ja from 'date-fns/locale/ja';
registerLocale('ja', ja);

const Customer = (props) => {
  const { condition, conditionActions } = props;
  const { siteId } = useParams();
  const [customerData, setCustomerData] = useState(null);
  // const [sortOrder, setSortOrder] = useState([]); // ソート順指定
  // const [condition, setCondition] = useState({});
  // const [searchFlag, setSearchFlag] = useState(true);
  const [historyStart, setHistoryStart] = useState(null);
  const [historyEnd, setHistoryEnd] = useState(null);
  const [lockData, setLockData] = useState(false);
  const componentRef = useRef();
  const [csvData, setCsvData] = useState(null);
  const [csvHistoryData, setCsvHistoryData] = useState(null);
  const historyDataRef = useRef();
  const { addToast } = useToasts();

  const createSearchParams = useCallback(() => {
    var reg_params = {
      site_id: siteId,
      operator: "and",
      where: [{
        site_id: siteId
      }]
    }
    // 会員番号
    if (condition.customer_no) {
      // 部分一致
      reg_params.where.push({
        "operator": "substring",
        "attr": 'customer_no',
        "val": condition.customer_no
      });
    }
    // お名前
    if (condition.customer_name) {
      const values = condition.customer_name.split(/ |　/);
      // スペース区切りか確認
      if (values.length > 1) {
        // 姓 名の両方が一致する
        reg_params.where.push({
          "operator": "and",
          "where": [
            {
              "operator": "substring",
              "attr": 'customer_name_sei',
              "val": values[0]
            },
            {
              "operator": "substring",
              "attr": 'customer_name_mei',
              "val": values[1]
            }
          ]
        });
      } else {
        // 姓 名のどちらかが一致する
        reg_params.where.push({
          "operator": "or",
          "where": [
            {
              "operator": "substring",
              "attr": 'customer_name_sei',
              "val": values[0]
            },
            {
              "operator": "substring",
              "attr": 'customer_name_mei',
              "val": values[0]
            }
          ]
        });
      }
    }
    // お名前(カナ)
    if (condition.customer_kana) {
      const values = condition.customer_kana.split(/ |　/);
      // スペース区切りか確認
      if (values.length > 1) {
        // 姓 名の両方が一致する
        reg_params.where.push({
          "operator": "and",
          "where": [
            {
              "operator": "substring",
              "attr": 'customer_kana_sei',
              "val": values[0]
            },
            {
              "operator": "substring",
              "attr": 'customer_kana_mei',
              "val": values[1]
            }
          ]
        });
      } else {
        // 姓 名のどちらかが一致する
        reg_params.where.push({
          "operator": "or",
          "where": [
            {
              "operator": "substring",
              "attr": 'customer_kana_sei',
              "val": values[0]
            },
            {
              "operator": "substring",
              "attr": 'customer_kana_mei',
              "val": values[0]
            }
          ]
        });
      }
    }
    // 携帯番号
    if (condition.customer_tel) {
      // 部分一致
      reg_params.where.push({
        "operator": "substring",
        "attr": 'customer_tel',
        "val": condition.customer_tel
      });
    }
    // メールアドレス
    if (condition.customer_mail) {
      // 部分一致
      reg_params.where.push({
        "operator": "substring",
        "attr": 'customer_mail',
        "val": condition.customer_mail
      });
    }
    // お誕生日
    if (condition.customer_birthday_y || condition.customer_birthday_m || condition.customer_birthday_d) {
      var birth = '________';
      if (condition.customer_birthday_y) {
        birth = ('0000' + condition.customer_birthday_y).slice(-4) + birth.slice(4, 8);
      }
      if (condition.customer_birthday_m) {
        birth = birth.slice(0, 4) + ('00' + condition.customer_birthday_m).slice(-2) + birth.slice(6, 8);
      }
      if (condition.customer_birthday_d) {
        birth = birth.slice(0, 6) + ('00' + condition.customer_birthday_d).slice(-2);
      }
      // 入力値の完全一致
      reg_params.where.push({
        "operator": "like",
        "attr": 'customer_birthday',
        "val": birth
      });
    }
    // 返金要否
    // if (condition.refund_flag && condition.refund_flag.length > 0) {
    //   // 含む
    //   reg_params.where.push({
    //     "operator": "in",
    //     "attr": 'refund_flag',
    //     "val": condition.refund_flag.map(item => item.value)
    //   });
    // }
    // ステータス
    if (condition.status && condition.status.length > 0) {
      // 含む
      reg_params.where.push({
        "operator": "in",
        "attr": 'status',
        "val": condition.status.map(item => item.value)
      });
    }
    // 種別
    if (condition.customer_type && condition.customer_type.length > 0) {
      // 含む
      reg_params.where.push({
        "operator": "in",
        "attr": 'customer_type',
        "val": condition.customer_type.map(item => item.value)
      });
    }
    // LINE登録
    if (condition.line_id && condition.line_id.length > 0) {
      // 2件選択済みの場合は○・-両方のため条件不要
      if (condition.line_id.length < 2) {
        if (condition.line_id[0].value === constClass.FLAG.ON) {
          // line_idが登録済みのもの
          reg_params.where.push({
            "operator": "not",
            "attr": 'line_id',
            "val": null
          });
        }
        if (condition.line_id[0].value === constClass.FLAG.OFF) {
          // line_idが未登録のもの
          reg_params.where.push({
            "operator": "is",
            "attr": 'line_id',
            "val": null
          });
        }
      }
    }
    // VIP会員
    if (condition.vip_flag && condition.vip_flag.length > 0) {
      // 含む
      reg_params.where.push({
        "operator": "in",
        "attr": 'vip_flag',
        "val": condition.vip_flag.map(item => item.value)
      });
    }
    return reg_params;
  }, [siteId, condition]);

  const createCsvData = useCallback((data) => {
    const list = [];
    const head = [];
    head.push({ label: "会員番号", key: "customer_no" });
    head.push({ label: "お名前", key: "customer_name" });
    head.push({ label: "お名前(カナ)", key: "customer_kana" });
    head.push({ label: "郵便番号", key: "customer_postal_code" });
    head.push({ label: "住所1", key: "customer_address1" });
    head.push({ label: "住所2", key: "customer_address2" });
    head.push({ label: "住所3", key: "customer_address3" });
    head.push({ label: "携帯番号", key: "customer_tel" });
    head.push({ label: "メールアドレス", key: "customer_mail" });
    // head.push({ label: "お誕生日(年)", key: "customer_birthday_y" });
    head.push({ label: "お誕生日(月)", key: "customer_birthday_m" });
    head.push({ label: "お誕生日(日)", key: "customer_birthday_d" });
    // head.push({ label: "職種", key: "customer_job" });
    // head.push({ label: "入会方法", key: "method" });
    // head.push({ label: "返金要否", key: "refund_flag" });
    head.push({ label: "銀行名", key: "bank_name" });
    head.push({ label: "支店名", key: "branch_name" });
    head.push({ label: "口座種別", key: "account_type_name" });
    head.push({ label: "口座番号", key: "account_number" });
    head.push({ label: "口座名義人", key: "account_holder" });
    head.push({ label: "会員種別", key: "customer_type_name" });
    head.push({ label: "VIP会員", key: "vip_flag_name" });
    head.push({ label: "LINE_ID", key: "line_id" });
    head.push({ label: "ステータス", key: "status_name" });
    data.forEach(c => {
      var birth = Common.getDateObject(c.customer_birthday);
      var customer = {
        ...c,
        // customer_birthday_y: birth.year ? birth.year : '',
        customer_birthday_m: birth.month ? birth.month : '',
        customer_birthday_d: birth.date ? birth.date : '',
        // refund_flag: constClass.REFUND_FLAG_NAME[c.refund_flag],
        account_type_name: constClass.ACCOUNT_TYPE_NAME[c.account_type],
        customer_type_name: constClass.CUSTOMER_TYPE_NAME[c.customer_type],
        vip_flag_name: c.vip_flag === constClass.FLAG.ON ? '○' : '-',
        status_name: constClass.STATUS_NAME[c.status]
      };
      list.push(customer);
    });

    setCsvData({ header: head, list: list });
  }, []);

  const refreshCustomer = useCallback(async () => {
    // useCallback対応のため検索ボタンを押したときのみ実行
    if (condition.isReload) {
      const jwt = localStorage.getItem('jwt');

      const reg_params = createSearchParams();
      // お客様データ
      const data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/search/`, reg_params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      })).data;
      setCustomerData(data);
      createCsvData(data);
      // createLineTextData(data);
      conditionActions.setIsReload(false);
    }
    // setSearchFlag(false);
  }, [condition, conditionActions, createSearchParams, createCsvData]);

  const handleHeaderClick = (column) => {
    var _sortOrder = condition.sortOrder.map(s => ({ ...s }));
    if (_sortOrder.map(s => s.column).includes(column)) { // 既に登録済み
      if (_sortOrder[0].column !== column) { // 先頭ではない
        _sortOrder.splice(_sortOrder.findIndex(s => s.column === column), 1); //削除
        _sortOrder.unshift({ column, order: 'ASC' }); // 追加
      } else { //先頭 並び順変更
        _sortOrder[0].order = _sortOrder[0].order === 'ASC' ? 'DESC' : 'ASC';
      }
    } else { //未登録
      _sortOrder.unshift({ column, order: 'ASC' }); // 追加
    }
    if (_sortOrder.length > 3) { //ソート順は3件まで
      _sortOrder.pop();
    }
    conditionActions.setSortOrder(_sortOrder);
    var data = customerData.slice();
    data.sort((a, b) => sortData(a, b));
    setCustomerData(data);
  }

  const handleSelect = (column) => (data) => {
    if (data.length <= 0) {
      conditionActions.setCondition({ name: column, value: [] });
    } else {
      conditionActions.setCondition({ name: column, value: data });
    }
  }

  const handleChangeCondition = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? (target.checked ? constClass.FLAG.ON : constClass.FLAG.OFF) : target.value;
    const name = target.name;
    conditionActions.setCondition({ name: name, value: value });
    // setErrMsg({ ...errMsg, [name]: validator(name, value) });
  }

  const handleExportHistoryClick = async () => {
    const jwt = localStorage.getItem('jwt');
    const params = {
      site_id: props.match.params.siteId,
      history_start: historyStart ? moment(historyStart).format('YYYYMMDD') : null,
      history_end: historyEnd ? moment(historyEnd).format('YYYYMMDD') : null,
    };
    try {
      setLockData(true);
      setCsvHistoryData(null);
      const data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/history_search/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      })).data;
      const head = [];
      head.push({ label: "管理ID", key: "customer_id" });
      head.push({ label: "履歴番号", key: "history_no" });
      head.push({ label: "会員番号", key: "customer_no" });
      head.push({ label: "お名前", key: "customer_name" });
      head.push({ label: "お名前(カナ)", key: "customer_kana" });
      head.push({ label: "郵便番号", key: "customer_postal_code" });
      head.push({ label: "住所1", key: "customer_address1" });
      head.push({ label: "住所2", key: "customer_address2" });
      head.push({ label: "住所3", key: "customer_address3" });
      head.push({ label: "携帯番号", key: "customer_tel" });
      head.push({ label: "メールアドレス", key: "customer_mail" });
      // head.push({ label: "お誕生日(年)", key: "customer_birthday_y" });
      head.push({ label: "お誕生日(月)", key: "customer_birthday_m" });
      head.push({ label: "お誕生日(日)", key: "customer_birthday_d" });
      // head.push({ label: "職種", key: "customer_job" });
      // head.push({ label: "入会方法", key: "method" });
      // head.push({ label: "返金要否", key: "refund_flag" });
      head.push({ label: "銀行名", key: "bank_name" });
      head.push({ label: "支店名", key: "branch_name" });
      head.push({ label: "口座種別", key: "account_type_name" });
      head.push({ label: "口座番号", key: "account_number" });
      head.push({ label: "口座名義人", key: "account_holder" });
      head.push({ label: "会員種別", key: "customer_type_name" });
      head.push({ label: "VIP会員", key: "vip_flag_name" });
      head.push({ label: "LINE_ID", key: "line_id" });
      head.push({ label: "ステータス", key: "status_name" });
      head.push({ label: "更新者", key: "upd_name" });
      head.push({ label: "更新日時", key: "upd_date" });
      const list = data.map(d => ({...d, upd_date: moment(d.upd_date).format("YYYY/MM/DD HH:mm:ss")}));

      setCsvHistoryData({ header: head, list: list });
      historyDataRef.current.link.click();
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      setLockData(false);
    }
  }

  // const validator = (name, value) => {
  //   switch (name) {
  //     case 'birth-y':
  //       return yearValidation(value);
  //     case 'postalCode':
  //       return postalCodeValidation(value);
  //     default:
  //       return '';
  //   }
  // }

  // const yearValidation = (value) => {
  //   if (value === '') return '';
  //   const regex = /^[0-9]{4}$/;
  //   if (!regex.test(value)) return '※正しい形式で年を入力してください';
  //   return '';
  // }

  // const postalCodeValidation = (value) => {
  //   if (value === '') return '';
  //   const regex = /^[0-9]{0,7}$/;
  //   if (!regex.test(value)) return '※正しい形式で郵便番号を入力してください';
  //   return '';
  // }

  const sortData = useCallback((a, b) => {
    if (condition.sortOrder.length <= 0) { // 初期値はお客様ID
      if (a.customer_kana < b.customer_kana) return -1;
      if (a.customer_kana > b.customer_kana) return 1;
      if (a.customer_kana === b.customer_kana) return (a.customer_no < b.customer_no) ? -1 : 1;
    }
    for (var i = 0; i < condition.sortOrder.length; i++) {
      const item = condition.sortOrder[i];
      // const detail = item.detail.find(() => true);
      if (item.column === 'status') {
        const aData = a.status === constClass.STATUS.REG ? 1 : a.status === constClass.STATUS.UPD ? 2 : a.status === constClass.STATUS.TMP ? 3 : 4;
        const bData = b.status === constClass.STATUS.REG ? 1 : b.status === constClass.STATUS.UPD ? 2 : b.status === constClass.STATUS.TMP ? 3 : 4;
        if (item.order === 'ASC') {
          if (aData < bData) return -1;
          if (aData > bData) return 1;
        } else {
          if (bData < aData) return -1;
          if (bData > aData) return 1;
        }
      } else if (item.column === 'customer_birthday') {
        if (item.order === 'ASC') {
          if (Number(a[item.column]) < Number(b[item.column])) return -1;
          if (Number(a[item.column]) > Number(b[item.column])) return 1;
        } else {
          if (Number(b[item.column]) < Number(a[item.column])) return -1;
          if (Number(b[item.column]) > Number(a[item.column])) return 1;
        }
      } else if (item.column === 'line_id') {
        const aData = a.line_id ? 1 : 0;
        const bData = b.line_id ? 1 : 0;
        if (item.order === 'ASC') {
          if (aData < bData) return -1;
          if (aData > bData) return 1;
        } else {
          if (bData < aData) return -1;
          if (bData > aData) return 1;
        }
      } else if (item.column === 'bank_name') {
        const aData = a.bank_name + a.branch_name + a.account_type + a.account_number;
        const bData = b.bank_name + b.branch_name + b.account_type + b.account_number;
        if (item.order === 'ASC') {
          if (aData < bData) return -1;
          if (aData > bData) return 1;
        } else {
          if (bData < aData) return -1;
          if (bData > aData) return 1;
        }
      } else {
        if (item.order === 'ASC') {
          if (a[item.column] < b[item.column]) return -1;
          if (a[item.column] > b[item.column]) return 1;
        } else {
          if (b[item.column] < a[item.column]) return -1;
          if (b[item.column] > a[item.column]) return 1;
        }
      }
    }
    return 0;
  }, [condition.sortOrder]);

  const renderSortTh = (column) =>
    <th className="text-center align-middle like-button p-0 m-0" onClick={() => handleHeaderClick(column)} key={column}>
      {condition.sortOrder.filter(s => s.column === column).map((item, idx, self) =>
        <span key={idx}>{item.order === 'ASC' ? '▲' : '▼'}{condition.sortOrder.findIndex(s => s.column === column) + 1}</span>
      )}
    </th>

  const renderHeader = (column, name) =>
    <th className="text-center align-middle text-nowrap like-button" onClick={() => handleHeaderClick(column)}>
      {name}
    </th>

  const renderCondition = (column, name, type, size = 'col-4', options = []) =>
    <div className={size}>
      <div className="row">
        <div className="col-auto pr-0 text-center align-self-center">
          <span>{name}</span>
        </div>
        {type === 'text' &&
          <div className="col text-center">
            <input className={`form-control`} type="text" id={column} name={column} value={condition[column] || ''} onChange={handleChangeCondition}></input>
          </div>
        }
        {type === 'date' &&
          <React.Fragment>
            {/* <div className="col text-center">
              <input className={`form-control`} type="text" id={column + '_y'} name={column + '_y'} value={condition[column + '_y'] || ''} onChange={handleChangeCondition} maxLength={4} placeholder={'年'}></input>
            </div> */}
            <div className="col text-center">
              <input className={`form-control`} type="text" id={column + '_m'} name={column + '_m'} value={condition[column + '_m'] || ''} onChange={handleChangeCondition} maxLength={2} placeholder={'月'}></input>
            </div>
            <div className="col text-center">
              <input className={`form-control`} type="text" id={column + '_d'} name={column + '_d'} value={condition[column + '_d'] || ''} onChange={handleChangeCondition} maxLength={2} placeholder={'日'}></input>
            </div>
          </React.Fragment>
        }
        {type === 'select' &&
          <div className="col text-center">
            <Select
              styles={{ container: (base) => ({ ...base, zIndex: 600 }) }}
              options={options}
              isClearable={false}
              isMulti
              closeMenuOnSelect={false}
              value={condition[column]}
              onChange={handleSelect(column)} />
          </div>
        }
      </div>
    </div>

  useEffect(() => {
    refreshCustomer();
  }, [refreshCustomer]);

  useEffect(() => {
    conditionActions.setIsReload(true);
  }, [conditionActions]);

  return (
    <div className="container">
      <div className="row mx-0 my-2 px-1-env">
        <div className="col-11">
          <div className="row my-1">
            {renderCondition('customer_no', '会員番号', 'text')}
            {renderCondition('customer_name', 'お名前', 'text')}
            {renderCondition('customer_kana', 'お名前(カナ)', 'text')}
            {renderCondition('customer_tel', '携帯番号', 'text')}
            {renderCondition('customer_mail', 'メール', 'text')}
            {renderCondition('customer_birthday', 'お誕生日', 'date')}
            {/* {renderCondition('refund_flag', '返金要否', 'select', 'col-3', [
              { value: constClass.FLAG.OFF, label: constClass.REFUND_FLAG_NAME.OFF },
              { value: constClass.FLAG.ON, label: constClass.REFUND_FLAG_NAME.ON }
            ])} */}
            {renderCondition('customer_type', '種別', 'select', 'col-3', [
              { value: constClass.CUSTOMER_TYPE.PAPER, label: constClass.CUSTOMER_TYPE_NAME.PAPER },
              { value: constClass.CUSTOMER_TYPE.LINE, label: constClass.CUSTOMER_TYPE_NAME.LINE }
            ])}
            {renderCondition('line_id', 'LINE登録', 'select', 'col-3', [
              { value: constClass.FLAG.ON, label: '○' },
              { value: constClass.FLAG.OFF, label: '-' }
            ])}
            {renderCondition('vip_flag', 'VIP会員', 'select', 'col-3', [
              { value: constClass.FLAG.ON, label: '○' },
              { value: constClass.FLAG.OFF, label: '-' }
            ])}
            {renderCondition('status', 'ステータス', 'select', 'col-3', [
              { value: constClass.STATUS.TMP, label: constClass.STATUS_NAME.TMP },
              { value: constClass.STATUS.REG, label: constClass.STATUS_NAME.REG },
              { value: constClass.STATUS.UPD, label: constClass.STATUS_NAME.UPD },
              { value: constClass.STATUS.CCL, label: constClass.STATUS_NAME.CCL }
            ])}
          </div>
        </div>
        <div className="col-1 px-0 text-center align-self-end">
          <div className="row pb-4">
            <div className="col">
              <button type="button"
                className={`btn btn-primary mx-1 w-100`}
                disabled={condition.isReload}
                onClick={() => conditionActions.clearCondition()}>
                クリア</button>
            </div>
          </div>
          <div className="row pt-3">
            <div className="col">
              <button type="button"
                className={`btn btn-primary mx-1 w-100`}
                disabled={condition.isReload}
                onClick={() => conditionActions.setIsReload(true)}>
                検索</button>
            </div>
          </div>
        </div>
      </div>
      {customerData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {customerData !== null && (
        <div ref={componentRef} className="print-list">
          <div className="row mb-0 p-0 ">
            <div className="col-10 pl-0 text-left align-self-end">
              <div className="row mr-0">
                <div className="col-2 pr-2 align-self-center">
                  <Link to={`${generatePath(`${props.match.path}`, { siteId })}customer/`}>
                    <button type="button"
                      className={`btn btn-primary w-100 px-0 mb-1`}>
                      新規登録</button>
                  </Link>
                </div>
                <div className="col-2 px-2 align-self-center">
                  {customerData[0] &&
                    <ReactToPrint
                      trigger={() => (
                        <button type="button" className="btn btn-dark print-none px-0 mb-1 w-100">
                          印刷
                        </button>
                      )}
                      content={() => componentRef.current}
                      pageStyle={""}
                    />}
                </div>
                <div className="col-2 px-2 align-self-center">
                  {(csvData && csvData.list.length > 0) &&
                    <CSVLink className="btn btn-dark print-none mb-1 px-0 w-100" data={csvData.list.sort((a, b) => sortData(a, b))} headers={csvData.header} filename={`会員一覧_${moment().format('YYYYMMDD')}.csv`}>
                      CSV出力
                    </CSVLink>
                  }
                </div>
                <div className="col-6 px-0 py-1 border">
                  <div className="row mx-0 px-0">
                    <div className="col mx-2 px-0 align-self-center">
                      {<DatePicker className="form-control" aria_labal="検索開始日" locale="ja" dateFormat="yyyyMMdd" monthsShown="1" id="historyStart" name="historyStart" selected={historyStart} onChange={date => setHistoryStart(date)} autoComplete="off" />}
                    </div>
                    <div className="col-auto mx-2 px-0 align-self-center">
                      ～
                    </div>
                    <div className="col mx-2 px-0 align-self-center">
                      {<DatePicker className="form-control" aria_labal="検索終了日" locale="ja" dateFormat="yyyyMMdd" monthsShown="1" id="historyEnd" name="historyEnd" selected={historyEnd} onChange={date => setHistoryEnd(date)} autoComplete="off" />}
                    </div>
                    <div className="col-auto mx-2 px-0">
                      <button
                        className="btn btn-dark print-none mb-1 px-2 w-100"
                        disabled={lockData}
                        onClick={() => handleExportHistoryClick()}>
                        全会員履歴
                      </button>
                      {csvHistoryData && <CSVLink data={csvHistoryData.list} headers={csvHistoryData.header} filename={`会員履歴_${historyStart ? moment(historyStart).format('YYYYMMDD'): '未指定'}_${historyEnd ? moment(historyEnd).format('YYYYMMDD') : '未指定'}.csv`} className="d-none" ref={historyDataRef} />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-2 px-2 align-self-center text-right border h-100">
              <div className="row">
                <div className="col-auto py-1 px-2 bg-lightwhite h-100">一覧表件数</div>
                <div className="col py-1">{customerData.length.toLocaleString()}</div>
              </div>
            </div>
          </div>
          <div className="row mb-3 p-0">
            <div className="col-12 p-0">
              <table className="table table-bordered table-striped" height="1">
                <thead className={`table-info`}>
                  <tr>
                    {renderHeader('customer_no', '会員番号')}
                    {renderHeader('customer_name', 'お名前')}
                    {renderHeader('customer_kana', 'お名前(カナ)')}
                    {renderHeader('customer_postal_code', '住所')}
                    {renderHeader('customer_tel', '携帯番号')}
                    {renderHeader('customer_mail', 'メールアドレス')}
                    {renderHeader('customer_birthday', 'お誕生日')}
                    {/* {renderHeader('customer_job', '職種')} */}
                    {/* {renderHeader('method', '入会方法')} */}
                    {/* {renderHeader('refund_flag', '返金要否')} */}
                    {renderHeader('bank_name', '返金口座')}
                    {renderHeader('customer_type', '種別')}
                    {renderHeader('line_id', 'LINE登録')}
                    {renderHeader('vip_flag', 'VIP会員')}
                    {renderHeader('status', 'ステータス')}
                  </tr>
                  <tr className="print-none">
                    {renderSortTh('customer_no')}
                    {renderSortTh('customer_name')}
                    {renderSortTh('customer_kana')}
                    {renderSortTh('customer_postal_code')}
                    {renderSortTh('customer_tel')}
                    {renderSortTh('customer_mail')}
                    {renderSortTh('customer_birthday')}
                    {/* {renderSortTh('customer_job')} */}
                    {/* {renderSortTh('method')} */}
                    {/* {renderSortTh('refund_flag')} */}
                    {renderSortTh('bank_name')}
                    {renderSortTh('customer_type')}
                    {renderSortTh('line_id')}
                    {renderSortTh('vip_flag')}
                    {renderSortTh('status')}
                  </tr>
                </thead>
                <tbody>
                  {customerData.sort((a, b) => sortData(a, b)).map((data, idx) => (
                    <tr key={data.customer_id}>
                      {/* 会員番号 */}
                      <td className="text-center align-middle p-1">
                        <Link to={`${generatePath(`${props.match.path}`, { siteId })}customer/${data.customer_id}`}>{data.customer_no ? data.customer_no : '(未登録)'}</Link>
                      </td>
                      {/* お名前 */}
                      <td className="text-left align-middle p-1">
                        {data.customer_name}
                      </td>
                      {/* お名前(カナ) */}
                      <td className="text-left align-middle p-1">
                        {data.customer_kana}
                      </td>
                      {/* 住所 */}
                      <td className="text-left align-middle p-1">
                        〒{data.customer_postal_code}<br />
                        {Common.getBrString(data.customer_address)}
                      </td>
                      {/* 携帯番号 */}
                      <td className="text-left align-middle p-1 text-break">
                        {data.customer_tel}
                      </td>
                      {/* メールアドレス */}
                      <td className="text-left align-middle p-1 text-break">
                        {data.customer_mail}
                      </td>
                      {/* お誕生日 */}
                      <td className="text-left align-middle p-1">
                        {Common.getDateObject(data.customer_birthday).str}
                      </td>
                      {/* 職種 */}
                      {/* <td className="text-left align-middle p-1">
                        {data.customer_job}
                      </td> */}
                      {/* 入会方法 */}
                      {/* <td className="text-left align-middle p-1">
                        {data.method}
                      </td> */}
                      {/* 返金要否 */}
                      {/* <td className="text-center align-middle p-1">
                        {constClass.REFUND_FLAG_NAME[data.refund_flag]}
                      </td> */}
                      {/* 返金口座 */}
                      <td className="text-left align-middle p-1">
                        {Common.getBrString(Common.getAccountString(data))}
                      </td>
                      {/* 種別 */}
                      <td className="text-center align-middle p-1">
                        {constClass.CUSTOMER_TYPE_NAME[data.customer_type]}
                      </td>
                      {/* LINE登録 */}
                      <td className="text-center align-middle p-1">
                        {data.line_id ? '○' : '-'}
                      </td>
                      {/* VIP会員 */}
                      <td className="text-center align-middle p-1">
                        {data.vip_flag === constClass.FLAG.ON ? '○' : '-'}
                      </td>
                      {/* ステータス */}
                      <td className="text-center align-middle p-1">
                        {constClass.STATUS_NAME[data.status]}
                      </td>
                    </tr>
                  ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Customer;