import React, { useState, useEffect, useRef, useCallback } from 'react';
import ReactToPrint from 'react-to-print';
import Barcode from 'react-barcode';
import constClass from '../../Constants/Constants';
import Common from '../Common/common.js';
import moment from 'moment';
import 'moment/locale/ja';

// 注文明細印刷
//printFlg  true:画面表示せずに印刷プレビュー表示
const BackyardOrderDetailPrint = ({ settings, order, timeMaster, sortData, setPrintModal }) => {
  const componentRef = useRef();
  const [orderData, setOrderData] = useState(null);
  const [pageCount, setPageCount] = useState({ total: 1, current: 1, view: 10, count: 30, pageList: [] });

  const createPageCount = useCallback(async (data) => {
    const pager = { view: 10, count: 30 };
    const total = Math.ceil(data.length / pager.count);
    var list = [];
    var viewEnd = total < pager.view ? total : pager.view;
    for (var i = 1; i <= viewEnd; i++) {
      list.push(i);
    }
    return { total: total, current: 1, pageList: list, view: pager.view, count: pager.count };
  }, []);

  const refreshData = useCallback(async () => {
    // 入力用データの加工処理
    var data = order.sort((a, b) => sortData(a, b));
    setOrderData(data);
    const count = await createPageCount(order);
    setPageCount(count);
  }, [createPageCount, order, sortData,]);

  const checkReady = useCallback(() => {
    return (order !== null);
  }, [order]);

  useEffect(() => {
    if (checkReady()) {
      refreshData();
    }
  }, [refreshData, checkReady]);

  const handlePageChanged = (newPage) => {
    var page = newPage < 1 ? 1 : newPage > pageCount.total ? pageCount.total : newPage;
    var viewEnd = (page + pageCount.view - 1) > pageCount.total ? pageCount.total : page + pageCount.view - 1;
    var viewStart = (viewEnd - pageCount.view + 1) < 1 ? 1 : viewEnd - pageCount.view + 1;
    var list = [];
    for (var i = viewStart; i <= viewEnd; i++) {
      list.push(i);
    }
    setPageCount({ ...pageCount, current: page, pageList: list });
  }
  const renderPager = () => {
    return (
      <div className='row mb-0 p-0 print-none justify-content-center'>
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            {<li className="page-item"><button className="page-link" onClick={() => pageCount.current > 1 && handlePageChanged(1)}>|&lt;</button></li>}
            {<li className="page-item"><button className="page-link" onClick={() => pageCount.current > 1 && handlePageChanged(pageCount.current - 1)}>&lt;</button></li>}
            {pageCount.pageList.map((item, idx) =>
              <li key={idx} className={`page-item ${item === pageCount.current ? "active" : ""}`}><button className="page-link" onClick={() => handlePageChanged(item)}>{item}</button></li>
            )}
            {<li className="page-item"><button className="page-link" onClick={() => pageCount.current < pageCount.total && handlePageChanged(pageCount.current + 1)}>&gt;</button></li>}
            {<li className="page-item"><button className="page-link" onClick={() => pageCount.current < pageCount.total && handlePageChanged(pageCount.total)}>&gt;|</button></li>}
          </ul>
        </nav>
      </div>
    );
  }

  return (
    <div className="modal show" id="detailprintModal">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            注文明細印刷
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setPrintModal(false)}><span aria-hidden="true">&times;</span></button>
          </div>
          <div className="modal-body">
            {orderData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
            {orderData !== null && (
              <React.Fragment>
                <div className="mb-1 p-1 print-none">
                  {pageCount.total > 1 &&
                    <div className="row mb-0 p-0">
                      <div className='col text-center'>印刷するページを指定して「印刷」ボタンをクリックしてください。(全{pageCount.total}ページ）</div>
                    </div>
                  }
                  <div className="row mb-0 p-0">
                    <div className='col-10 text-center'>
                      {pageCount.total > 1 && renderPager()}
                    </div>
                    <div className="col-2 pl-0 text-left align-self-center">
                      <ReactToPrint
                        trigger={() => <button type="button" className="btn btn-primary px-0 mb-1 w-100">印刷</button>}
                        documentTitle={`注文明細${pageCount.total}-${pageCount.current}`}
                        pageStyle=""
                        content={() => componentRef.current}
                      />
                    </div>
                  </div>
                </div>
                <div className="print-detail" ref={componentRef}>
                  {orderData.filter((x, idx) => (idx >= (pageCount.current - 1) * pageCount.count) && (idx < pageCount.current * pageCount.count)).map((data, idx) => (
                    <table className="table" height="1" key={idx}>
                      <thead>
                        <tr className='print-detail-header'>
                          <th colSpan={2}>
                            <div className='row mt-4 mb-2'>
                              <div className='col'>{moment().format('YYYY/MM/DD')} {data.receive_shop_name}</div>
                              <div className='col text-right'>({pageCount.total}-{pageCount.current})</div>
                            </div>
                            <div className='h3 text-center'>注文明細書　注文No.{data.receipt_num}</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>{/* 注文明細 */}
                        <tr className='print-detail-header'>
                          <td colSpan={2}>
                            <div className='row'>
                              <div className='col-4'>
                                <div className='h4'>ご注文主</div>
                                <div className='pl-3'>{data.customer_kana}</div>
                                <div className='pl-3 h5'>{data.customer_name} 様</div>
                              </div>
                              <div className='col-4'>
                                <div className='h4'>{data.delivery_flag === constClass.FLAG.ON ? "配送" : "お受け取り"}予定</div>
                                <div className='pl-3 h5'>{moment(data.receive_date).format('YYYY年M月D日(dd)')}</div>
                                {data.delivery_flag !== constClass.FLAG.ON && <div className='pl-3 h5'>{timeMaster.find(t => t.time_id.toString() === data.receive_time) ? timeMaster.find(t => t.time_id.toString() === data.receive_time).time_name : ''}</div>}
                              </div>
                              {data.delivery_flag === constClass.FLAG.ON &&
                                <div className='col-4'>
                                  <div className='h4'>配送先</div>
                                  <div className="pl-3 h5">{data.delivery_kana}</div>
                                  <div className="pl-3 h5">{data.delivery_name} 様</div>
                                  <div className="pl-3 h5">&#x3012;{data.delivery_postal_code}</div>
                                  <div className="pl-3 h5">{data.delivery_address}</div>
                                </div>
                              }
                            </div>
                          </td>
                        </tr>
                        <tr className='print-detail-header'>
                          <td className=' align-bottom'>
                            <div className='h4'>{data.plan_name}</div>
                            <div>(受付：{data.order_type})</div>
                            {data.payment_flag === constClass.FLAG.ON &&
                              <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0 text-right text-danger h5">※お支払い済み</div>
                              </div>}
                          </td>
                          <td>
                            <div className='mr-1'>
                              <div className='row'>
                                <div className='col'>注文点数</div>
                                <div className='col text-right'>{data.t_order_detail.reduce((sum, d) => sum + Number(d.count), 0)} 点</div>
                              </div>
                              <div className='row'>
                                <div className='col'>小計</div>
                                <div className='col text-right'>&yen; {Math.floor(data.subtotal).toLocaleString()}</div>
                              </div>
                              {Number(data.discount) > 0 &&
                                <div className='row'>
                                  <div className='col'>早期割引</div>
                                  <div className='col text-right'>&yen; {(data.discount * -1).toLocaleString()}</div>
                                </div>
                              }
                              <div className='row'>
                                <div className='col'>{Common.getSettingValue(settings, 'TAX_INCLUDED_FLAG', '0') === '1' ? '（内税額' : '税額'}</div>
                                <div className='col text-right'>&yen; {Math.floor(data.tax).toLocaleString()}{Common.getSettingValue(settings, 'TAX_INCLUDED_FLAG', '0') === '1' ? '）' : ''}</div>
                              </div>
                              <div className='row border-top'>
                                <div className='col'>合計</div>
                                <div className='col text-right'>&yen; {Math.floor(data.total).toLocaleString()}</div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        {(data.t_order_detail && data.t_order_detail.length > 0) && data.t_order_detail.sort((a, b) => Number(a.product_id) - Number(b.product_id)).map((detail, detail_idx) => (
                          <tr className="print-detail-product" key={data.order_id + '_' + detail.product_id}>
                            <td className="text-center align-top px-3">
                              <div className="row p-2 m-0">
                                <div className="col-3 text-left align-self-center py-0 pl-0 pr-1 m-0">{detail.product_code}</div>
                                <div className="col-9 text-left align-self-center p-0 m-0">{detail.product_name}</div>
                              </div>
                              <div className="row p-2 pl-5 m-0">
                                <div className="col text-left align-self-center py-0 pl-0 pr-1 m-0">&yen;{Common.getSettingValue(settings, 'TAX_INCLUDED_FLAG', '0') === '1' ? Math.floor(detail.price).toLocaleString() + '(税込)' : Math.floor(detail.price_before_tax).toLocaleString()}<br />
                                  {Common.getSettingValue(settings, 'TAX_INCLUDED_FLAG', '0') !== '1' && <small>&yen;{detail.price.toLocaleString()}(税込)</small>}</div>
                                <div className="col text-left align-self-center  p-0 m-0">{detail.count} 個</div>
                                <div className="col text-right align-self-center py-0 pl-1 pr-0 m-0">&yen;{Common.getSettingValue(settings, 'TAX_INCLUDED_FLAG', '0') === '1' ? Math.floor(detail.price * detail.count).toLocaleString() + '(税込)' : Math.floor(detail.price_before_tax * detail.count).toLocaleString()}<br />
                                  {Common.getSettingValue(settings, 'TAX_INCLUDED_FLAG', '0') !== '1' && <small>&yen;{(detail.price * detail.count).toFixed(2).toLocaleString()}(税込)</small>}</div>
                              </div>
                              {detail.early_discount === constClass.FLAG.ON && <div className="row p-2 pl-5 m-0">
                                <div className='col text-right text-danger h5'>※早割対象</div>
                              </div>}
                            </td>
                            <td className="text-center align-middle p-1" style={{ width: "400px" }}>
                              <div className='row m-0 p-0'>
                                <div className='col text-left'>{(detail.m_product && detail.m_product.jan_code) &&
                                  <Barcode renderer={"img"} value={detail.m_product.jan_code} background={`#ffffff`} marginLeft={20} marginRight={20} format={'EAN13'} />
                                }</div>
                                <div className="col-auto text-right align-self-center py-0 px-1 m-0 h3"><small>×</small>{detail.count}</div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ))
                  }
                </div>
              </React.Fragment>
            )
            }
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => setPrintModal(false)}>Close</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BackyardOrderDetailPrint;