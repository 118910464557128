import React, { useEffect, useCallback } from 'react';
import { useHistory, Link } from 'react-router-dom';
import Common from '../Common/common';
import constClass from '../../Constants/Constants';
import InputComponent from './InputComponent';
import axios from 'axios';
import moment from 'moment';

const Renewal = ({ siteId, page, setting, my_customer, liff_access_token, liff_user_id, setBack, setNext, setMyCustomer, membership, setPaymentData }) => {
  const history = useHistory();

  const checkInvalid = useCallback(() => {
    return false;
  }, []);

  const submit = useCallback(async () => {
    const payment_param = { site_id: siteId, line_id: liff_access_token, membership_year: membership[0].membership_year, customer_id: my_customer.customer_id };
    try {
      const payment_data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/membership/payment/`, payment_param)).data;

      setPaymentData({ ...payment_data.data }, null);
      history.push(`/${siteId}/?page=${constClass.PAYMENT}`);
    } catch (e) {
      console.log(e);
    }
  }, [history, siteId, liff_access_token, membership, my_customer, setPaymentData]);

  useEffect(() => {
    setBack({
      title: '戻る',
      render: true,
      disabled: false,
      onClick: () => {
        if (history.length <= 1) {
          history.push(`/${siteId}/`);
        } else {
          history.goBack();
        }
      }
    });
    setNext({
      title: '次へ',
      render: true,
      disabled: false,
      onClick: submit
    });
    if (membership.length <= 0) {
      if (history.length <= 1) {
        history.push(`/${siteId}/`);
      } else {
        history.goBack();
      }
    }
  }, [history, setBack, setNext, siteId, checkInvalid, submit, membership])

  return (
    <div id="user" className="row mx-3 my-3 px-0-env">
      <div className="col bg-white px-0">
        <div>
          {(setting['RENEWAL_HEADER']) &&
            <div className="row mx-0 pt-2 pb-1 text-left">
              <div className="col py-0 px-3 align-self-center">
                {Common.getBrString(setting['RENEWAL_HEADER'])}
              </div>
            </div>
          }
          {membership.length > 0 &&
            <div className="row mx-0 pt-2 pb-1 text-center">
              <div className="col-1"></div>
              <div className="col-10 border py-2">
                <div className="row mx-0 text-center">
                  <div className="col-auto px-0 text-right">会期：</div>
                  <div className="col px-0 text-left">{moment(membership[0].membership_start).format('YYYY年M月D日')}～{moment(membership[0].membership_end).format('YYYY年M月D日')}</div>
                </div>
                <div className="row mx-0 text-center">
                  <div className="col-auto px-0 text-right">会費：</div>
                  <div className="col px-0 text-left">{Number(membership[0].membership_fee).toLocaleString()}円</div>
                </div>
              </div>
              <div className="col-1"></div>
            </div>
          }
          <div className="row mx-0 pt-2 pb-1 text-center">
            <div className="col py-0 px-0 align-self-center">
              ご登録内容
            </div>
          </div>
          <div className="row mx-0 pt-2 pb-1">
            <div className="col py-0 px-0 align-self-center">
              {InputComponent.renderLabel('会員番号', my_customer.customer_no)}
              {InputComponent.renderLabel('お名前', my_customer.customer_name)}
              {InputComponent.renderLabel('お名前(カナ)', my_customer.customer_kana)}
              {InputComponent.renderLabel('携帯番号', my_customer.customer_tel)}
              {InputComponent.renderLabel('メールアドレス', my_customer.customer_mail)}
              {InputComponent.renderLabel('ご住所', `〒${my_customer.customer_postal_code}\n${my_customer.customer_address}`)}
              {/* {InputComponent.renderLabel('お誕生日', Common.getDateObject(my_customer.customer_birthday).str)} */}
              {/* {InputComponent.renderLabel('職種', my_customer.customer_job)}
              {InputComponent.renderLabel('返金', `返金${constClass.REFUND_FLAG_NAME[my_customer.refund_flag]}`)} */}
              {InputComponent.renderLabel('返金口座', Common.getAccountString(my_customer))}
              <div className="form-group">
                <div className="row mx-0 px-3-env text-left">
                  <div className={`col px-0 align-self-center`}>
                    <Link to={`/${siteId}/?page=${constClass.USERCHANGE}`}>
                      <button
                        className={`btn btn-enable w-100 h-100 py-2 px-2`}>
                        <h4 className="mb-0">会員情報変更</h4>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Renewal;