import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Common from '../Common/common';
import constClass from '../../Constants/Constants';
import axios from 'axios';
import util from 'util';
import InputComponent from './InputComponent';

const UserChange = ({ siteId, page, setting, my_customer, liff_access_token, liff_user_id, setBack, setNext, setMyCustomer }) => {
  const [data, setData] = useState(my_customer || {});
  const [message, setMessage] = useState({});
  const [lockData, setLockData] = useState(false);
  const history = useHistory();

  const change = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? (target.checked ? constClass.FLAG.ON : constClass.FLAG.OFF) : target.value;
    const name = target.name;
    const customer = { ...data, [name]: value };
    setData(customer);
    // setMessage({ ...message, [name]: validator(name, value) });
  }

  const handleBlur = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? (target.checked ? constClass.FLAG.ON : constClass.FLAG.OFF) : target.value;
    const name = target.name;
    setMessage({ ...message, [name]: validator(name, value) });
  }

  const validator = (name, value) => {
    switch (name) {
      case 'customer_kana_sei':
      case 'customer_kana_mei':
        return Common.kanaValidation(value, true);
      case 'customer_tel':
        return Common.mobileNoValidation(value, true);
      case 'customer_mail':
        return Common.mailAddressValidation(value, true);
      case 'customer_postal_code':
        return Common.postalCodeValidation(value, true);
      case 'customer_birthday':
        return Common.dateValidation(value, false, true, true, true);
      case 'account_number':
        return Common.numericValidation(value, false);
      default:
        return '';
    }
  }

  const checkInvalid = useCallback(() => {
    var ret = false;
    // 必須項目チェック
    if (!data.customer_name_sei || !data.customer_name_mei || !data.customer_kana_sei || !data.customer_kana_mei || !data.customer_tel || !data.customer_mail || !data.customer_postal_code || !data.customer_address1 || !data.customer_address2) {
      ret = true;
    }
    // お誕生日チェック
    const birth = Common.getDateObject(data.customer_birthday);
    if (!birth.month || !birth.date) {
      ret = true;
    }
    // 返金口座チェック
    if (data.refund_flag !== constClass.FLAG.ON) {
      if (!data.bank_name || !data.branch_name || !data.account_number || !data.account_holder) {
        ret = true;
      }
    }

    // エラーメッセージチェック
    // messageに入っている値をループしてメッセージが設定されている場合はNG
    Object.keys(message).forEach(name => {
      if (message[name]) {
        ret = true;
      }
    });
    return ret;
  }, [data, message]);

  const submit = useCallback(async () => {
    if (checkInvalid() || lockData) {
      return;
    }
    // API実行
    setLockData(true);
    try {
      const params = { ...data, line_id: liff_access_token };
      const customer = (await axios.put(`${process.env.REACT_APP_BACKEND_URL}/customer/line/${data.customer_id}`, params)).data;
      alert('保存しました。');
      setLockData(false);
      setMyCustomer(customer);
      history.goBack();
    } catch (err) {
      console.log(util.inspect(err));
      if (err.response && err.response.data) {
        alert(err.response.data);
      } else {
        alert('エラーが発生しました。');
      }
      setLockData(false);
    }
  }, [checkInvalid, lockData, history, liff_access_token, setMyCustomer, data]);

  useEffect(() => {
    setBack({
      title: '戻る',
      render: true,
      disabled: lockData,
      onClick: () => {
        if (history.length <= 1) {
          history.push(`/${siteId}/`);
        } else {
          history.goBack();
        }
      }
    });
    setNext({
      title: '保存',
      render: true,
      disabled: checkInvalid() || lockData,
      onClick: submit
    });
  }, [history, setBack, setNext, siteId, checkInvalid, lockData, submit])

  const searchAddress = async () => {
    // API実行
    setLockData(true);
    try {
      const params = { site_id: siteId, line_id: liff_access_token };
      const result = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/address/line/${data.customer_postal_code}`, params)).data;
      if (result) {
        var address = '';
        var address2 = '';
        if (result.PrefecturesName) {
          address += result.PrefecturesName;
        }
        if (result.CityName) {
          address += result.CityName;
        }
        if (result.TownName) {
          address2 += result.TownName;
        }
        setData({ ...data, customer_address1: address, customer_address2: address2, customer_address3: '' });
        setMessage({ ...message, customer_postal_code: '' });
      } else {
        setMessage({ ...message, customer_postal_code: '※郵便番号が見つかりません' });
      }
      setLockData(false);
    } catch (err) {
      // alert(util.inspect(err));
      if (err.response && err.response.data && err.response.data.msg) {
        alert(err.response.data.msg);
      } else {
        alert('エラーが発生しました。');
      }
      setLockData(false);
    }
  }

  return (
    <div id="userchange" className="row mx-3 my-3 px-0-env">
      <div className="col bg-white px-0">
        <div>
          <div className="customer-input row mx-0 pt-2 pb-1">
            <div className="col py-0 px-0 align-self-center">
              {InputComponent.renderLabel('会員番号', data.customer_no)}
              <React.Fragment>
                <div className="input-label row mx-0 px-3-env">
                  <div className="col px-0">
                    <span>お名前</span>
                  </div>
                  <div className="col-auto px-0">
                    <span className="text-required">必須</span>
                  </div>
                </div>
                <div className="input-body form-group">
                  <div className="input-control row mx-0 px-3-env text-left">
                    <div className={`col pl-0 pr-2 align-self-center`}><input type={'text'} className="form-control" id={'customer_name_sei'} name={'customer_name_sei'} value={data.customer_name_sei} onChange={change} placeholder={'例）○○'}></input></div>
                    <div className={`col pr-0 pl-2 align-self-center`}><input type={'text'} className="form-control" id={'customer_name_mei'} name={'customer_name_mei'} value={data.customer_name_mei} onChange={change} placeholder={'例）○○'}></input></div>
                  </div>
                  {message.customer_name_sei &&
                    <div className="input-message row mx-0 px-3-env text-left">
                      <div className="col px-0 text-danger font-weight-normal">{Common.getBrString(message.customer_name_sei)}</div>
                    </div>
                  }
                  {message.customer_name_mei &&
                    <div className="input-message row mx-0 px-3-env text-left">
                      <div className="col px-0 text-danger font-weight-normal">{Common.getBrString(message.customer_name_mei)}</div>
                    </div>
                  }
                </div>
              </React.Fragment>
              <React.Fragment>
                <div className="input-label row mx-0 px-3-env">
                  <div className="col px-0">
                    <span>お名前(カナ)</span>
                  </div>
                  <div className="col-auto px-0">
                    <span className="text-required">必須</span>
                  </div>
                </div>
                <div className="input-body form-group">
                  <div className="input-control row mx-0 px-3-env text-left">
                    <div className={`col pl-0 pr-2 align-self-center`}><input type={'text'} className="form-control" id={'customer_kana_sei'} name={'customer_kana_sei'} value={data.customer_kana_sei} onChange={change} placeholder={'例）○○'} onBlur={handleBlur}></input></div>
                    <div className={`col pr-0 pl-2 align-self-center`}><input type={'text'} className="form-control" id={'customer_kana_mei'} name={'customer_kana_mei'} value={data.customer_kana_mei} onChange={change} placeholder={'例）○○'} onBlur={handleBlur}></input></div>
                  </div>
                  {message.customer_kana_sei &&
                    <div className="input-message row mx-0 px-3-env text-left">
                      <div className="col px-0 text-danger font-weight-normal">{Common.getBrString(message.customer_kana_sei)}</div>
                    </div>
                  }
                  {message.customer_kana_mei &&
                    <div className="input-message row mx-0 px-3-env text-left">
                      <div className="col px-0 text-danger font-weight-normal">{Common.getBrString(message.customer_kana_mei)}</div>
                    </div>
                  }
                </div>
              </React.Fragment>
              {InputComponent.renderText('携帯番号(半角数字、半角ハイフン要)', data.customer_tel, 'customer_tel', change, message.customer_tel, '例）080-1234-5678', 20, true, null, null, handleBlur)}
              {InputComponent.renderText('メールアドレス', data.customer_mail, 'customer_mail', change, message.customer_mail, '例）○○@example.com', null, true, null, null, handleBlur)}
              {InputComponent.renderText('ご住所', data.customer_postal_code, 'customer_postal_code', change, message.customer_postal_code, '例）123-4567', null, true, '〒',
                <button
                  onClick={() => searchAddress()}
                  disabled={lockData}
                  className={`btn btn-enable py-1 px-1 ml-1`}>
                  住所検索
                </button>
                , handleBlur)}
              {InputComponent.renderText('都道府県市区町村', data.customer_address1, 'customer_address1', change, message.customer_address1, '例）○○県○○市○○区', null, true, null, null, handleBlur)}
              {InputComponent.renderText('町域・番地', data.customer_address2, 'customer_address2', change, message.customer_address2, '例）○○ ○丁目○-○', null, true, null, null, handleBlur)}
              {InputComponent.renderText('建物名など', data.customer_address3, 'customer_address3', change, message.customer_address3, '例）◯◯マンション101号', null, false, null, null, handleBlur)}
              {/* {InputComponent.renderDate('お誕生日', data.customer_birthday, 'customer_birthday', change, message.customer_birthday, '', null, false)} */}
              {/* {InputComponent.renderText('職種', data.customer_job, 'customer_job', change, message.customer_job, '例）○○', null, false)} */}
              {/* <React.Fragment>
                <div className="input-label row mx-0 px-3-env">
                  <div className="col px-0">
                    <span>返金</span>
                  </div>
                </div>
                <div className="input-body form-group">
                  <div className="input-control row mx-0 px-3-env text-left">
                    <div className={`col px-0 align-self-center`}>
                      <div className="row px-0 mx-0">
                        <div className="col-2 align-self-center">
                          <input type="checkbox" className="form-control" id={'refund_flag'} name={'refund_flag'} value="true" checked={data.refund_flag === constClass.FLAG.ON} onChange={change}></input>
                        </div>
                        <div className="col-10 align-self-center">
                          <label className="my-0" htmlFor={'refund_flag'}>返金不要</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment> */}
              {/* {data.refund_flag !== constClass.FLAG.ON && */}
              <React.Fragment>
                <React.Fragment>
                  <div className="input-label row mx-0 px-3-env">
                    <div className="col px-0">
                      <span>返金口座</span>
                    </div>
                    <div className="col-auto px-0">
                      <span className="text-required">必須</span>
                    </div>
                  </div>
                  <div className="input-body form-group">
                    <div className="input-control row mx-0 px-3-env text-left">
                      <div className={`col px-0 align-self-center`}><input type={'text'} className="form-control" id={'bank_name'} name={'bank_name'} value={data.bank_name} onChange={change} placeholder={'例）○○'} onBlur={handleBlur}></input></div>
                      <div className={`col-auto pl-0 pr-2 align-self-center`}>銀行</div>
                      <div className={`col px-0 align-self-center`}><input type={'text'} className="form-control" id={'branch_name'} name={'branch_name'} value={data.branch_name} onChange={change} placeholder={'例）○○'} onBlur={handleBlur}></input></div>
                      <div className={`col-auto pr-0 pl-0 align-self-center`}>支店</div>
                    </div>
                    {message.bank_name &&
                      <div className="input-message row mx-0 px-3-env text-left">
                        <div className="col px-0 text-danger font-weight-normal">{Common.getBrString(message.bank_name)}</div>
                      </div>
                    }
                    {message.branch_name &&
                      <div className="input-message row mx-0 px-3-env text-left">
                        <div className="col px-0 text-danger font-weight-normal">{Common.getBrString(message.branch_name)}</div>
                      </div>
                    }
                  </div>
                </React.Fragment>
                <React.Fragment>
                  <div className="input-label row mx-0 px-3-env">
                    <div className="col px-0">
                      <span>口座番号</span>
                    </div>
                    <div className="col-auto px-0">
                      <span className="text-required">必須</span>
                    </div>
                  </div>
                  <div className="input-body form-group">
                    <div className="input-control row mx-0 px-3-env text-left">
                      <div className={`col-auto pl-0 pr-2 align-self-center`}>
                        <select className="form-control text-center" id={'account_type'} name={'account_type'} onChange={change} value={data.account_type}>
                          <option value={constClass.ACCOUNT_TYPE.SAVING}>{constClass.ACCOUNT_TYPE_NAME.SAVING}</option>
                          <option value={constClass.ACCOUNT_TYPE.CURRENT}>{constClass.ACCOUNT_TYPE_NAME.CURRENT}</option>
                        </select>
                      </div>
                      <div className={`col pr-0 pl-2 align-self-center`}><input type={'text'} className="form-control" id={'account_number'} name={'account_number'} value={data.account_number} onChange={change} placeholder={'例）口座番号'} onBlur={handleBlur}></input></div>
                    </div>
                    {message.account_type &&
                      <div className="input-message row mx-0 px-3-env text-left">
                        <div className="col px-0 text-danger font-weight-normal">{Common.getBrString(message.account_type)}</div>
                      </div>
                    }
                    {message.account_number &&
                      <div className="input-message row mx-0 px-3-env text-left">
                        <div className="col px-0 text-danger font-weight-normal">{Common.getBrString(message.account_number)}</div>
                      </div>
                    }
                  </div>
                </React.Fragment>
                {InputComponent.renderText('口座名義', data.account_holder, 'account_holder', change, message.account_holder, '例）○○', null, false, null, null, handleBlur)}
              </React.Fragment>
              {/* } */}
              {(setting['REFUND_NOTICE']) &&
                <div className="row mx-0 pt-2 pb-1 text-left">
                  <div className="col py-0 px-3 align-self-center">
                    {Common.getBrString(setting['REFUND_NOTICE'])}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserChange;