import React, { Component } from 'react';
import axios from 'axios';
import { withRouter, Link } from 'react-router-dom';
import constClass from '../../Constants/Constants';
import Loading from '../Loading/Loading';
import Modal from 'react-modal';
import util from 'util';
import DocumentMeta from 'react-document-meta';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel, AccordionItemState } from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import Select from 'react-select';
import ProductList from '../Master/ProductList';
// import DatePicker from "react-datepicker"
// import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment';
import 'moment/locale/ja';
import { Payment } from 'nis-common-frontend';
// import chroma from 'chroma-js';
import Common from '../Common/common.js';
import { generatePath } from 'react-router';
import Popover from "react-popover";

// Modal.setAppElement("#root");

class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      siteId: props.match.params.siteId,
      disabled: false,
      my_order: null,
      message: {},
      message_error: {},
      message_notice: {},
      ready: false,
      product_category: [],
      receive_shop: "",
      planMaster: null,
      productMaster: null,
      productCategoryMaster: null,
      shopMaster: null,
      areaMaster: null,
      settingMaster: null,
      my_customer: null,
      area: undefined,
      name: null,
      kana: null,
      tel: null,
      postal_code: null,
      address: null,
      delivery_check: true,
      delivery_name: null,
      delivery_kana: null,
      delivery_tel: null,
      delivery_postal_code: null,
      delivery_address: null,
      privacypolicy: false,
      cart: [],
      current_order: null,
      selected_plan: [],
      selected_early_discount: null,
      changed_order: [],
      payment_data: {},
      not_liff_flag: false,
      sleeping: false,
      headerHeight: 0,
      footerHeight: 0,
    };

    this.modalStyle = {
      overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: "rgba(0,0,0,0.5)"
      },
      content: {
        position: "absolute",
        left: '0.5rem',
        right: '0.5rem',
        top: '50%',
        bottom: 'auto',
        marginTop: '-25%',
        borderRadius: "0rem",
        padding: "0px",
        height: "auto"
      }
    };

    // this.changeHeight = this.changeHeight.bind(this);
    this.refreshOrder = this.refreshOrder.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.change = this.change.bind(this);
    this.handleRequestClick = this.handleRequestClick.bind(this);
    this.setReady = this.setReady.bind(this);
    this.addCart = this.addCart.bind(this);
    this.subCart = this.subCart.bind(this);
    this.displayCartCount = this.displayCartCount.bind(this);
    this.getDetailNotice = this.getDetailNotice.bind(this);
    this.setDetailNotice = this.setDetailNotice.bind(this);
    this.setIntervalPop = this.setIntervalPop.bind(this);
    this.intervalPop = [];
    this.receiveShopRef = React.createRef();
    this.pageRef = React.createRef();
    this.returnPayment = this.returnPayment.bind(this);
    this.headerRef = React.createRef();
    this.footerRef = React.createRef();
  }

  // サイト情報取得
  async componentDidMount() {
    this.refreshOrder();
    this.refreshData();
    // this.disableBounceScroll();

    // try {
    //   this.settings = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/setting/${this.state.siteId}`)).data;
    //   this.meta = {
    //     link: {
    //       rel: {
    //         icon: `/${this.state.siteId}/favicon.ico`,
    //         "apple-touch-icon": `/${this.state.siteId}/logo192.png`,
    //         manifest: `/${this.state.siteId}/manifest.json`,
    //         stylesheet: `/${this.state.siteId}/style.css`,
    //       },
    //     },
    //     title: Common.getSettingValue(this.settings, 'TITLE_NAME', ''),
    //     description: Common.getSettingValue(this.settings, 'TITLE_NAME', ''),
    //   };
    //   /* // ロゴ画像ファイル
    //   const logo_file = Common.getSettingValue(this.settings, 'LOGO_FILE', '');
    //   if (logo_file) {
    //     this.logo = require(`../Images/${logo_file}`).default;
    //   } */
    //   this.refreshOrder();
    //   this.disableBounceScroll();
    //   this.changeHeight(this.state);
    //   this.setState({ sleeping: false });
    // } catch (err) {
    //   this.setState({ sleeping: true });
    // }
    this.settings = this.props.setting;
    if (Common.checkOrderPage(this.props.page)) {
      this.props.setBack({
        title: '戻る',
        render: false,
        disabled: true,
        onClick: () => { }
      });
      this.props.setNext({
        title: '次へ',
        render: false,
        disabled: true,
        onClick: () => { }
      });
    }
  }

  // componentDidUpdate(prevProps, prevState) {
  //   this.changeHeight(prevState);
  // }

  componentDidUpdate(prevProps, prevState) {
    this.changeHeight(prevState);
    if (!Common.checkOrderPage(prevProps.page) && Common.checkOrderPage(this.props.page)) {
      this.props.setBack({
        title: '戻る',
        render: false,
        disabled: true,
        onClick: () => { }
      });
      this.props.setNext({
        title: '次へ',
        render: false,
        disabled: true,
        onClick: () => { }
      });
      if (prevProps.page === constClass.USERCHANGE) {
        const my_customer = { ...this.props.my_customer, receive_shop: 1 };
        this.setState({
          my_customer,
          name: this.props.my_customer.customer_name,
          kana: this.props.my_customer.customer_kana,
          tel: this.props.my_customer.customer_tel,
          postal_code: this.props.my_customer.customer_postal_code,
          address: this.props.my_customer.customer_address,
          delivery_name: this.props.my_customer.customer_name,
          delivery_kana: this.props.my_customer.customer_kana,
          delivery_tel: this.props.my_customer.customer_tel,
          delivery_postal_code: this.props.my_customer.customer_postal_code,
          delivery_address: this.props.my_customer.customer_address,
        });
      }
    }
    if (prevProps.page === constClass.AUTHVERIFY && this.props.page === constClass.MENU) {
      this.refreshOrder();
      this.refreshData();
    }
  }

  changeHeight(prevState) {
    const height = { header: prevState.headerHeight, footer: prevState.footerHeight };
    if (this.headerRef.current) {
      const rect = this.headerRef.current.getBoundingClientRect();
      if (height.header !== rect.height) {
        this.setState({ headerHeight: rect.height });
      }
    }
    if (this.footerRef.current) {
      const rect = this.footerRef.current.getBoundingClientRect();
      if (height.footer !== rect.height) {
        this.setState({ footerHeight: rect.height });
      }
    }
  }

  // async componentWillUnmount() {
  // }

  // disableBounceScroll() {
  //   let touchY = 0;

  //   document.body.addEventListener('touchstart', (e) => {
  //     touchY = e.touches[0].screenY;
  //   });

  //   document.body.addEventListener('touchmove', (e) => {
  //     let el = e.target;
  //     const moveY = e.touches[0].screenY;
  //     let noScroll = true;

  //     while (el !== null) {
  //       if (el.offsetHeight < el.scrollHeight) {
  //         if (touchY < moveY && el.scrollTop === 0) {
  //           break;
  //         }
  //         if (touchY > moveY && el.scrollTop === el.scrollHeight - el.offsetHeight) {
  //           break;
  //         }
  //         noScroll = false;
  //         break;
  //       }
  //       el = el.parentElement;
  //     }
  //     if (noScroll) {
  //       if (e.cancelable) {
  //         e.preventDefault();
  //       }
  //     }
  //     touchY = moveY;
  //   }, { passive: false });
  // }

  change(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
    var message = this.state.message;
    this.setState({
      message: { ...message, [name]: this.validator(name, value) }
    });

    // お客様情報の配送先連動処理
    if (this.state.delivery_check && ['name', 'kana', 'tel', 'postal_code', 'address'].includes(name)) {
      this.setState({
        ['delivery_' + name]: value,
      });
    }
    // お客様情報の連動チェック変更処理
    if (name === 'delivery_check' && value) {
      this.setState({
        delivery_name: this.state.name,
        delivery_kana: this.state.kana,
        delivery_tel: this.state.tel,
        delivery_postal_code: this.state.postal_code,
        delivery_address: this.state.address,
      })
    }
  }

  validator(name, value) {
    switch (name) {
      case 'kana':
        return this.kanaValidation(value);
      case 'delivery_kana':
        return this.kanaValidation(value);
      case 'tel':
        return this.phoneValidation(value);
      case 'delivery_tel':
        return this.phoneValidation(value);
      default:
        return '';
    }
  }

  phoneValidation(value) {
    if (value === '') return '';
    const regex = /^\(?0[0-9\-()]{9,12}$/;
    if (!regex.test(value)) return '※正しい形式で携帯番号を入力してください';
    return '';
  }

  kanaValidation(value) {
    if (value === '') return '';
    const regex = /^[ァ-ヴー　 ]*$/;
    if (!regex.test(value)) return '※全角カタカナで入力してください';
    return '';
  }

  scrollPageTop() {
    setTimeout(() => {
      // this.pageRef.current.scrollTo(0, 0);
      // window.scrollTo(0, 0);
    }, 200);
  }

  scrollWindowTop() {
    setTimeout(() => {
      // window.scrollTo(0, 0);
    }, 200);
  }

  async refreshOrder() {
    if (process.env.REACT_APP_ENV !== 'dev') {
      await window.liff.ready;
    }
    if (!this.props.liff_access_token) {
      if (!this.intervalOrder) {
        this.intervalOrder = setInterval(() => {
          this.refreshOrder();
        }, 500);
      }
    } else {
      clearInterval(this.intervalOrder);
      const reg_params = {
        "site_id": this.state.siteId,
        "line_id": this.props.liff_access_token,
        "operator": "and",
        "where": [
          {
            "site_id": this.state.siteId,
            "line_id": this.props.liff_access_token
          }
        ]
      }
      const my_order = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/order/search/`, reg_params)).data; // orderData取得
      console.log(my_order)
      this.setState({
        my_order: my_order,
        disabled: false,
        message_error: {},
        message_notice: {}
      });
      if (this.props.page === constClass.CANCEL) {
        this.submitCancel('CCL');
      } else if (this.props.page === constClass.PAYMENT && !!this.props.current_order && (!this.props.payment_data || this.props.current_order !== this.props.payment_data.id)) {
        // 決済画面でcurrent_orderに値が設定されている（payment_dataと違う値）場合は決済データ取得
        this.submitPayment(this.props.current_order);
      } else if (this.props.page === constClass.ORDERFINISH && this.props.current_order &&
        my_order.find(o => o.order_id === this.props.current_order) && [constClass.ORDER_STATUS.CCL, constClass.ORDER_STATUS.CUPAY].includes(my_order.find(o => o.order_id === this.props.current_order).status)) {
        // 注文完了画面でcurrent_orderに値が設定されていて該当orderがキャンセル済みの場合は注文確認画面へ
        this.props.history.replace(`/${this.state.siteId}/?page=${constClass.CHANGE}&current_order=${this.props.current_order}&retmenu=1`);
      }
      this.setReady();
    }
  }

  async refreshData() {
    const filter = {
      "site_id": this.state.siteId,
      "line_id": this.props.liff_access_token,
      "operator": "and",
      "where": [{ "site_id": this.state.siteId }]
    }
    if (process.env.REACT_APP_ENV !== 'dev') {
      await window.liff.ready;
    }
    if (!this.props.liff_access_token) {
      if (!this.intervalMaster) {
        this.intervalMaster = setInterval(() => {
          this.refreshData();
        }, 500);
      }
    } else {
      if (process.env.REACT_APP_ENV !== 'dev' && this.props.liff_access_token === 'token_unusable') { // トークン取得不可→非LINEブラウザ
        this.setState({ not_liff_flag: true });
        return;
      }
      clearInterval(this.intervalMaster);
      // 各種APIコール
      const planPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/plan/list/${this.state.siteId}/`, { line_id: this.props.liff_access_token });
      const productPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/product/list/${this.state.siteId}/`, { line_id: this.props.liff_access_token });
      const productCategoryPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/productcategory/list/${this.state.siteId}`, { line_id: this.props.liff_access_token });
      const shopPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/shop/search/line/`, filter);
      const timePromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/receive_time/search/line/`, filter);
      const settingPromise = axios.get(`${process.env.REACT_APP_BACKEND_URL}/setting/${this.state.siteId}`);
      // const areaPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/area/search/`, filter);
      // const areaPromise = axios.get(`${process.env.REACT_APP_BACKEND_URL}/area/${this.state.siteId}`);
      const areaPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/area/line/${this.state.siteId}`, { line_id: this.props.liff_access_token });
      // const customerPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/line/search/`, { site_id: this.state.siteId, line_id: this.props.liff_access_token });
      const planMaster = (await planPromise).data;
      const productMaster = (await productPromise).data;
      const productCategoryMaster = (await productCategoryPromise).data;
      const shopMaster = (await shopPromise).data;
      const timeMaster = (await timePromise).data;
      const settingMaster = (await settingPromise).data;
      const areaMaster = (await areaPromise).data;
      // const customer = (await customerPromise).data;
      // const my_customer = customer.find(c => c.line_id === this.props.liff_user_id);
      const my_customer = { ...this.props.my_customer, receive_shop: 1 };
      // 過去に選択していた店舗が削除された場合
      if (my_customer && shopMaster.find(s => s.shop_id === my_customer.receive_shop) && shopMaster.find(s => s.shop_id === my_customer.receive_shop).view_flag !== constClass.FLAG.ON) {
        my_customer.receive_shop = "";
      }
      // 商品の絞り込みの解除
      this.setState({
        selected_plan: [],
        product_category: [],
        selected_early_discount: false,
      })
      console.log(shopMaster);
      this.setState({
        planMaster,
        productMaster,
        productCategoryMaster,
        shopMaster,
        timeMaster,
        settingMaster,
        areaMaster,
        my_customer,
      });
      if (my_customer) {
        this.setState({
          receive_shop: my_customer.receive_shop,
          name: my_customer.customer_name,
          kana: my_customer.customer_kana,
          tel: my_customer.customer_tel,
          postal_code: my_customer.customer_postal_code,
          address: my_customer.customer_address,
          delivery_name: my_customer.customer_name,
          delivery_kana: my_customer.customer_kana,
          delivery_tel: my_customer.customer_tel,
          delivery_postal_code: my_customer.customer_postal_code,
          delivery_address: my_customer.customer_address,
        });
      }
      // if (this.state.settingMaster) {
      //   this.setState({
      //     setting_val: this.state.settingMaster.setting_val,
      //   });
      // }
      this.setReady();
    }
  }

  setReady() {
    if (this.state.my_order !== null &&
      this.state.my_customer !== null &&
      this.state.productMaster !== null &&
      this.state.planMaster !== null &&
      this.state.productCategoryMaster !== null &&
      this.state.shopMaster !== null) {
      this.setState({ ready: true });
    } else {
      this.setState({ ready: false });
    }
  }

  myOrderCount(productId) {
    var count = 0;
    this.state.my_order.forEach(o => {
      if ([constClass.ORDER_STATUS.REG, constClass.ORDER_STATUS.PRE, constClass.ORDER_STATUS.FIN, constClass.ORDER_STATUS.DFIN, constClass.ORDER_STATUS.UPAY].includes(o.status)) {
        o.t_order_detail.forEach(d => {
          if (d.product_id === productId) {
            count += d.count;
          }
        });
      }
    });
    return count;
  }

  setIntervalPop(productId) {
    if (!this.intervalPop[productId]) {
      this.intervalPop[productId] = setInterval(() => {
        const msg = { ...this.state.message, [productId]: undefined };
        this.setState({ message: msg });
        clearInterval(this.intervalPop[productId]);
        this.intervalPop[productId] = undefined;
      }, 2000);
    }
  }

  addCart(productId) {
    const product = this.state.productMaster.find(p => p.product_id === productId);
    const plan = this.state.planMaster.find(p => p.plan_id === product.plan_id);
    var cart = this.state.cart;
    var message = this.state.message;
    message = { ...message, [productId]: undefined };
    var message_notice = this.state.message_notice;
    // カートの企画チェック
    var planIdx = cart.findIndex(c => c.planId === product.plan_id);
    // 同じ企画の商品が無い
    if (planIdx < 0) {
      if ((product.cart_limit > 0 && this.myOrderCount(productId) + 1 > product.cart_limit)) { // 1人当たり注文上限数を超える
        message = { ...message, [productId]: 'これ以上予約できません' };
        this.setState({ message });
        // メッセージ削除タイマー設定
        this.setIntervalPop(productId);
        return;
      } else { // 1人当たり注文上限数を超えない
        cart.push({ planId: product.plan_id, detail: [], payment_flag: plan.payment_flag === constClass.FLAG.ON ? constClass.FLAG.ON : constClass.FLAG.OFF, delivery_flag: plan.delivery_flag === constClass.FLAG.ON ? constClass.FLAG.ON : constClass.FLAG.OFF });
        planIdx = cart.findIndex(c => c.planId === product.plan_id);
      }
    }
    var idx = cart[planIdx].detail.findIndex(c => c.productId === productId);
    // カートに該当商品がある
    if (idx >= 0) {
      if (((product.year_round_type !== "1" || product.stock > 0) &&
        cart[planIdx].detail[idx].count + 1 + product.order_count > product.stock)) { //在庫を超える
        message = { ...message, [productId]: '在庫上限に達しました、これ以上予約できません' };
        // メッセージ削除タイマー設定
        this.setIntervalPop(productId);
      } else if ((product.cart_limit > 0 && cart[planIdx].detail[idx].count + this.myOrderCount(product.product_id) + 1 > product.cart_limit)) { // 1人当たり注文上限数を超える
        message = { ...message, [productId]: 'これ以上予約できません' };
        // メッセージ削除タイマー設定
        this.setIntervalPop(productId);
      } else { //在庫を超えない
        cart[planIdx].detail[idx].count = cart[planIdx].detail[idx].count + 1;
      }
    }
    // カートに該当商品がない
    else {
      if ((product.cart_limit > 0 && this.myOrderCount(productId) + 1 > product.cart_limit)) { // 1人当たり注文上限数を超える
        message = { ...message, [productId]: 'これ以上予約できません' };
        // メッセージ削除タイマー設定
        this.setIntervalPop(productId);
      } else { // 1人当たり注文上限数を超えない
        cart[planIdx].detail.push({
          productId,
          price_before_tax: product.price_before_tax,
          price: product.price,
          early_discount: this.checkEarlyDiscount(product) ? constClass.FLAG.ON : constClass.FLAG.OFF,
          early_discount_rate: product.early_discount_rate,
          count: 1,
          shipping_date: product.shipping_date
        });
      }
      if (product.notice_flag === constClass.FLAG.ON) {
        message_notice = { ...message_notice, [productId]: Common.getSettingValue(this.settings, 'ORDER_DETAIL_NOTICE_ERROR', '') };
      }
    }
    this.setState({ cart, message, message_notice });
  }

  subCart(productId) {
    const product = this.state.productMaster.find(p => p.product_id === productId);
    var cart = this.state.cart;
    var message = this.state.message;
    message = { ...message, [productId]: undefined };
    var message_notice = this.state.message_notice;
    // カートの企画チェック
    var planIdx = cart.findIndex(c => c.planId === product.plan_id);
    // 同じ企画の商品がある
    if (planIdx >= 0) {
      var idx = cart[planIdx].detail.findIndex(c => c.productId === productId);
      // カートに該当商品がある
      if (idx >= 0) {
        // 1個→0個の場合は削除
        if (cart[planIdx].detail[idx].count <= 1) {
          cart[planIdx].detail.splice(idx, 1);
          // 企画内が0件になれば企画を削除
          if (cart[planIdx].detail.length <= 0) {
            cart.splice(planIdx, 1);
          }
          message_notice = { ...message_notice, [productId]: undefined };
        }
        // 他は1個マイナス
        else {
          cart[planIdx].detail[idx].count = cart[planIdx].detail[idx].count - 1;
        }
      }
      // カートに該当商品がない→何もしない
    }
    // 同じ企画の商品がない→何もしない
    this.setState({ cart, message, message_notice });
  }

  deleteCart(productId) {
    const product = this.state.productMaster.find(p => p.product_id === productId);
    var cart = this.state.cart;
    var message = this.state.message;
    message = { ...message, [productId]: undefined };
    var message_notice = this.state.message_notice;
    // カートの企画チェック
    var planIdx = cart.findIndex(c => c.planId === product.plan_id);
    // 同じ企画の商品がある
    if (planIdx >= 0) {
      var idx = cart[planIdx].detail.findIndex(c => c.productId === productId);
      // カートに該当商品がある
      if (idx >= 0) {
        cart[planIdx].detail.splice(idx, 1);
        // 企画内が0件になれば企画を削除
        if (cart[planIdx].detail.length <= 0) {
          cart.splice(planIdx, 1);
        }
        message_notice = { ...message_notice, [productId]: undefined };
      }
      // カートに該当商品がない→何もしない
    }
    // 同じ企画の商品がない→何もしない
    this.setState({ cart, message, message_notice });
  }

  setCartFlag(planId, flag_name, value) {
    var cart = this.state.cart;
    // カートの企画チェック
    var planIdx = cart.findIndex(c => c.planId === planId);
    // 同じ企画の商品がある
    if (planIdx >= 0) {
      // フラグをセット
      cart[planIdx][flag_name] = value;
    }
    // 同じ企画の商品がない→何もしない
    this.setState({ cart });
  }

  addOrder(orderId, productId) {
    const product = this.state.productMaster.find(p => p.product_id === productId);
    var order = this.state.my_order;
    var message = this.state.message;
    message = { ...message, [productId]: undefined };
    var orderIdx = order.findIndex(o => o.order_id === orderId);
    // 変更量確認
    var changed_order = this.state.changed_order;
    var corderIdx = changed_order.findIndex(o => o.order_id === orderId);
    if (corderIdx < 0) {
      changed_order.push({ order_id: orderId, [productId]: 0 });
      corderIdx = changed_order.findIndex(o => o.order_id === orderId);
    }
    changed_order[corderIdx] = { [productId]: 0, ...changed_order[corderIdx] };
    if (changed_order[corderIdx][productId] + 1 > 0  // 変更量が1以上→在庫チェックが必要
      && (product.year_round_type !== "1" || product.stock > 0) //通年設定なしまたは在庫数が0より大きい→在庫チェックが必要
      && (product.order_count + changed_order[corderIdx][productId] + 1) > product.stock) { //在庫を超える
      message = { ...message, [productId]: '在庫上限に達しました、これ以上予約できません' };
      // メッセージ削除タイマー設定
      this.setIntervalPop(productId);
    } else {
      changed_order[corderIdx][productId] += 1;
      // 変更済み
      order[orderIdx].changed = true;
      var idx = order[orderIdx].t_order_detail.findIndex(c => c.product_id === productId);
      // カートに該当商品がある
      if (idx >= 0) {
        order[orderIdx].t_order_detail[idx].count += 1;
      }
      // カートに該当商品がない
      else {
        order[orderIdx].t_order_detail.push({
          product_id: productId,
          price_before_tax: product.price_before_tax,
          price: product.price,
          early_discount: this.checkEarlyDiscount(product) ? constClass.FLAG.ON : constClass.FLAG.OFF,
          early_discount_rate: product.early_discount_rate,
          count: 1
        })
      }
    }
    this.setState({ my_order: order, changed_order, message });
  }

  subOrder(orderId, productId) {
    var order = this.state.my_order;
    var orderIdx = order.findIndex(o => o.order_id === orderId);
    var message = this.state.message;
    message = { ...message, [productId]: undefined };
    // 変更量確認
    var changed_order = this.state.changed_order;
    var corderIdx = changed_order.findIndex(o => o.order_id === orderId);
    if (corderIdx < 0) {
      changed_order.push({ order_id: orderId, [productId]: 0 });
      corderIdx = changed_order.findIndex(o => o.order_id === orderId);
    }
    changed_order[corderIdx] = { [productId]: 0, ...changed_order[corderIdx] };
    // 変更済み
    order[orderIdx].changed = true;
    var idx = order[orderIdx].t_order_detail.findIndex(c => c.product_id === productId);
    // カートに該当商品がある
    if (idx >= 0) {
      // 1個→0個の場合は0固定
      if (order[orderIdx].t_order_detail[idx].count < 1) {
        order[orderIdx].t_order_detail[idx].count = 0;
      }
      // 他は1個マイナス
      else {
        order[orderIdx].t_order_detail[idx].count -= 1;
        changed_order[corderIdx][productId] -= 1;
      }
    }
    // カートに該当商品がない→何もしない
    this.setState({ my_order: order, changed_order, message });
  }

  deleteOrder(orderId, productId) {
    var order = this.state.my_order;
    var orderIdx = order.findIndex(o => o.order_id === orderId);
    var message = this.state.message;
    message = { ...message, [productId]: undefined };
    var message_error = this.state.message_error;
    message_error = { ...message, [productId]: undefined };
    // 変更済み
    order[orderIdx].changed = true;
    var idx = order[orderIdx].t_order_detail.findIndex(c => c.product_id === productId);
    // カートに該当商品がある
    if (idx >= 0) {
      order[orderIdx].t_order_detail.splice(idx, 1);
    }
    // カートに該当商品がない→何もしない
    this.setState({ my_order: order, message, message_error });
  }

  setProductCategory(product_category) {
    this.setState({ product_category });
  }

  setArea(area) {
    this.setState({ area: Number(area) });
    // 選択中の受取店舗が選択後の地区に含まれない場合
    if (!(this.state.shopMaster.filter(s => !area || s.area_id === Number(area)).map(s => s.shop_id).includes(this.state.receive_shop))) {
      this.setState({ receive_shop: undefined }); //受取店舗クリア
      this.receiveShopRef.current.selectedIndex = 0;
    }
  }

  setReceiveShop(receive_shop) {
    this.setState({ receive_shop: Number(receive_shop) });
  }

  setReceiveDate(plan_id, date) {
    var cart = this.state.cart;
    // カートの企画チェック
    var planIdx = cart.findIndex(c => c.planId === plan_id);
    date = date ? date.substr(0, 4) + '-' + date.substr(4, 2) + '-' + date.substr(6, 2) : '';
    cart[planIdx].receiveDate = date;
    this.setState({ cart });
  }

  setReceiveTime(plan_id, time) {
    var cart = this.state.cart;
    // カートの企画チェック
    var planIdx = cart.findIndex(c => c.planId === plan_id);
    cart[planIdx].receiveTime = time;
    this.setState({ cart });
  }

  getDetailNotice(plan_id, product_id) {
    var cart = this.state.cart;
    // カートの企画チェック
    var planIdx = cart.findIndex(c => c.planId === plan_id);
    if (planIdx >= 0) {
      var detailIdx = cart[planIdx].detail.findIndex(p => p.productId === product_id);
      if (detailIdx >= 0) {
        return cart[planIdx].detail[detailIdx].notice;
      }
    }
    return null;
  }

  setDetailNotice(plan_id, product_id, value) {
    var cart = this.state.cart;
    var message_notice = this.state.message_notice;
    // カートの企画チェック
    var planIdx = cart.findIndex(c => c.planId === plan_id);
    var detailIdx = cart[planIdx].detail.findIndex(p => p.productId === product_id);
    cart[planIdx].detail[detailIdx].notice = value;
    if (value) {
      message_notice = { ...message_notice, [product_id]: undefined };
    } else {
      message_notice = { ...message_notice, [product_id]: Common.getSettingValue(this.settings, 'ORDER_DETAIL_NOTICE_ERROR', '') };
    }
    this.setState({ cart, message_notice });
  }

  setOrderReceiveDate(order_id, date) {
    var order = this.state.my_order;
    // カートの企画チェック
    var orderIdx = order.findIndex(c => c.order_id === order_id);
    date = date ? date.substr(0, 4) + '-' + date.substr(4, 2) + '-' + date.substr(6, 2) : '';
    order[orderIdx].receive_date = date;
    order[orderIdx].changed = true;
    this.setState({ my_order: order });
  }

  setOrderReceiveTime(order_id, time) {
    var order = this.state.my_order;
    // カートの企画チェック
    var orderIdx = order.findIndex(c => c.order_id === order_id);
    order[orderIdx].receive_time = time;
    order[orderIdx].changed = true;
    this.setState({ my_order: order });
  }

  checkDataInvalid() {
    var cart = this.state.cart;
    var result = false;
    cart.forEach(c => {
      if (c.receiveDate === null || c.receiveTime === null) {
        result = true;
      }
    })
    return result;
  }

  getDateString(date = null) {
    const sysdate = new Date();
    if (date === null) {
      date = sysdate;
    }
    return String(date.getFullYear()) + (('00' + String(date.getMonth() + 1)).slice(-2)) + (('00' + String(date.getDate())).slice(-2));
  }

  getShortDateString(date) {
    date = date.replace(/-/g, '');
    return Number(date.substr(4, 2)).toString() + "/" + Number(date.substr(6, 2)).toString();

  }

  checkEarlyDiscount(product) {
    return product.early_discount_start <= this.getDateString() && product.early_discount_end >= this.getDateString();
  }

  getChangeLimitFromOrder(order) {
    if (order.year_round_type === "1") {
      //受取日から変更可能日数最大を引いた日付を返す
      return moment(new Date(order.receive_date.replace(/-/g, '/'))).subtract(
        order.t_order_detail.reduce((max, d) => max < d.order_detail_product.change_limit_days ? d.order_detail_product.change_limit_days : max, '0'), 'd'
      ).format('YYYYMMDD');
    } else {
      return order.t_order_detail.reduce((min, d) => min > d.order_detail_product.change_limit ? d.order_detail_product.change_limit : min, '99999999');
    }
  }

  getMinDateReceiveDate(receive_date, productMaster) {
    //設定済の受取日と受取可能日の内小さい日付の方を返す
    const date1 = moment(new Date(receive_date.replace(/-/g, '/')));
    const date2 = moment().add(parseInt(productMaster.sort((a, b) => parseInt(b.receipt_limit_days) - parseInt(a.receipt_limit_days))[0].receipt_limit_days), 'days');
    if (date2.isAfter(date1)) return new Date(date1.format('YYYY/MM/DD'));
    return new Date(date2.format('YYYY/MM/DD'));
  }
  getDuplicateReceiveDate(productMaster) {
    if (productMaster.length <= 0) { // 0件は終了
      return [];
    }
    var res = productMaster[0].m_product_receive.map(r => r.receive_date); // 最初の1件目
    productMaster.forEach(product => {
      var arr = product.m_product_receive.map(r => r.receive_date); // receive_dateの配列作成
      res = [...res, ...arr].filter(item => res.includes(item) && arr.includes(item)); // resとarrのどちらにも含まれるものを抽出
      res = Array.from(new Set(res).values()); // Setで重複除外して配列に変換
    });
    return res.sort((a, b) => Number(a) - Number(b));
  }

  getDuplicateReceiveNgTime(productMaster, timeMaster) {
    if (productMaster.length <= 0) { // 0件は終了
      return [];
    }
    var res = productMaster[0].m_receive_ng_time.map(r => r.time_id); // 最初の1件目
    productMaster.forEach(product => {
      var arr = product.m_receive_ng_time.map(r => r.time_id); // time_idの配列作成
      res = [...res, ...arr];
      res = Array.from(new Set(res).values()); // Setで重複除外して配列に変換
    });
    return timeMaster.filter(t => !JSON.parse(Common.getSettingValue(this.settings, 'RECEIVE_TIME_BACKYARD_ONLY', '[]')).includes(t.time_id) && !res.includes(t.time_id));
  }
  getDuplicateNgShop(productMaster) {
    if (productMaster.length <= 0) { // 0件は終了
      return [];
    }
    var res = productMaster[0].m_ng_shop.map(r => r.shop_id); // 最初の1件目
    productMaster.forEach(product => {
      var arr = product.m_ng_shop.map(r => r.shop_id); // receive_dateの配列作成
      res = [...res, ...arr].filter(item => res.includes(item) && arr.includes(item)); // resとarrのどちらにも含まれるものを抽出
      res = Array.from(new Set(res).values()); // Setで重複除外して配列に変換
    });
    return res.sort((a, b) => Number(a) - Number(b));
  }

  getOrderTotal(order) {
    // 金額計算
    var subtotal = 0; //合計金額
    var discount = 0; //割引金額
    var tax_rates = []; // 税率別合計金額
    var tax = 0; //税額
    var total = 0; //税込金額
    var detail; //明細
    var payment = 0; //支払金額
    var delivery_price = 0; //配送料
    if (order.detail) { //detailがある＝カートの場合
      detail = order.detail;
    }
    if (order.t_order_detail) { //t_order_detailがある＝Orderデータの場合
      detail = order.t_order_detail;
    }
    // 金額計算を待ち合わせる
    if (Common.getSettingValue(this.settings, 'TAX_INCLUDED_FLAG', '0') === '1') {
      // 内税計算式
      (detail.forEach((item) => {
        const product = this.state.productMaster.find(p => p.product_id === item.product_id || p.product_id === item.productId);
        // 合計金額: 税込単価 * 数量
        var st = product.price * item.count;
        subtotal += st;

        // 割引金額: 税込金額 * 割引率 の切り上げ
        var dc = 0;
        if (item.early_discount === constClass.FLAG.ON) {
          dc = Common.calcRounding(this.settings, 'DISCOUNT', st * product.early_discount_rate);
        }
        discount += dc;

        //税率別合計金額
        const tax_idx = tax_rates.findIndex(t => t.tax_rate === Number(product.tax_rate));
        // 既に税率がある
        if (tax_idx >= 0) {
          tax_rates[tax_idx].total += (st - dc); // 合計金額 - 割引金額
        }
        // 新しい税率
        else {
          tax_rates.push({ tax_rate: Number(product.tax_rate), total: (st - dc) }); // 合計金額 - 割引金額
        }

        // 送料が必要な場合
        if (product.shipping_fee_flag === constClass.FLAG.ON) {
          delivery_price = Number(Common.getSettingValue(this.settings, 'DELIVERY_FEE', '0'));
        }
      }));
      tax = tax_rates.reduce((sum, item) => sum += Common.calcRounding(this.settings, 'TAX', item.total * item.tax_rate / (1 + item.tax_rate)), 0);// 税額計算 sum(合計金額 - 割引金額) * 税率 / (1 + 税率)
      total = tax_rates.reduce((sum, item) => sum += item.total, 0); // 総合計　sum(合計金額 - 割引金額)
    } else {
      // 外税計算式
      (detail.forEach((item) => {
        const product = this.state.productMaster.find(p => p.product_id === item.product_id || p.product_id === item.productId);
        // 合計金額: 税抜単価 * 数量
        var st = product.price_before_tax * item.count;
        subtotal += st;

        // 割引金額: 税抜金額 * 割引率 の切り上げ
        var dc = 0;
        if (item.early_discount === constClass.FLAG.ON) {
          dc = Common.calcRounding(this.settings, 'DISCOUNT', st * product.early_discount_rate);
        }
        discount += dc;

        //税率別合計金額
        const tax_idx = tax_rates.findIndex(t => t.tax_rate === Number(product.tax_rate));
        // 既に税率がある
        if (tax_idx >= 0) {
          tax_rates[tax_idx].total += (st - dc); // 合計金額 - 割引金額
        }
        // 新しい税率
        else {
          tax_rates.push({ tax_rate: Number(product.tax_rate), total: (st - dc) }); // 合計金額 - 割引金額
        }

        // 送料が必要な場合
        if (product.shipping_fee_flag === constClass.FLAG.ON) {
          delivery_price = Number(Common.getSettingValue(this.settings, 'DELIVERY_FEE', '0'));
        }
      }));
      tax = tax_rates.reduce((sum, item) => sum += Common.calcRounding(this.settings, 'TAX', item.total * item.tax_rate), 0);// 税額計算 sum(合計金額 - 割引金額) * 税率
      total = tax_rates.reduce((sum, item) => sum += Common.calcRounding(this.settings, 'TAX', item.total * (1 + item.tax_rate)), 0); // 総合計 sum(合計金額 - 割引金額) * (1 + 税率)
    }
    if (order.delivery_flag === constClass.FLAG.ON && !(this.props.page === constClass.REQUEST)) {
      total += Number(delivery_price);
    }
    return { subtotal, discount, tax, total, payment, tax_rates, delivery_price };
  }

  displayCartCount(plan_id, product_id) {
    var ret = 0;
    if (this.state.cart.find(c => c.planId === plan_id)) { //企画有無チェック
      if (this.state.cart.find(c => c.planId === plan_id).detail.find(c => c.productId === product_id)) { //商品有無チェック
        ret = this.state.cart.find(c => c.planId === plan_id).detail.find(c => c.productId === product_id).count;
      } else {
        ret = 0;
      }
    } else {
      ret = 0;
    }
    const product = this.state.productMaster.find(p => p.product_id === product_id);
    if ((product.year_round_type !== "1" || product.stock > 0) && (product.stock - product.order_count) <= 0) { //在庫切れ
      ret = '売り切れ';
    }
    if (product.m_ng_shop.map(s => s.shop_id).includes(this.state.my_customer.receive_shop)) { //受取不可
      ret = '受取対象外';
    }
    return ret;
  }

  checkOrderChangeLimit(checkorderid = null) {
    if (checkorderid === null && this.props.current_order) checkorderid = this.props.current_order;
    if (checkorderid) {
      const order = this.state.my_order.find(o => o.order_id === checkorderid);
      if (order.status !== constClass.ORDER_STATUS.REG && order.status !== constClass.ORDER_STATUS.PRE && order.status !== constClass.ORDER_STATUS.UPAY) { // ステータスが確定（注文済み）以外は変更不可
        return false
      }
      const change_limit = this.getChangeLimitFromOrder(order);
      if (this.getDateString() <= change_limit) { // 今日が変更可能日より前なら変更可
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  setSelectedPlan(selected_plan) {
    this.setState({ selected_plan });
  }

  toggleEarlyDiscount() {
    var selected_early_discount = this.state.selected_early_discount;
    if (selected_early_discount) { //既に選択済み
      selected_early_discount = false;
    } else { //未選択
      selected_early_discount = true;
    }
    this.setState({ selected_early_discount });
  }

  // 注文情報DB登録
  async submit() {
    if (this.checkDataInvalid() || this.state.disabled) {
      return;
    }
    if ((this.state.cart.filter(c => c.payment_flag === constClass.FLAG.ON).length <= 0 || this.state.cart.reduce((prev, c) => prev + this.getOrderTotal(c).total, 0) <= 0) && !window.confirm('注文を確定しますがよろしいですか？')) {
      return;
    }
    this.setState({ disabled: true });
    var message = this.state.message;
    // 結果保持用変数群
    var my_order = []; // orderデータ
    var errors = []; // エラー
    // 結果待ち合わせ
    await Promise.all(this.state.cart.map(async (plan) => {
      // const p = this.state.planMaster.find(p => p.plan_id === plan.planId);
      // orderデータ準備
      var data = {
        'site_id': this.state.siteId,
        'line_id': this.props.liff_access_token,
        'customer_id': this.state.my_customer.customer_id,
        'customer_name': this.state.my_customer.customer_name,
        'customer_kana': this.state.my_customer.customer_kana,
        'receive_shop': this.state.my_customer.receive_shop,
        'customer_tel': this.state.my_customer.customer_tel,
        // 'payment_flag': [constClass.FLAG.ON, constClass.FLAG.BOTH].includes(p.payment_flag) ? constClass.FLAG.ON : constClass.FLAG.OFF,
        // 'delivery_flag': [constClass.FLAG.ON, constClass.FLAG.BOTH].includes(p.delivery_flag) ? constClass.FLAG.ON : constClass.FLAG.OFF,
        // 'customer_postal_code': [constClass.FLAG.ON, constClass.FLAG.BOTH].includes(p.delivery_flag) ? this.state.my_customer.customer_postal_code : null,
        // 'customer_address': [constClass.FLAG.ON, constClass.FLAG.BOTH].includes(p.delivery_flag) ? this.state.my_customer.customer_address : null,
        'payment_flag': plan.payment_flag,
        'delivery_flag': plan.delivery_flag,
        'customer_postal_code': plan.delivery_flag === constClass.FLAG.ON ? this.state.my_customer.customer_postal_code : null,
        'customer_address': plan.delivery_flag === constClass.FLAG.ON ? this.state.my_customer.customer_address : null,
        'delivery_name': plan.delivery_flag === constClass.FLAG.ON ? this.state.my_customer.customer_name : null,
        'delivery_kana': plan.delivery_flag === constClass.FLAG.ON ? this.state.my_customer.customer_kana : null,
        'delivery_tel': plan.delivery_flag === constClass.FLAG.ON ? this.state.my_customer.customer_tel : null,
        'delivery_postal_code': plan.delivery_flag === constClass.FLAG.ON ? this.state.my_customer.customer_postal_code : null,
        'delivery_address': plan.delivery_flag === constClass.FLAG.ON ? this.state.my_customer.customer_address : null,
        'plan_id': plan.planId,
        'receive_date': plan.receiveDate,
        'receive_time': plan.receiveTime,
        'employee_flag': this.state.my_customer.employee_flag,
        't_order_detail': [],
      };
      // order_detailデータ準備
      plan.detail.forEach((detail) => {
        data.t_order_detail.push({
          'site_id': this.state.siteId,
          'product_id': detail.productId,
          'early_discount': detail.early_discount,
          'count': detail.count,
          'notice': detail.notice,
        });
      });
      // API実行
      try {
        const result = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/order/line/`, data)).data;
        if (result.result) {
          my_order.push(result.order);
        } else {
          errors.push('エラーが発生しました。もう一度お試しください。');
        }
      } catch (err) {
        errors.push(err);
      }
    }));

    // エラー件数0
    if (errors.length === 0) {
      // 事前決済判定
      // const plan = this.state.planMaster.filter(p => my_order.map(o => o.plan_id).includes(p.plan_id) && p.payment_flag === constClass.FLAG.ON).map(p => p.plan_id);
      // console.log(plan);
      if (my_order.filter(o => o.payment_flag === constClass.FLAG.ON).length > 0 && my_order.reduce((prev, c) => prev + this.getOrderTotal(c).total, 0) > 0) {
        await this.refreshOrder();
        this.setState({ cart: [] });
        const p = this.state.planMaster.find(p => p.plan_id === my_order.find(o => o.payment_flag === constClass.FLAG.ON && o.total > 0).plan_id);
        await this.submitPayment(my_order.find(o => o.payment_flag === constClass.FLAG.ON && o.total > 0).order_id, p.plan_name);

      } else {
        await this.refreshOrder();
        this.refreshData();
        this.setState({ order_finish: my_order, cart: [], disabled: false, message: {} });
        this.props.history.push(`/${this.state.siteId}/?page=${constClass.ORDERFINISH}`);
      }
    }
    // エラーあり
    else {
      errors.forEach((e) => {
        if (e.response.data && e.response.data.message) {
          try {
            const error_list = JSON.parse(e.response.data.message);
            error_list.forEach(item => {
              message = { ...message, [item]: '在庫上限に達したため予約できません' };
            });
          } catch (err) {
            console.log(util.inspect(err));
          }
        }
      })
      await this.refreshData();
      this.setState({ disabled: false, message });
    }
  }

  // 注文変更：処理
  async submitChange() {
    if (!this.state.my_order.reduce((flag, order) => (flag || order.changed), false) || this.state.disabled) {
      return;
    }
    this.setState({ disabled: true });
    var message = this.state.message;
    // 結果保持用変数群
    var my_order = []; // orderデータ
    var errors = []; // エラー
    // 結果待ち合わせ
    await Promise.all(this.state.my_order.filter(o => o.changed).map(async (order) => {
      // orderデータ準備
      order.line_id = this.props.liff_access_token;
      // API実行
      try {
        const result = (await axios.put(`${process.env.REACT_APP_BACKEND_URL}/order/line/${this.state.siteId}/${order.order_id}`, order)).data;
        if (result.result) {
          my_order.push(result.order);
        } else {
          errors.push('エラーが発生しました。もう一度お試しください。');
        }
      } catch (err) {
        errors.push(err);
      }
    }));

    // エラー件数0
    if (errors.length === 0) {
      // 事前決済判定
      if (my_order.filter(o => o.status === constClass.ORDER_STATUS.UPAY).length > 0) {
        await this.refreshOrder();
        await this.submitPayment(my_order.find(o => o.status === constClass.ORDER_STATUS.UPAY).order_id);

      } else {
        await this.refreshOrder();
        this.refreshData();
        this.setState({ order_finish: my_order, cart: [], disabled: false, message: {}, changed_order: [] });
        this.props.history.push(`/${this.state.siteId}/?page=${constClass.CHANGEFINISH}`);
      }
    }
    // エラーあり
    else {
      errors.forEach((e) => {
        if (e.response.data && e.response.data.message) {
          try {
            const error_list = JSON.parse(e.response.data.message);
            error_list.forEach(item => {
              message = { ...message, [item]: '在庫上限に達したため予約できません' };
            });
          } catch (err) {
            console.log(util.inspect(err));
            window.alert('エラーが発生しました。\n' + e.response.data.message);
          }
        }
      })
      await this.refreshData();
      this.setState({ disabled: false, message });
    }
  }

  async submitReceiveShop() {
    if (this.state.disabled) {
      return;
    }
    this.setState({ disabled: true });
    var data = {
      'site_id': this.state.siteId,
      'line_id': this.props.liff_access_token,
      'customer_name': this.state.my_customer ? this.state.my_customer.customer_name : null,
      'customer_kana': this.state.my_customer ? this.state.my_customer.customer_kana : null,
      'receive_shop': this.state.receive_shop,
      'customer_tel': this.state.my_customer ? this.state.my_customer.customer_tel : null,
      'employee_flag': this.state.my_customer ? this.state.my_customer.employee_flag : null,
      'customer_postal_code': this.state.my_customer ? this.state.my_customer.customer_postal_code : null,
      'customer_address': this.state.my_customer ? this.state.my_customer.customer_address : null,
    };
    try {
      // const my_customer = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/`, data)).data;
      const my_customer = data;
      var message = {};
      var cart = this.state.cart;
      this.state.productMaster.forEach(item => {
        if (item.m_ng_shop.map(s => s.shop_id).includes(my_customer.receive_shop)) {
          message = { ...message, [item.product_id]: `${this.state.shopMaster.find(s => s.shop_id === my_customer.receive_shop).shop_name}では受け取りできません` };
          var cartIdx = cart.findIndex(p => p.planId === item.plan_id);
          if (cartIdx >= 0) { // 企画商品を選択済み
            var idx = cart[cartIdx].detail.findIndex(p => p.productId === item.product_id);
            if (idx >= 0) { // 商品をカートに追加済み
              cart[cartIdx].detail.splice(idx, 1); // 受取不可の商品はカートから削除する
            }
          }
        } else {
          message = { ...message, [item.product_id]: undefined };
        }
      });
      this.setState({ my_customer, disabled: false, message, cart });
      this.props.history.push(`/${this.state.siteId}/?page=${constClass.REQUEST}`);
    } catch (err) {
      // alert(err.toString());
      alert('システムエラーが発生しました。');
      this.setState({ message: err, disabled: false });
    }
  }

  async submitCustomer(page) {
    if (this.state.disabled) {
      return;
    }
    this.setState({ disabled: true });
    if (this.props.plan_id) {
      // plan_idが選択されている（カート内の配送先変更）場合は該当のカートの配送先のみ変更
      this.state.cart.filter(c => c.planId === this.props.plan_id).forEach(c => {
        c.delivery_name = this.state.delivery_name;
        c.delivery_kana = this.state.delivery_kana;
        c.delivery_tel = this.state.delivery_tel;
        c.delivery_postal_code = this.state.delivery_postal_code;
        c.delivery_address = this.state.delivery_address;
      });
    } else {
      // カートがあればカート内に値を追加
      this.state.cart.forEach(c => {
        c.delivery_name = this.state.delivery_name;
        c.delivery_kana = this.state.delivery_kana;
        c.delivery_tel = this.state.delivery_tel;
        c.delivery_postal_code = this.state.delivery_postal_code;
        c.delivery_address = this.state.delivery_address;
      });
    }
    var data = {
      'site_id': this.state.siteId,
      'line_id': this.props.liff_access_token,
      'customer_name': this.state.name,
      'customer_kana': this.state.kana,
      'receive_shop': this.state.my_customer ? this.state.my_customer.receive_shop : (this.state.receive_shop !== "" ? this.state.receive_shop : null),
      'customer_tel': this.state.tel,
      'employee_flag': this.state.my_customer ? this.state.my_customer.employee_flag : null,
      'customer_postal_code': this.state.postal_code,
      'customer_address': this.state.address,
    };
    try {
      const my_customer = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/`, data)).data;
      this.setState({ my_customer, disabled: false, message: {}, delivery_check: false });
      this.props.history.push(`/${this.state.siteId}/?page=${page}`);
    } catch (err) {
      // alert(err.toString());
      alert('システムエラーが発生しました。');
      this.setState({ message: err, disabled: false });
    }
  }

  async submitCardEdit() {
    const payment_data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/order/member/${this.state.siteId}/${this.props.current_order}`)).data;

    const form = document.createElement('form');
    form.action = payment_data.url;
    form.method = payment_data.method;

    // body に追加
    document.body.append(form);
    for (const i in payment_data.data) {
      // obj.hasOwnProperty() はオブジェクトのプロトタイプチェーンからプロパティを絞り込むために使用しています
      if (payment_data.data.hasOwnProperty(i)) {
        const input = document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', i);
        input.setAttribute('value', payment_data.data[i]);
        form.appendChild(input);
      }
    }

    // submit
    form.submit();
  }

  async submitPayment(order_id, plan_name = null) {
    this.setState({ disabled: true });
    const params = {
      site_id: this.state.siteId,
      Overview: plan_name,
      line_id: this.props.liff_access_token
    };
    const payment_data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/order/payment/${this.state.siteId}/${order_id}`, params)).data;
    console.log(payment_data);

    // urlがある⇒画面遷移
    if (payment_data.url) {
      const form = document.createElement('form');
      form.action = payment_data.url;
      form.method = payment_data.method;

      // body に追加
      document.body.append(form);
      for (const i in payment_data.data) {
        // obj.hasOwnProperty() はオブジェクトのプロトタイプチェーンからプロパティを絞り込むために使用しています
        if (payment_data.data.hasOwnProperty(i)) {
          const input = document.createElement('input');
          input.setAttribute('type', 'hidden');
          input.setAttribute('name', i);
          input.setAttribute('value', payment_data.data[i]);
          form.appendChild(input);
        }
      }

      // submit
      form.submit();
    } else {
      // urlが無い⇒paymentモジュール画面へ遷移
      // this.setState({ payment_data: payment_data.data, disabled: false });
      this.props.setPaymentData(payment_data.data, this.returnPayment);
      this.props.history.push(`/${this.state.siteId}/?page=${constClass.PAYMENT}&current_order=${order_id}`);
    }
  }

  async returnPayment() {
    await this.refreshOrder();
  }

  moveOrderCustomer() {
    if (this.state.disabled) {
      return;
    }
    var order = this.state.my_order;
    // カートの企画チェック
    var orderIdx = order.findIndex(c => c.order_id === this.props.current_order);
    var name = order[orderIdx].customer_name;
    var kana = order[orderIdx].customer_kana;
    var tel = order[orderIdx].customer_tel;
    var postal_code = order[orderIdx].customer_postal_code;
    var address = order[orderIdx].customer_address;
    var delivery_name = order[orderIdx].delivery_name;
    var delivery_kana = order[orderIdx].delivery_kana;
    var delivery_tel = order[orderIdx].delivery_tel;
    var delivery_postal_code = order[orderIdx].delivery_postal_code;
    var delivery_address = order[orderIdx].delivery_address;
    this.setState({ name, kana, tel, postal_code, address, delivery_name, delivery_kana, delivery_tel, delivery_postal_code, delivery_address });
    this.props.history.push(`/${this.state.siteId}/?page=${constClass.ORDERUSERCHANGE}&current_order=${this.props.current_order}`);
  }

  cancelOrderCustomer() {
    if (this.state.disabled) {
      return;
    }
    var name = this.state.my_customer.customer_name;
    var kana = this.state.my_customer.customer_kana;
    var tel = this.state.my_customer.customer_tel;
    var postal_code = this.state.my_customer.customer_postal_code;
    var address = this.state.my_customer.customer_address;
    this.setState({ name, kana, tel, postal_code, address, delivery_name: name, delivery_kana: kana, delivery_tel: tel, delivery_postal_code: postal_code, delivery_address: address });
    if (this.props.history.length <= 1) {
      this.props.history.push(`/${this.state.siteId}/`);
    } else {
      this.props.history.goBack();
    }
  }

  submitOrderCustomer() {
    if (this.state.disabled) {
      return;
    }
    var order = this.state.my_order;
    // カートの企画チェック
    var orderIdx = order.findIndex(c => c.order_id === this.props.current_order);
    order[orderIdx].customer_name = this.state.name;
    order[orderIdx].customer_kana = this.state.kana;
    order[orderIdx].customer_tel = this.state.tel;
    order[orderIdx].customer_postal_code = this.state.postal_code;
    order[orderIdx].customer_address = this.state.address;
    order[orderIdx].delivery_name = this.state.delivery_name;
    order[orderIdx].delivery_kana = this.state.delivery_kana;
    order[orderIdx].delivery_tel = this.state.delivery_tel;
    order[orderIdx].delivery_postal_code = this.state.delivery_postal_code;
    order[orderIdx].delivery_address = this.state.delivery_address;
    order[orderIdx].changed = true;
    var name = this.state.my_customer.customer_name;
    var kana = this.state.my_customer.customer_kana;
    var tel = this.state.my_customer.customer_tel;
    var postal_code = this.state.my_customer.customer_postal_code;
    var address = this.state.my_customer.customer_address;
    this.setState({ my_order: order, name, kana, tel, postal_code, address, delivery_name: name, delivery_kana: kana, delivery_tel: tel, delivery_postal_code: postal_code, delivery_address: address });
    this.props.history.goBack();
  }

  moveOrderShop() {
    if (this.state.disabled) {
      return;
    }
    var order = this.state.my_order;
    // カートの企画チェック
    var orderIdx = order.findIndex(c => c.order_id === this.props.current_order);
    var receive_shop = order[orderIdx].receive_shop;
    var before_area = this.state.area;
    this.setState({ receive_shop, before_area, area: null });
    this.props.history.push(`/${this.state.siteId}/?page=${constClass.ORDERSHOPCHANGE}&current_order=${this.props.current_order}`);
  }

  cancelOrderShop() {
    if (this.state.disabled) {
      return;
    }
    var receive_shop = this.state.my_customer.receive_shop;
    var before_area = this.state.before_area;
    this.setState({ receive_shop, area: before_area, before_area: null });
    if (this.props.history.length <= 1) {
      this.props.history.push(`/${this.state.siteId}/`);
    } else {
      this.props.history.goBack();
    }
  }

  submitOrderShop() {
    if (this.state.disabled) {
      return;
    }
    var order = this.state.my_order;
    var message_error = this.state.message_error;

    // カートの企画チェック
    var orderIdx = order.findIndex(c => c.order_id === this.props.current_order);
    order[orderIdx].receive_shop = this.state.receive_shop;
    order[orderIdx].changed = true;
    var receive_shop = this.state.my_customer.receive_shop;
    var before_area = this.state.before_area;
    console.log(order[orderIdx].t_order_detail)
    // 受取不可店舗チェック
    order[orderIdx].t_order_detail.forEach(item => {
      if (item.order_detail_product.ng_product.map(s => s.shop_id).includes(this.state.receive_shop)) { //受取不可店舗に含む
        message_error = { ...message_error, [item.product_id]: 'この店舗では受け取りできません' };
      } else {
        message_error = { ...message_error, [item.product_id]: undefined };
      }
    })
    this.setState({ my_order: order, receive_shop, area: before_area, before_area: null, message_error });
    this.props.history.goBack();
  }

  async cancelChange() {
    if (this.state.disabled) {
      return;
    }
    this.setState({ disabled: true });
    try {
      await this.refreshOrder();
      if (this.props.history.length <= 1) {
        this.props.history.push(`/${this.state.siteId}/?page=${constClass.MENU}`);
      } else {
        if (this.props.history.location.search.match(/retmenu=1/)) {
          this.props.history.push(`/${this.state.siteId}/?page=${constClass.MENU}`);
        } else {
          this.props.history.goBack();
        }
      }
    } catch (err) {
      alert(err.toString());
      this.setState({ message: err, disabled: false });
    }
  }

  // お受け取り分ボタン：処理
  async submitCurrentOrder(order_id, page) {
    this.setState({ message: {} });
    // this.props.setCurrentOrder(order_id);
    this.props.history.push(`/${this.state.siteId}/?page=${page}&current_order=${order_id}`);
    this.scrollPageTop();
  }

  // 受け取るボタン：処理
  async submitReceive() {
    if (this.state.disabled) {
      return;
    }
    if (!window.confirm('本当に受け取りますか？受け取りはスタッフの確認が必要です')) {
      return;
    }
    this.setState({ disabled: true });
    // 送信データ
    const data = {
      status: constClass.ORDER_STATUS.FIN,
      line_id: this.props.liff_access_token,
    }
    // API実行
    try {
      const result = (await axios.put(`${process.env.REACT_APP_BACKEND_URL}/order/status/line/${this.state.siteId}/${this.props.current_order}`, data)).data;
      if (result.result) {
        this.setState({ disabled: false, message: {} });
        this.refreshOrder();
        this.props.history.push(`/${this.state.siteId}/?page=${constClass.RECEIVEFINISH}`);
      } else {
        alert('エラーが発生しました。もう一度お試しください。');
        this.setState({ disabled: false, message: {} });
      }
    } catch (err) {
      // alert(util.inspect(err));
      if (err.response && err.response.data && err.response.data.msg) {
        alert(err.response.data.msg);
      } else {
        alert('システムエラーが発生しました。');
      }
      this.setState({ disabled: false, message: {} });
    }
  }

  submitRequest() {
    this.props.history.push(`/${this.state.siteId}/?page=${constClass.CONFIRM}`);
    this.setState({ message: {} });
    this.scrollPageTop();
  }

  async submitCancel() {
    if (this.state.disabled) {
      return;
    }
    if (!window.confirm('本当にキャンセルしますか？この操作は取り消しできません')) {
      return;
    }
    this.setState({ disabled: true });
    // 送信データ
    const data = {
      status: constClass.ORDER_STATUS.CCL,
      line_id: this.props.liff_access_token,
    }
    // API実行
    try {
      const result = (await axios.put(`${process.env.REACT_APP_BACKEND_URL}/order/status/line/${this.state.siteId}/${this.props.current_order}`, data)).data;
      if (result.result) {
        this.setState({ disabled: false, message: {} });
        this.refreshOrder();
        window.alert(`注文をキャンセルしました。`);
        this.props.history.push(`/${this.state.siteId}/?page=${constClass.HISTORY}`);
      } else {
        alert('エラーが発生しました。もう一度お試しください。');
        this.setState({ disabled: false, message: {} });
      }
    } catch (err) {
      // alert(util.inspect(err));
      if (err.response && err.response.data && err.response.data.msg) {
        alert(err.response.data.msg);
      } else {
        alert('システムエラーが発生しました。');
      }
      this.setState({ disabled: false, message: {} });
    }
  }

  async submitEmployee() {
    if (this.state.disabled) {
      return;
    }
    this.setState({ disabled: true });
    // 送信データ
    const data = {
      site_id: this.state.siteId,
      line_id: this.props.liff_access_token,
      employee_flag: constClass.FLAG.ON
    }
    // API実行
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/employee`, data);
      this.setState({ disabled: false, message: {} });
      this.refreshData();
      window.alert(`登録が完了しました。`);
      this.props.history.replace(generatePath(`${this.props.match.path}`, { siteId: this.state.siteId }));
    } catch (err) {
      // alert(util.inspect(err));
      if (err.response && err.response.data && err.response.data.msg) {
        alert(err.response.data.msg);
      } else {
        alert('システムエラーが発生しました。');
      }
      this.setState({ disabled: false, message: {} });
    }
  }

  async submitEmployeeCancel() {
    if (this.state.disabled) {
      return;
    }
    if (!window.confirm('本当に従業員登録を解除しますか？')) {
      return;
    }
    this.setState({ disabled: true });
    // 送信データ
    const data = {
      site_id: this.state.siteId,
      line_id: this.props.liff_access_token,
      employee_flag: constClass.FLAG.OFF
    }
    // API実行
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/employee`, data);
      this.setState({ disabled: false, message: {} });
      this.refreshData();
      window.alert(`登録を解除しました。`);
      this.props.history.replace(generatePath(`${this.props.match.path}`, { siteId: this.state.siteId }));
    } catch (err) {
      // alert(util.inspect(err));
      if (err.response && err.response.data && err.response.data.msg) {
        alert(err.response.data.msg);
      } else {
        alert('システムエラーが発生しました。');
      }
      this.setState({ disabled: false, message: {} });
    }
  }

  handleRequestClick() {
    this.props.setPage(constClass.REQUEST);
  }

  async searchAddress(postal_code, target) {
    this.setState({ disabled: true });
    const message = this.state.message;
    // API実行
    try {
      const data = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/address/${postal_code}`)).data;
      if (data) {
        var address = '';
        if (data.PrefecturesName) {
          address += data.PrefecturesName;
        }
        if (data.CityName) {
          address += data.CityName;
        }
        if (data.TownName) {
          address += data.TownName;
        }
        if (target === '' && this.state.delivery_check) {
          this.setState({ disabled: false, [target + 'address']: address, delivery_address: address, message: { ...message, [target + 'postal_code']: '', delivery_postal_code: '' } });
        } else {
          this.setState({ disabled: false, [target + 'address']: address, message: { ...message, [target + 'postal_code']: '' } });
        }
      } else {
        this.setState({ disabled: false, message: { ...message, [target + 'postal_code']: '郵便番号が見つかりません' } });
      }
    } catch (err) {
      // alert(util.inspect(err));
      if (err.response && err.response.data && err.response.data.msg) {
        alert(err.response.data.msg);
      } else {
        alert('システムエラーが発生しました。');
      }
      this.setState({ disabled: false });
    }
  }

  changeCustomer() {
    this.props.history.push(`/${this.state.siteId}/?page=${constClass.USERCHANGE}&plan_id=0`);
  }

  checkUserInvalid() {
    var result = false;
    // 基本情報の確認
    if (!this.state.name || !this.state.kana || !this.state.tel || this.state.disabled || !this.state.privacypolicy) {
      result = true;
    }
    // 注文入力時に配送専用がある or 注文変更時に配送のデータがある
    if ((this.props.page === constClass.USER && this.state.planMaster.filter(p => this.state.cart.map(c => c.planId).includes(p.plan_id)).reduce((prev, p) => [constClass.FLAG.ON].includes(p.delivery_flag) ? true : prev, false)) || //注文時ユーザ登録
      (this.props.page === constClass.ORDERUSERCHANGE && this.state.my_order.find(o => o.order_id === this.props.current_order).delivery_flag === constClass.FLAG.ON)) { // 注文情報変更
      if (!this.state.postal_code || !this.state.address ||
        !this.state.delivery_name || !this.state.delivery_kana || !this.state.delivery_tel || !this.state.delivery_postal_code || !this.state.delivery_address) {
        result = true;
      }
    }
    // エラーメッセージの確認
    if (this.state.message.kana || this.state.message.tel || this.state.message.postal_code || this.state.message.delivery_kana || this.state.message.delivery_tel || this.state.message.delivery_postal_code) {
      result = true;
    }

    return result;
  }

  renderHeader() {
    return (
      <header className="header">
        <div className="row mx-0" ref={this.headerRef}>
          <div className="col text-center">
            {Common.getSettingValue(this.settings, 'TITLE_LOGO', '') &&
              <img src={`/${this.state.siteId}/${Common.getSettingValue(this.settings, 'TITLE_LOGO', '')}`} alt="logo" onLoad={() => this.changeHeight(this.state)} />
            }
          </div>
        </div>
      </header>
    );
  }
  renderPagetitle() {
    const titlename = () => {
      switch (this.props.page) {
        case constClass.PLAN: return `受取希望店舗選択`;
        case constClass.REQUEST: return `ご注文商品 選択`;
        case constClass.USER: return `お客様情報入力`;
        case constClass.CONFIRM: return `ご注文情報確認`;
        case constClass.ORDERFINISH: return `注文完了`;
        case constClass.HISTORY: return `ご注文履歴`;
        case constClass.CHANGE: return `ご注文履歴`;
        case constClass.CHANGEFINISH: return `注文完了`;
        case constClass.RECEIVESELECT: return `ご注文履歴`;
        case constClass.RECEIVE: return `ご注文履歴`;
        case constClass.RECEIVEFINISH: return `お受け取り完了`;
        case constClass.USERCHANGE: return `お客様情報`;
        case constClass.ORDERUSERCHANGE: return `ご注文履歴`;
        case constClass.ORDERSHOPCHANGE: return `ご注文履歴`;
        case constClass.PAYMENT: return `お支払い`;
        default: return `メニュー`;
      }
    }
    return (
      <div>
        <div className="row mx-0">
          <div className="col section py-0 line-height-2-2"
          // ref={(el) => { // テーマカラー
          //   if (el) {
          //     el.style.setProperty('background-color', chroma(this.state.setting_val).alpha(0.3).css(), 'important');
          //   }
          // }}>
          >
            <span className="d-inline-block align-middle">
              {titlename(this)}</span>
          </div>
        </div>
      </div>
    );
  }

  // 絞り込み機能
  renderCondition() {
    return (
      <div className="row bg-ash mx-0 my-0 py-2 px-1-env">
        <div className="col">
          <div className="row">
            <div className="col">
              <Accordion allowZeroExpanded>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton className="px-0 py-2 m-0 border-none bg-white text-center">
                      絞り込み
                      <AccordionItemState>
                        {({ expanded }) => (expanded ? '▲' : '▼')}
                      </AccordionItemState>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className="p-1">
                    <div className="row">
                      <div className="col align-self-center text-center">
                        {this.state.planMaster.length > 1 &&
                          <div className="row mx--1">
                            <div className="col px-1 text-left">
                              {Common.getSettingValue(this.settings, 'PRODUCT_SEARCH_NAME', '')}から絞り込む
                            </div>
                          </div>
                        }
                        {this.state.planMaster.length > 1 &&
                          <div className="row mx--1">
                            <div className="col px-1">
                              <Select
                                options={this.state.planMaster.map((item) => ({ label: item.plan_name, value: item.plan_id }))}
                                isMulti
                                value={this.state.selected_plan}
                                onChange={(item) => this.setSelectedPlan(item)}
                                placeholder={`選択してください`} />
                            </div>
                          </div>
                        }
                        {this.state.productCategoryMaster.length > 1 &&
                          <div className="row mx--1">
                            <div className="col px-1 text-left">
                              カテゴリから絞り込む
                            </div>
                          </div>
                        }
                        {this.state.productCategoryMaster.length > 1 &&
                          <div className="row mx--1">
                            <div className="col px-1">
                              <Select
                                options={this.state.productCategoryMaster.map((item) => ({ label: item.product_category_name, value: item.product_category_id }))}
                                isMulti
                                value={this.state.product_category}
                                onChange={(item) => this.setProductCategory(item)}
                                placeholder={`選択してください`} />
                            </div>
                          </div>
                        }
                        {this.state.productMaster.reduce((flg, item) => flg || this.checkEarlyDiscount(item), false) &&
                          <div className="row mx--1">
                            <div className="col px-1 text-left">
                              割引対象から絞り込む
                            </div>
                          </div>
                        }
                        {this.state.productMaster.reduce((flg, item) => flg || this.checkEarlyDiscount(item), false) &&
                          <div className="row mx--1">
                            <div className="col px-1">
                              <button
                                className={`btn btn-${this.state.selected_early_discount ? 'active' : 'enable'} w-100 h-100 py-1 px-1`}
                                onClick={() => this.toggleEarlyDiscount()}>
                                <p className="mb-0 font-weight-bold">早割対象</p>
                              </button>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderMenu() {
    return (
      <div className="row mx-0">
        <div className="col px-0">
          <div className="row mx-0">
            <div className="col py-2 text-center">
              <span>ご希望のメニューをお選びください</span>
            </div>
          </div>
          <div>
            <div className="row mx-0 px-3-env pt-2 pb-1">
              <div className="col py-1 px-1 align-self-center">
                <div className="row mx-0 mt-3 mb-3 text-center">
                  <div className="col px-0">
                    <Link to={`/${this.state.siteId}/?page=${constClass.PLAN}`}>
                      <button
                        className={`btn btn-enable w-100 h-100 py-2 px-2`}>
                        <h4 className="mb-0 font-weight-bold">ご注文</h4>
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="row mx-0 mt-3 mb-0 text-center">
                  <div className="col px-0">
                    <Link to={`/${this.state.siteId}/?page=${constClass.HISTORY}`}>
                      <button
                        className={`btn btn-enable w-100 h-100 py-2 px-2`}>
                        <h4 className="mb-0 font-weight-bold">ご注文履歴</h4>
                      </button>
                    </Link>
                  </div>
                </div>
                {/* <div className="row mx-0 mt-0 mb-3 text-center">
                  <div className="col px-0">
                    {this.state.my_order.find(o => o.status === constClass.ORDER_STATUS.UPAY) &&
                      <Link to={`/${this.state.siteId}/?page=${constClass.HISTORY}`}>
                        <span className="text-danger">お支払いが済んでいないご注文がございます</span>
                      </Link>
                    }
                  </div>
                </div> */}
                <div className="row mx-0 my-3 text-center">
                  <div className="col px-0">
                    <Link to={`/${this.state.siteId}/?page=${constClass.RECEIVESELECT}`}>
                      <button
                        className={`btn btn-enable w-100 h-100 py-2 px-2`}>
                        <h4 className="mb-0 font-weight-bold">お受け取り</h4>
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="row mx-0 my-3 text-center">
                  <div className="col px-0">
                    <Link to={`/${this.state.siteId}/?page=${constClass.USERCHANGE}`}>
                      <button
                        className={`btn btn-enable w-100 h-100 py-2 px-2`}>
                        <h4 className="mb-0 font-weight-bold">お客様情報</h4>
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="row mx-0 my-3 text-center">
                </div>
                {Common.getSettingValue(this.settings, 'SPECIFIED_COMMERCIAL_URL', '') &&
                  <div className="row mx-0 my-1 text-center">
                    <div className="col px-0">
                      <a href={Common.getSettingValue(this.settings, 'SPECIFIED_COMMERCIAL_URL', '')} target="_blank" rel="noreferrer"><u>特定商取引法に関する表記</u></a>
                    </div>
                  </div>
                }
                {Common.getSettingValue(this.settings, 'USER_GUIDE_URL', '') &&
                  <div className="row mx-0 my-3 text-center">
                    <div className="col px-0">
                      <a href={Common.getSettingValue(this.settings, 'USER_GUIDE_URL', '')} target="_blank" rel="noreferrer"><u>ご利用案内</u></a>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // 予約可能商品一覧
  renderPlan() {
    return (
      <div className="row mx-0">
        <div className="col px-0">
          <div>
            <div className="row mx-0 px-3-env pt-2 pb-1">
              <div className="col py-1 px-1 align-self-center">
                <div className="row mx-0 my-3 text-center">
                  <div className="col px-0">
                    {Common.getSettingValue(this.settings, 'RESERVE_DATA_LABEL', '')}
                  </div>
                </div>
                <div className="bg-white border border-info rounded px-3">
                  {this.state.planMaster.map((plan, idx) =>
                    <div key={plan.plan_id} className="row mx-0 my-3">
                      <div className="col-12 px-0 text-left bg-white">
                        受付期間:{plan.plan_start.substr(4, 2)}/{plan.plan_start.substr(6, 2)}～{plan.plan_end.substr(4, 2)}/{plan.plan_end.substr(6, 2)}
                      </div>
                      <div className="col-12 px-0 text-left bg-white">
                        ・　{plan.plan_name}
                      </div>
                      {plan.pdf_url &&
                        <div className="col-12 px-0 text-right bg-white">
                          {Common.getSettingValue(this.settings, 'PAMPHLET_NAME', '予約パンフレット')}は<a href={`/${this.state.siteId}${constClass.PLAN_IMAGE_DIR}${plan.pdf_url}`} target="_blank" rel="noreferrer"><u>こちら</u></a>
                        </div>
                      }
                      <div className="col-12 px-0 text-left bg-white text-danger">
                        {plan.payment_flag === constClass.FLAG.ON && '※事前決済が必要です。'}
                        {plan.payment_flag === constClass.FLAG.BOTH && '※事前決済可能です。'}
                        {plan.delivery_flag === constClass.FLAG.ON && '※配送専用です。'}
                        {plan.delivery_flag === constClass.FLAG.BOTH && '※配送可能です。'}
                      </div>
                    </div>
                  )}
                  {this.state.planMaster.length <= 0 &&
                    <div className="row mx-0 my-3">
                      <div className="col-12 px-0 text-left bg-white">
                        現在ご予約可能な商品はありません。
                      </div>
                    </div>
                  }
                </div>
                <div className="row mx-0 my-3 text-center">
                  <div className="col px-0">
                    画面の表示に従ってご注文ください。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderReceiveShop() {
    return (
      <div className="row mx-0">
        <div className="col px-0">
          <div>
            <div className="row mx-0">
              <div className="col section py-0 line-height-2-2"
              // ref={(el) => {
              //   if (el) {
              //     el.style.setProperty('background-color', chroma(this.state.setting_val).alpha(0.3).css(), 'important');
              //   }
              // }}>
              >
                <span className="d-inline-block align-middle">
                  {Common.getSettingValue(this.settings, 'RECEIPT_PLACE_LABEL', '')}</span>
              </div>
            </div>
          </div>
          <div>
            <div className="row mx-0 bg-white px-3-env pt-2 pb-1">
              <div className="col bg-white py-1 px-1 align-self-center">
                {(this.state.areaMaster && this.state.areaMaster.length > 0) &&
                  <div className="row mx-0 my-3 text-center">
                    <div className="col-6 px-0 bg-white text-center align-self-center">
                      {Common.getSettingValue(this.settings, 'SEARCH_SHOP_NAME', '')}で絞り込む
                    </div>
                    <div className="col-6 px-0 bg-white align-self-center">
                      <select className="custom-select w-100 text-center" aria-label={Common.getSettingValue(this.settings, 'SEARCH_SHOP_NAME', '')} value={this.state.area} onChange={(e) => this.setArea(e.target.value)} onBlur={() => this.scrollWindowTop()}>
                        <option value="">- 選択 -</option>
                        {this.state.areaMaster.map((item) =>
                          <option key={item.area_id} value={item.area_id}>{item.area_name}</option>
                        )}
                      </select>
                    </div>
                  </div>
                }
                <div className="row mx-0 my-3 text-center">
                  <div className="col px-0 bg-white">
                    <select className="custom-select w-100 text-center" aria-label="受取店舗" ref={this.receiveShopRef} value={this.state.receive_shop} onChange={(e) => this.setReceiveShop(e.target.value)} onBlur={() => this.scrollWindowTop()}>
                      <option value="" >- 選択 -</option>
                      {this.state.shopMaster.filter(s => s.shop_id > 0) // 本社を除く
                        .filter(s => !(this.state.area) || s.area_id === this.state.area) // 地区が選ばれていたら絞り込む
                        .filter(s => s.view_flag === constClass.FLAG.ON) // 表示フラグが「1」のみ表示
                        .filter(s => !(this.getDuplicateNgShop(this.state.productMaster).includes(s.shop_id))).map((item) => // 全ての商品で受取不可の店舗を除く
                          <option key={item.shop_id} value={item.shop_id}>{item.shop_name}</option>
                        )}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mx-0 bg-white px-3-env pt-1 pb-2">
              <div className="col">
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // ご注文商品選択
  renderRequest() {
    return (
      <div className="row mx-0">
        <div className="col px-0">
          <ProductList
            site_id={this.state.siteId}
            products={this.state.productMaster.filter((item) => this.getDateString() <= item.order_limit) // 締め切り日フィルタ
              .filter((item) => (this.state.product_category.length <= 0 || this.state.product_category.map(id => id.value).includes(item.product_category_id))) // カテゴリ選択フィルタ
              .filter((item) => this.state.selected_plan.length <= 0 || this.state.selected_plan.map(id => id.value).includes(item.plan_id)) // 企画選択フィルタ
              .filter((item) => !this.state.selected_early_discount || this.checkEarlyDiscount(item))
              .filter((item) => Common.getSettingValue(this.settings, 'NG_SHOP_DISP_FLAG', '1') !== '0' || !item.m_ng_shop.map(s => s.shop_id).includes(this.state.my_customer.receive_shop))
            }
            disabled={this.state.disabled}
            message={this.state.message}
            receive_shop={this.state.my_customer.receive_shop}
            displayCartCount={this.displayCartCount}
            addCart={this.addCart}
            subCart={this.subCart}
            setting={this.settings}
            getDetailNotice={this.getDetailNotice}
            setDetailNotice={this.setDetailNotice}
            message_notice={this.state.message_notice}
          />
        </div>
      </div>
    );
  }

  // お客様情報
  renderUser() {
    return (
      <div className="row mx-0">
        <div className="col px-0">
          <div className="row mx-0 my-3 text-center">
            <div className="col px-0">
              お受け取りの際に必要な情報となりますので<br />
              間違いの無いようご記入ください。
            </div>
          </div>
          <div>
            <div className="row mx-0 px-3-env pt-2 pb-1">
              <div className="col py-1 px-1 align-self-center">
                {!(this.props.page === constClass.USER && this.props.plan_id > 0) &&
                  <React.Fragment>
                    <div className="row mx-0 text-left">
                      <div className="col-4 px-0"><label htmlFor="name">お名前</label></div>
                      <div className="col-8 px-0"><input type="text" className="form-control" id="name" name="name" value={this.state.name || ""} onChange={this.change} onBlur={() => this.scrollWindowTop()} placeholder={Common.getSettingValue(this.settings, 'CUSTOMER_NAME_PLACEHOLDER', '')}></input></div>
                    </div>
                    <div className="row mx-0 text-left form-group">
                      <div className="col px-0 font-weight-normal">{Common.getSettingValue(this.settings, 'CUSTOMER_NAME_NOTICE') || ""}</div>
                    </div>
                    <div className="row mx-0 text-left">
                      <div className="col-4 px-0"><label htmlFor="name">お名前(カナ)</label></div>
                      <div className="col-8 px-0"><input type="text" className="form-control" id="kana" name="kana" value={this.state.kana || ""} onChange={this.change} onBlur={() => this.scrollWindowTop()} placeholder={Common.getSettingValue(this.settings, 'CUSTOMER_KANA_PLACEHOLDER', '')}></input></div>
                    </div>
                    <div className="row mx-0 text-left">
                      <div className="col px-0 font-weight-normal">{Common.getSettingValue(this.settings, 'CUSTOMER_KANA_NOTICE') || ""}</div>
                    </div>
                    <div className="row mx-0 text-left form-group">
                      <div className="col px-0 text-danger font-weight-normal">{this.state.message.kana || ""}</div>
                    </div>
                    <div className="row mx-0 text-left">
                      <div className="col-4 px-0"><label htmlFor="tel">携帯番号</label></div>
                      <div className="col-8 px-0"><input type="text" className="form-control" id="tel" name="tel" value={this.state.tel || ""} onChange={this.change} onBlur={() => this.scrollWindowTop()} placeholder={Common.getSettingValue(this.settings, 'CUSTOMER_TEL_PLACEHOLDER', '')}></input></div>
                    </div>
                    <div className="row mx-0 text-left">
                      <div className="col px-0 font-weight-normal">{Common.getSettingValue(this.settings, 'PHONE_NUMBER_NOTICE') || ""}</div>
                    </div>
                    <div className="row mx-0 text-left form-group">
                      <div className="col px-0 text-danger font-weight-normal">{this.state.message.tel || ""}</div>
                    </div>
                  </React.Fragment>
                }
                {Common.getSettingValue(this.settings, 'DELIVERY_USE_FLAG', '1') !== '0' &&
                  ((this.props.page === constClass.USER && this.state.planMaster.filter(p => this.state.cart.map(c => c.planId).includes(p.plan_id)).reduce((prev, p) => [constClass.FLAG.ON, constClass.FLAG.BOTH].includes(p.delivery_flag) ? true : prev, false)) || //注文時ユーザ登録
                    (this.props.page === constClass.ORDERUSERCHANGE && this.state.my_order.find(o => o.order_id === this.props.current_order).delivery_flag === constClass.FLAG.ON) || // 注文情報変更
                    (this.props.page === constClass.USERCHANGE)) && // お客様情報変更
                  <div>
                    {!(this.props.page === constClass.USER && this.props.plan_id > 0) &&
                      <React.Fragment>
                        <div className="row mx-0 text-center form-group">
                          <div className="col px-0">配送をご利用の場合はご記入ください</div>
                        </div>
                        <div className="row mx-0 text-left">
                          <div className="col-4 px-0"><label htmlFor="postal_code">郵便番号</label></div>
                          <div className="col-5 px-0"><input type="text" className="form-control" id="postal_code" name="postal_code" value={this.state.postal_code || ""} onChange={this.change} onBlur={() => this.scrollWindowTop()} placeholder={Common.getSettingValue(this.settings, 'CUSTOMER_POSTAL_CODE_PLACEHOLDER', '')}></input></div>
                          <div className="col-3 px-1">
                            <button
                              onClick={() => this.searchAddress(this.state.postal_code, '')}
                              className={`btn btn-enable w-90 py-1 px-1`}>
                              住所検索
                            </button>
                          </div>
                        </div>
                        <div className="row mx-0 text-left">
                          <div className="col px-0 font-weight-normal">{Common.getSettingValue(this.settings, 'POSTAL_CODE_NOTICE') || ""}</div>
                        </div>
                        <div className="row mx-0 text-left form-group">
                          <div className="col px-0 text-danger font-weight-normal">{this.state.message.postal_code || ""}</div>
                        </div>
                        <div className="row mx-0 text-left">
                          <div className="col-4 px-0"><label htmlFor="address">住所</label></div>
                          <div className="col-8 px-0"><input type="text" className="form-control" id="address" name="address" value={this.state.address || ""} onChange={this.change} onBlur={() => this.scrollWindowTop()} placeholder={Common.getSettingValue(this.settings, 'CUSTOMER_ADDRESS_PLACEHOLDER', '')}></input></div>
                        </div>
                        <div className="row mx-0 text-left form-group">
                          <div className="col px-0 font-weight-normal">{Common.getSettingValue(this.settings, 'ADDRESS_NOTICE') || ""}</div>
                        </div>
                      </React.Fragment>
                    }
                    {(this.props.page !== constClass.USERCHANGE && (isNaN(this.props.plan_id) || this.props.plan_id > 0)) &&
                      <React.Fragment>
                        {!(this.props.page === constClass.USER && this.props.plan_id > 0) &&
                          <div className="row mx-0 text-left form-group">
                            <div className="col-auto px-0 align-self-center"><label htmlFor="delivery_check" className="mb-0">配送先は上記と同じ</label></div>
                            <div className="col-2"><input type="checkbox" className="form-control" id="delivery_check" name="delivery_check" value="true" checked={this.state.delivery_check} onChange={this.change}></input></div>
                            <div className="col"></div>
                          </div>
                        }
                        <div className="row mx-0 text-left">
                          <div className="col-4 px-0"><label htmlFor="name">配送先お名前</label></div>
                          <div className="col-8 px-0"><input type="text" className="form-control" id="delivery_name" name="delivery_name" value={this.state.delivery_name || ""} disabled={this.state.delivery_check} onChange={this.change} onBlur={() => this.scrollWindowTop()} placeholder={Common.getSettingValue(this.settings, 'DELIVERY_NAME_PLACEHOLDER', '')}></input></div>
                        </div>
                        <div className="row mx-0 text-left form-group">
                          <div className="col px-0 font-weight-normal">{Common.getSettingValue(this.settings, 'DELIVERY_NAME_NOTICE') || ""}</div>
                        </div>
                        <div className="row mx-0 text-left">
                          <div className="col-4 px-0"><label htmlFor="name">配送先お名前(カナ)</label></div>
                          <div className="col-8 px-0"><input type="text" className="form-control" id="delivery_kana" name="delivery_kana" value={this.state.delivery_kana || ""} disabled={this.state.delivery_check} onChange={this.change} onBlur={() => this.scrollWindowTop()} placeholder={Common.getSettingValue(this.settings, 'DELIVERY_KANA_PLACEHOLDER', '')}></input></div>
                        </div>
                        <div className="row mx-0 text-left">
                          <div className="col px-0 font-weight-normal">{Common.getSettingValue(this.settings, 'DELIVERY_KANA_NOTICE') || ""}</div>
                        </div>
                        <div className="row mx-0 text-left form-group">
                          <div className="col px-0 text-danger font-weight-normal">{this.state.message.delivery_kana || ""}</div>
                        </div>
                        <div className="row mx-0 text-left">
                          <div className="col-4 px-0"><label htmlFor="tel">配送先携帯番号</label></div>
                          <div className="col-8 px-0"><input type="text" className="form-control" id="delivery_tel" name="delivery_tel" value={this.state.delivery_tel || ""} disabled={this.state.delivery_check} onChange={this.change} onBlur={() => this.scrollWindowTop()} placeholder={Common.getSettingValue(this.settings, 'DELIVERY_TEL_PLACEHOLDER', '')}></input></div>
                        </div>
                        <div className="row mx-0 text-left">
                          <div className="col px-0 font-weight-normal">{Common.getSettingValue(this.settings, 'DELIVERY_TEL_NOTICE') || ""}</div>
                        </div>
                        <div className="row mx-0 text-left form-group">
                          <div className="col px-0 text-danger font-weight-normal">{this.state.message.delivery_tel}</div>
                        </div>
                        <div className="row mx-0 text-left">
                          <div className="col-4 px-0"><label htmlFor="postal_code">配送先郵便番号</label></div>
                          <div className="col-5 px-0"><input type="text" className="form-control" id="delivery_postal_code" name="delivery_postal_code" value={this.state.delivery_postal_code || ""} disabled={this.state.delivery_check} onChange={this.change} onBlur={() => this.scrollWindowTop()} placeholder={Common.getSettingValue(this.settings, 'DELIVERY_POSTAL_CODE_PLACEHOLDER', '')}></input></div>
                          <div className="col-3 px-1">
                            <button
                              onClick={() => this.searchAddress(this.state.delivery_postal_code, 'delivery_')}
                              className={`btn btn-enable w-90 py-1 px-1`}>
                              住所検索
                            </button>
                          </div>
                        </div>
                        <div className="row mx-0 text-left">
                          <div className="col px-0 font-weight-normal">{Common.getSettingValue(this.settings, 'DELIVERY_POSTAL_CODE_NOTICE') || ""}</div>
                        </div>
                        <div className="row mx-0 text-left form-group">
                          <div className="col px-0 text-danger font-weight-normal">{this.state.message.delivery_postal_code || ""}</div>
                        </div>
                        <div className="row mx-0 text-left">
                          <div className="col-4 px-0"><label htmlFor="address">配送先住所</label></div>
                          <div className="col-8 px-0"><input type="text" className="form-control" id="delivery_address" name="delivery_address" value={this.state.delivery_address || ""} disabled={this.state.delivery_check} onChange={this.change} onBlur={() => this.scrollWindowTop()} placeholder={Common.getSettingValue(this.settings, 'DELIVERY_ADDRESS_PLACEHOLDER', '')}></input></div>
                        </div>
                        <div className="row mx-0 text-left form-group">
                          <div className="col px-0 font-weight-normal">{Common.getSettingValue(this.settings, 'DELIVERY_ADDRESS_NOTICE') || ""}</div>
                        </div>
                      </React.Fragment>
                    }
                  </div>
                }
                <div className="row mx-0 my-1 text-left">
                  <div className="col px-0 font-weight-normal">
                    {Common.getSettingValue(this.settings, 'POSSIBILITY_CANCEL_STRING', '')}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mx-0 px-3-env pt-2 pb-1 line-height-2-2">
              <div className="col-2">
                <input type="checkbox" className="form-control" id="privacypolicy" name="privacypolicy" value="true" checked={this.state.privacypolicy} onChange={this.change}></input>
              </div>
              <div className="col-10">
                <label htmlFor="privacypolicy" >{Common.getSettingValue(this.settings, 'PRIVACYPOLICY_URL', '') ? <a href={Common.getSettingValue(this.settings, 'PRIVACYPOLICY_URL', '')} target="_blank" rel="noreferrer"><u>プライバシーポリシー</u></a> : 'プライバシーポリシー'}に同意する</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // ご注文確認
  renderConfirm() {
    return (
      <div className="row mx-0">
        <div className="col px-0">
          <div className="">
            <div className="row mx-0 px-3-env pt-2 pb-1">
              <div className="col-10 bg-white py-2 px-1 align-self-center border-textbox rounded">
                <div className="row mx-0 text-left">
                  <div className="col-4 px-0 bg-white">お名前</div>
                  <div className="col-8 px-0 bg-white">{this.state.my_customer.customer_name}</div>
                </div>
                <div className="row mx-0 text-left">
                  <div className="col-4 px-0 bg-white">お名前(カナ)</div>
                  <div className="col-8 px-0 bg-white">{this.state.my_customer.customer_kana}</div>
                </div>
                <div className="row mx-0 text-left">
                  <div className="col-4 px-0 bg-white">携帯番号</div>
                  <div className="col-8 px-0 bg-white">{this.state.my_customer.customer_tel}</div>
                </div>
                {this.state.planMaster.filter(p => this.state.cart.map(c => c.planId).includes(p.plan_id)).reduce((prev, p) => [constClass.FLAG.ON, constClass.FLAG.BOTH].includes(p.delivery_flag) ? true : prev, false) &&
                  <div>
                    <div className="row mx-0 text-left">
                      <div className="col-4 px-0 bg-white">郵便番号</div>
                      <div className="col-8 px-0 bg-white">{this.state.my_customer.customer_postal_code}</div>
                    </div>
                    <div className="row mx-0 text-left">
                      <div className="col-4 px-0 bg-white">住所</div>
                      <div className="col-8 px-0 bg-white">{this.state.my_customer.customer_address}</div>
                    </div>
                  </div>
                }
              </div>
              <div className="col-2 py-1 px-1 align-self-center text-nowrap">
                <button
                  className={`btn btn-enable w-90 py-2 px-2`}
                  onClick={() => this.changeCustomer()}>
                  変更
                </button>
              </div>
            </div>
            {/* <div className="row mx-0 px-3-env pt-1 pb-1">
              <div className="col-10 bg-white py-2 px-1 align-self-center border-textbox rounded">
                <div className="row mx-0 text-left">
                  <div className="col-4 px-0 bg-white">受取店舗</div>
                  <div className="col-8 px-0 bg-white">{this.state.shopMaster.find(s => s.shop_id === this.state.my_customer.receive_shop).shop_name}</div>
                </div>
              </div>
              <div className="col-2 bg-white py-1 px-1 align-self-center text-nowrap">
                <Link to={`/${this.state.siteId}/?page=${constClass.PLAN}`} >
                  <button
                    className={`btn btn-enable w-90 py-2 px-2`}>
                    変更
                  </button>
                </Link>
              </div>
            </div> */}
            {(this.settings['ORDER_ADDRESS_NOTICE']) &&
              <div className="row mx-0 px-3-env pt-1 pb-1">
                <div className="col px-0">
                  {Common.getBrString(this.settings['ORDER_ADDRESS_NOTICE'])}
                </div>
              </div>
            }
            <div className="row mx-0 px-3-env pt-1 pb-1">
              <div className="col-12 text-center">
                ご注文内容
              </div>
            </div>
            {this.state.cart.filter(c => c.payment_flag === constClass.FLAG.ON).length > 1 &&
              <div className="row mx-0 px-1-env pt-1 pb-1">
                <div className="col-12 text-center text-danger">
                  複数回のお支払いが必要となります。<br />
                  お支払い完了後、続けてお支払いをお願いします。
                </div>
              </div>
            }
            {this.state.cart.map(c => c.planId).filter((elem, index, self) => self.indexOf(elem) === index).map((plan) =>
              <div key={plan} className="mb-2 p-2 bg-white border border-info rounded mx-2">
                <div className="row mx-0 px-1-env">
                  <div className="col py-1 px-1 align-self-center border-bottom">
                    {/* <div className="row mx-0 text-left">
                      <div className="col px-0">◇{this.state.planMaster.find(p => p.plan_id === plan).plan_name}</div>
                    </div> */}
                    {this.state.planMaster.find(p => p.plan_id === plan).payment_flag === constClass.FLAG.BOTH &&
                      <div>
                        <div className="row mx-0 px-2 mt-2">
                          <div className="col">
                            <p className="mb-0 text-danger">お支払い方法を選択してください</p>
                          </div>
                        </div>
                        <div className="row mx-0 px-2 mb-2">
                          <div className="col-2 align-self-center">
                            <input type="radio" className="form-control" id="payment_off" name="payment_flag" onChange={() => this.setCartFlag(plan, "payment_flag", constClass.FLAG.OFF)} value={constClass.FLAG.OFF} checked={this.state.cart.find(c => c.planId === plan).payment_flag === constClass.FLAG.OFF} />
                          </div>
                          <div className="col-4 align-self-center px-0">
                            <label htmlFor="payment_off" className="mb-0">受取時支払</label>
                          </div>
                          <div className="col-2 align-self-center">
                            <input type="radio" className="form-control" id="payment_on" name="payment_flag" onChange={() => this.setCartFlag(plan, "payment_flag", constClass.FLAG.ON)} value={constClass.FLAG.ON} checked={this.state.cart.find(c => c.planId === plan).payment_flag === constClass.FLAG.ON} />
                          </div>
                          <div className="col-4 align-self-center px-0">
                            <label htmlFor="payment_on" className="mb-0">事前支払</label>
                          </div>
                        </div>
                      </div>
                    }
                    {this.state.planMaster.find(p => p.plan_id === plan).delivery_flag === constClass.FLAG.BOTH &&
                      <div>
                        <div className="row mx-0 px-2 mt-2">
                          <div className="col">
                            <p className="mb-0 text-danger">受取方法を選択してください</p>
                          </div>
                        </div>
                        <div className="row mx-0 px-2 mb-2">
                          <div className="col-2 align-self-center">
                            <input type="radio" className="form-control" id="delivery_off" name="delivery_flag" onChange={() => this.setCartFlag(plan, "delivery_flag", constClass.FLAG.OFF)} value={constClass.FLAG.OFF} checked={this.state.cart.find(c => c.planId === plan).delivery_flag === constClass.FLAG.OFF} />
                          </div>
                          <div className="col-4 align-self-center px-0">
                            <label htmlFor="delivery_off" className="mb-0">店舗受取</label>
                          </div>
                          <div className="col-2 align-self-center">
                            <input type="radio" className="form-control" id="delivery_on" name="delivery_flag" onChange={() => this.setCartFlag(plan, "delivery_flag", constClass.FLAG.ON)} value={constClass.FLAG.ON} checked={this.state.cart.find(c => c.planId === plan).delivery_flag === constClass.FLAG.ON} />
                          </div>
                          <div className="col-4 align-self-center px-0">
                            <label htmlFor="delivery_on" className="mb-0">配送</label>
                          </div>
                        </div>
                      </div>
                    }
                    {(this.state.planMaster.find(p => p.plan_id === plan).payment_flag === constClass.FLAG.ON && this.getOrderTotal(this.state.cart.find(c => c.planId === plan)).total > 0) &&
                      <div className="row mx-0 px-2 mt-2 mb-2">
                        <div className="col">
                          <p className="mb-0 text-danger">お支払い完了後、注文が確定されます</p>
                        </div>
                      </div>
                    }
                    <div className="row mx-0 px-2 mt-2 mb-2">
                      <div className="col">
                        {/* <div className="row pb-2">
                          <div className="col-5 align-self-center">
                            受取日
                          </div>
                          <div className="col-7 order-datepicker">
                            {this.state.planMaster.find(p => p.plan_id.toString() === plan.toString()).year_round_type === "1" &&
                              <DatePicker className="custom-select w-100 text-center border-textbox text-primary font-weight-bold" aria_labal="受取日" locale="ja" dateFormat="yyyy/MM/dd" monthsShown="1" id="receiveDate" name="receiveDate"
                                popperPlacement='bottom'
                                popperModifiers={{
                                  flip: {
                                    behavior: ["bottom"] // don't allow it to flip to be above
                                  },
                                }}
                                selected={this.state.cart.find(c => c.planId === plan).receiveDate ? new Date(this.state.cart.find(c => c.planId === plan).receiveDate.replace(/-/g, '/')) : null}
                                minDate={new Date(moment().add(parseInt(this.state.productMaster.filter(p => this.state.cart.find(c => c.planId === plan).detail.map(d => d.productId).includes(p.product_id)).sort((a, b) => parseInt(b.receipt_limit_days) - parseInt(a.receipt_limit_days))[0].receipt_limit_days), 'days').format('YYYY/MM/DD'))}
                                onChange={date => this.setReceiveDate(plan, date ? moment(new Date(date)).format('YYYYMMDD') : null)}
                                onBlur={() => this.scrollWindowTop()}
                                placeholderText="- 選択 -"
                                customInput={<button>{this.state.cart.find(c => c.planId === plan).receiveDate ? this.state.cart.find(c => c.planId === plan).receiveDate.replace(/-/g, '/') : "- 選択 -"}</button>}
                              />
                            }
                            {this.state.planMaster.find(p => p.plan_id.toString() === plan.toString()).year_round_type !== "1" &&
                              <select className="custom-select w-100 text-center border-textbox text-primary font-weight-bold" aria-label="受取日"
                                value={this.state.cart.find(c => c.planId === plan).receiveDate ? this.state.cart.find(c => c.planId === plan).receiveDate.replace(/-/g, '') : ''}
                                onChange={(e) => this.setReceiveDate(plan, e.target.value)} onBlur={() => this.scrollWindowTop()}>
                                <option value="">- 選択 -</option>
                                {this.getDuplicateReceiveDate(this.state.productMaster.filter(p => this.state.cart.find(c => c.planId === plan).detail.map(d => d.productId).includes(p.product_id))) //productMasterをcartに含まれるものでフィルタして関数に渡す
                                  .map(item =>
                                    <option key={item} value={item}>{item.substr(0, 4) + '/' + item.substr(4, 2) + '/' + item.substr(6, 2)}</option>
                                  )}
                              </select>
                            }
                          </div>
                        </div> */}
                        {/* {
                          this.state.cart.find(c => c.planId === plan).delivery_flag === constClass.FLAG.OFF &&
                          <React.Fragment>
                            <div className="row pb-2">
                              <div className="col-5 align-self-center">
                                受取時間帯
                              </div>
                              <div className="col-7">
                                <select className="custom-select w-100 text-center border-textbox text-primary font-weight-bold" aria-label="受取時間帯" value={this.state.cart.find(c => c.planId === plan).receiveTime ? this.state.cart.find(c => c.planId === plan).receiveTime : ''} onChange={(e) => this.setReceiveTime(plan, e.target.value)} onBlur={() => this.scrollWindowTop()}>
                                  <option value="">- 選択 -</option>
                                  {this.getDuplicateReceiveNgTime(
                                    this.state.productMaster.filter(p => this.state.cart.find(c => c.planId === plan).detail.map(d => d.productId).includes(p.product_id))
                                    , this.state.timeMaster
                                  ).map(item =>
                                    <option key={item.time_id} value={item.time_id}>{item.time_name}</option>
                                  )}
                                </select>
                              </div>
                            </div>
                            {this.getDuplicateReceiveNgTime(
                              this.state.productMaster.filter(p => this.state.cart.find(c => c.planId === plan).detail.map(d => d.productId).includes(p.product_id))
                              , this.state.timeMaster
                            ).length <= 0 &&
                              <div className="row pb-2">
                                <div className="col">
                                  <p className="mb-0 text-danger">{Common.getSettingValue(this.settings, 'RECEIVE_TIME_NOT_FOUND', 'まとめてお受け取りできる時間帯がありません。商品を分けてご注文ください。')}</p>
                                </div>
                              </div>
                            }
                          </React.Fragment>
                        }
                        {this.state.cart.find(c => c.planId === plan).delivery_flag === constClass.FLAG.ON &&
                          <div className="row pb-2">
                            <div className="col-3 align-self-center">
                              配送先
                            </div>
                            <div className="col-7 px-0 align-self-center">
                              <small>{this.state.cart.find(c => c.planId === plan).delivery_kana}<br /></small>
                              {this.state.cart.find(c => c.planId === plan).delivery_name}<br />
                              &#x2121;{this.state.cart.find(c => c.planId === plan).delivery_tel}<br />
                              &#x3012;{this.state.cart.find(c => c.planId === plan).delivery_postal_code}<br />
                              {this.state.cart.find(c => c.planId === plan).delivery_address}
                            </div>
                            <div className="col-2 align-self-center text-nowrap">
                              <Link to={`/${this.state.siteId}/?page=${constClass.USER}&plan_id=${plan}`} >
                                <button
                                  className={`btn btn-enable w-90 py-2 px-2`}>
                                  変更
                                </button>
                              </Link>
                            </div>
                          </div>
                        } */}
                        {!!Common.getDateObject(this.state.cart.find(c => c.planId === plan).detail.reduce((prev, d) => prev < d.shipping_date ? d.shipping_date : prev, '00000000')).str &&
                          <div className="row pb-2">
                            <div className="col">
                              <p className="mb-0">{Common.getSettingValue(this.settings, 'ORDER_SHIPPING_NOTICE_PREFIX', '')}{Common.getDateObject(this.state.cart.find(c => c.planId === plan).detail.reduce((prev, d) => prev < d.shipping_date ? d.shipping_date : prev, '00000000')).str}{Common.getSettingValue(this.settings, 'ORDER_SHIPPING_NOTICE_SUFFIX', '')}</p>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                    {
                      this.state.cart.find(c => c.planId === plan).detail.map((item) =>
                        <div key={item.productId} className="m-2 mb-1">
                          <div className="row mx-0 text-left border-top pt-1">
                            <div className="col px-0 text-info">{this.state.productMaster.find(p => p.product_id === item.productId).product_name}</div>
                          </div>
                          {!!Common.getDateObject(this.state.productMaster.find(p => p.product_id === item.productId).shipping_date).str &&
                            <div className="row mx-0 text-left">
                              <div className="col px-2">発送予定日：{Common.getDateObject(this.state.productMaster.find(p => p.product_id === item.productId).shipping_date).str}</div>
                            </div>
                          }
                          {this.state.productMaster.find(p => p.product_id === item.productId).notice_flag === constClass.FLAG.ON &&
                            <React.Fragment>
                              <div className="row mx-0 text-left">
                                <div className="col px-2">備考：{item.notice}</div>
                              </div>
                            </React.Fragment>
                          }
                          <div className="row mx-0 text-right  align-self-center">
                            <div className="col-5 px-0 align-self-center">
                              &yen;{Common.getSettingValue(this.settings, 'TAX_INCLUDED_FLAG', '0') === '1' ? Math.floor(item.price).toLocaleString() + '(税込)' : Math.floor(item.price_before_tax).toLocaleString()}<br />
                              {Common.getSettingValue(this.settings, 'TAX_INCLUDED_FLAG', '0') !== '1' && <small>&yen;{item.price.toLocaleString()}(税込)</small>}
                            </div>
                            <div className="col-7">
                              <div className="row">
                                <div className="col-auto text-center p-1 ml-1 align-self-center">
                                  <button
                                    disabled={((item.year_round_type !== "1" || item.stock > 0) && (item.stock - item.order_count) <= 0) || this.state.disabled}
                                    className={`btn btn-digitalcard w-100 h-100 py-1 px-2`}
                                    onClick={() => { this.subCart(item.productId) }}>
                                    －
                                  </button>
                                </div>
                                <div className="col text-center py-1 px-1 align-self-center border-textbox">
                                  <div className="bg-white">{item.count}</div>
                                </div>
                                <div className="col-auto text-center p-1 mr-1 align-self-center">
                                  <Popover
                                    isOpen={this.state.message[item.productId] !== undefined}
                                    place='below'
                                    body={<div className="section-white border text-danger px-3">{this.state.message[item.productId]}</div>}>
                                    <button
                                      disabled={((item.year_round_type !== "1" || item.stock > 0) && (item.stock - item.order_count) <= 0) || this.state.disabled}
                                      className={`btn btn-digitalcard w-100 h-100 py-1 px-2`}
                                      onClick={() => { this.addCart(item.productId) }}>
                                      ＋
                                    </button>
                                  </Popover>
                                </div>
                                <div className="col-auto align-self-center pl-1 pr-0">個</div>
                              </div>
                            </div>
                          </div>
                          <div className="row mx-0 text-right  align-self-center">
                            <div className="col px-0 text-danger">{/*this.state.message[item.productId]*/}</div>
                            <div className="col-auto px-0">
                              <button
                                className={`btn btn-enable w-100 py-0 px-2`}
                                onClick={() => { this.deleteCart(item.productId) }}>
                                削除
                              </button>
                            </div>
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>
                <div className="row mx-0 px-1-env pt-1 pb-2">
                  <div className="col">
                    <div className="row">
                      <div className="col-6 text-right">
                        小計
                      </div>
                      <div className="col-6 text-right">
                        &yen;{(this.getOrderTotal(this.state.cart.find(c => c.planId === plan)).subtotal).toLocaleString()}
                      </div>
                    </div>
                    {this.getOrderTotal(this.state.cart.find(c => c.planId === plan)).discount > 0 &&
                      <div className="row">
                        <div className="col-6 text-right">
                          早期割引
                        </div>
                        <div className="col-6 text-right">
                          &yen;{(this.getOrderTotal(this.state.cart.find(c => c.planId === plan)).discount * -1).toLocaleString()}
                        </div>
                      </div>
                    }
                    <div className="row">
                      <div className="col-6 text-right">
                        {Common.getSettingValue(this.settings, 'TAX_INCLUDED_FLAG', '0') === '1' ? '(内税額' : '税額'}
                      </div>
                      <div className="col-6 text-right">
                        &yen;{(this.getOrderTotal(this.state.cart.find(c => c.planId === plan)).tax).toLocaleString()}{Common.getSettingValue(this.settings, 'TAX_INCLUDED_FLAG', '0') === '1' ? ')' : ''}
                      </div>
                    </div>
                    {this.getOrderTotal(this.state.cart.find(c => c.planId === plan)).delivery_price > 0 &&
                      <div className="row">
                        <div className="col-6 text-right">
                          送料
                        </div>
                        <div className="col-6 text-right">
                          &yen;{this.getOrderTotal(this.state.cart.find(c => c.planId === plan)).delivery_price.toLocaleString()}
                        </div>
                      </div>
                    }
                    {/* {Common.getSettingValue(this.settings, 'TAX_INCLUDED_FLAG', '0') !== '1' && this.getOrderTotal(this.state.cart.find(c => c.planId === plan)).discount > 0 &&
                      <div className="row">
                        <div className="col-6 text-right">
                          税込金額
                        </div>
                        <div className="col-6 text-right">
                          &yen;{(this.getOrderTotal(this.state.cart.find(c => c.planId === plan)).subtotal + this.getOrderTotal(this.state.cart.find(c => c.planId === plan)).tax).toLocaleString()}
                        </div>
                      </div>
                    } */}
                    <div className="row">
                      <div className="col-6 text-right">
                        合計
                      </div>
                      <div className="col-6 text-right">
                        &yen;{(this.getOrderTotal(this.state.cart.find(c => c.planId === plan)).total).toLocaleString()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  // ご注文完了
  renderThanks() {
    return (
      <div className="row mx-0">
        <div className="col px-0">
          <div>
            <div className="row mx-0 px-3-env pt-2 pb-1">
              <div className="col bg-lightblue py-1 px-1 align-self-center border-textbox rounded">
                <div className="row mx-0 text-center">
                  <div className="col px-0"><h4>{Common.getSettingValue(this.settings, 'COMPLETION_ORDER_TITLE', '')}</h4></div>
                </div>
                {this.props.page === constClass.ORDERFINISH &&
                  <div className="row mx-0 text-center">
                    <div className="col px-0"><h4>{Common.getSettingValue(this.settings, 'COMPLETION_ORDER_SUBTITLE', '')}</h4></div>
                  </div>
                }
                {this.props.page === constClass.CHANGEFINISH &&
                  <div className="row mx-0 text-center">
                    <div className="col px-0"><h4>{Common.getSettingValue(this.settings, 'COMPLETION_CHANGE_SUBTITLE', '')}</h4></div>
                  </div>
                }
                {this.props.page === constClass.RECEIVEFINISH &&
                  <div className="row mx-0 text-center">
                    <div className="col-12 px-0"><h4>{Common.getBrString(Common.getSettingValue(this.settings, 'COMPLETION_RECEIPT_SUBTITLE', ''))}</h4></div>
                  </div>
                }
                {this.props.page === constClass.MEMBERFINISH &&
                  <div className="row mx-0 text-center">
                    <div className="col px-0"><h4>ご登録ありがとうございました。</h4></div>
                  </div>
                }
                {(this.props.page === constClass.ORDERFINISH || this.props.page === constClass.CHANGEFINISH) &&
                  <div className="row mx-0 text-center">
                    <div className="col px-0">注文番号 {this.state.order_finish && this.state.order_finish.map((item) => "No." + item.receipt_num)}{(!this.state.order_finish && this.props.current_order) && 'No.' + this.state.my_order.find(o => o.order_id === this.props.current_order).receipt_num}</div>
                  </div>
                }
                <div className="row mx-0 text-center">
                  <div className="col px-0"><img src={`/${this.state.siteId}/${Common.getSettingValue(this.settings, 'COMPLETION_ORDER_IMAGE', 'thanks.png')}`} alt="ありがとうございました。" /></div>
                </div>
              </div>
            </div>
            {(this.props.page === constClass.ORDERFINISH || this.props.page === constClass.CHANGEFINISH) &&
              <div>
                {this.state.my_order.find(o => o.order_id === this.props.current_order) &&
                  <div className="row mx-0 px-3-env pt-1 pb-2">
                    {this.state.my_order.find(o => o.order_id === this.props.current_order).delivery_flag === constClass.FLAG.ON
                      ? Common.getSettingValue(this.settings, 'COMPLETION_ORDER_INFOMATION_DELIVERY', '') // 配送フラグオン
                      : Common.getSettingValue(this.settings, 'COMPLETION_ORDER_INFOMATION', '')
                    }
                  </div>
                }
                {(!this.state.my_order.find(o => o.order_id === this.props.current_order) && (this.state.order_finish && this.state.order_finish.reduce((prev, item) => prev || item.delivery_flag !== constClass.FLAG.ON, false))) &&
                  <div className="row mx-0 px-3-env pt-1 pb-2">
                    {Common.getSettingValue(this.settings, 'COMPLETION_ORDER_INFOMATION', '')}
                  </div>
                }
                {(!this.state.my_order.find(o => o.order_id === this.props.current_order) && (this.state.order_finish && this.state.order_finish.reduce((prev, item) => prev || item.delivery_flag === constClass.FLAG.ON, false))) &&
                  <div className="row mx-0 px-3-env pt-1 pb-2">
                    {Common.getSettingValue(this.settings, 'COMPLETION_ORDER_INFOMATION_DELIVERY', '')}
                  </div>
                }
                {/* {(!this.state.order_finish && !isNaN(this.props.current_order)) &&
                  <div>
                    <div className="row mx-0">
                      <div className="col section py-0 line-height-2-2">
                        <span className="d-inline-block align-middle">クレジットカード情報を登録する場合はこちら</span>
                      </div>
                    </div>
                    <div className="row mx-0 px-3-env pt-1 pb-2">
                      <div className="col">
                        <button
                          className={`btn btn-enable w-100 h-100 py-2 px-2`}
                          onClick={() => this.submitCardEdit()}>
                          <h4 className="mb-0 font-weight-bold">登録</h4>
                        </button>
                      </div>
                    </div>
                  </div>
                } */}
                {this.state.my_order.find(o => o.status === constClass.ORDER_STATUS.UPAY) &&
                  <div>
                    <div className="row mx-0">
                      <div className="col py-0 text-center text-danger">
                        <span className="d-inline-block align-middle">未決済のご注文がございますので続けてお支払いをお願いします。</span>
                      </div>
                    </div>
                    {this.state.my_order.filter(o => ([constClass.ORDER_STATUS.UPAY].includes(o.status))).length > 0 && // 履歴の場合に未決済があれば当セクションを表示
                      <div className="row mx-0 px-3-env">
                        <div className="col py-0 px-1 align-self-center">
                          {this.state.my_order.filter(o => ([constClass.ORDER_STATUS.UPAY].includes(o.status))) //未決済のみ
                            .sort((a, b) => ((a.plan_id === b.plan_id) ? b.order_id - a.order_id : b.plan_id - a.plan_id)).map((order) =>
                              this.renderOrderSelectdetail(order)
                            )}
                        </div>
                      </div>
                    }
                    {/* <div className="row mx-0 px-3-env pt-1 pb-2">
                      <div className="col">
                        <Link to={`/?page=${constClass.HISTORY}`}>
                          <button
                            className={`btn btn-submit-alert w-100 h-100 py-2 px-2`}>
                            <h4 className="mb-0 font-weight-bold">ご注文履歴・変更</h4>
                          </button>
                        </Link>
                      </div>
                    </div> */}
                  </div>
                }
                {!this.state.my_order.find(o => o.status === constClass.ORDER_STATUS.UPAY) &&
                  <div>
                    <div className="row mx-0">
                      <div className="col section py-0 line-height-2-2"
                      // ref={(el) => { // テーマカラー
                      //   if (el) {
                      //     el.style.setProperty('background-color', chroma(this.state.setting_val).alpha(0.3).css(), 'important');
                      //   }
                      // }}>
                      >
                        <span className="d-inline-block align-middle">ご注文履歴のご確認はこちら</span>
                      </div>
                    </div>
                    <div className="row mx-0 px-3-env pt-1 pb-2">
                      <div className="col">
                        <Link to={`/${this.state.siteId}/?page=${constClass.HISTORY}`}>
                          <button
                            className={`btn btn-enable w-100 h-100 py-2 px-2`}>
                            <h4 className="mb-0 font-weight-bold">ご注文履歴</h4>
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                }
              </div>
            }
            {(this.props.page === constClass.RECEIVEFINISH) &&
              <div>
                {Common.getSettingValue(this.settings, 'ADVERTISEMENT_INFORMATION_URL', '') !== '' &&
                  <div className="row mx-0 px-3-env pt-1 pb-2">
                    <div className="col text-center">
                      <a href={`${Common.getSettingValue(this.settings, 'ADVERTISEMENT_INFORMATION_URL', '')}?openExternalBrowser=1`} target="_blank" rel="noreferrer">
                        <button
                          disabled={this.state.disabled}
                          className={`btn-lg btn-alert w-100 py-3 p-env-bottom`}>
                          <span>{Common.getSettingValue(this.settings, 'ADVERTISEMENT_INFORMATION_MESSAGE', '')}</span>
                        </button>
                      </a>
                    </div>
                  </div>
                }
                <div>
                  <div className="row mx-0">
                    <div className="col section py-0 line-height-2-2"
                    // ref={(el) => { // テーマカラー
                    //   if (el) {
                    //     el.style.setProperty('background-color', chroma(this.state.setting_val).alpha(0.3).css(), 'important');
                    //   }
                    // }}>
                    >
                      <span className="d-inline-block align-middle">
                        その他の商品をお受け取りの際はこちら</span>
                    </div>
                  </div>
                </div>
                <div className="row mx-0 px-3-env pt-1 pb-2">
                  <div className="col">
                    <Link to={`/${this.state.siteId}/?page=${constClass.RECEIVESELECT}`}>
                      <button
                        className={`btn btn-enable w-100 h-100 py-2 px-2`}>
                        <h4 className="mb-0 font-weight-bold">お受け取り</h4>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }

  // ご注文変更
  renderChange() {
    return (
      <div className="row mx-0">
        <div className="col px-0">
          <div>
            <div className="row mx-0 px-3-env pt-2 pb-1">
              <div className="col-10 py-2 px-1 bg-white align-self-center border-textbox rounded">
                <div className="row mx-0 text-left">
                  <div className="col-4 px-0">お名前</div>
                  <div className="col-8 px-0">{this.state.my_order.find(o => o.order_id === this.props.current_order).customer_name}</div>
                </div>
                <div className="row mx-0 text-left">
                  <div className="col-4 px-0">お名前(カナ)</div>
                  <div className="col-8 px-0">{this.state.my_order.find(o => o.order_id === this.props.current_order).customer_kana}</div>
                </div>
                <div className="row mx-0 text-left">
                  <div className="col-4 px-0">携帯番号</div>
                  <div className="col-8 px-0">{this.state.my_order.find(o => o.order_id === this.props.current_order).customer_tel}</div>
                </div>
                {this.state.my_order.find(o => o.order_id === this.props.current_order).delivery_flag === constClass.FLAG.ON &&
                  <div>
                    <div className="row mx-0 text-left">
                      <div className="col-4 px-0 bg-white">郵便番号</div>
                      <div className="col-8 px-0 bg-white">{this.state.my_order.find(o => o.order_id === this.props.current_order).customer_postal_code}</div>
                    </div>
                    <div className="row mx-0 text-left">
                      <div className="col-4 px-0 bg-white">配送先住所</div>
                      <div className="col-8 px-0 bg-white">{this.state.my_order.find(o => o.order_id === this.props.current_order).customer_address}</div>
                    </div>
                  </div>
                }
              </div>
              <div className="col-2 py-1 px-1 align-self-center">
                <button
                  className={`btn btn-enable w-100 py-2 px-2`}
                  onClick={() => this.moveOrderCustomer()}>
                  変更
                </button>
              </div>
            </div>
            {this.state.my_order.find(o => o.order_id === this.props.current_order).status === constClass.ORDER_STATUS.UPAY &&
              <div>
                <div className="row mx-0 pt-1">
                  <div className="col text-center">
                    <p className="mb-0 text-danger">お支払いが完了するまで注文は確定されません</p>
                  </div>
                </div>
                <div className="row mx-0 pb-2">
                  <div className="col py-0 px-5 align-self-center">
                    <button
                      className={`btn btn-submit-alert w-100 py-2 px-2`}
                      onClick={() => this.submitPayment(this.props.current_order)}>
                      決済画面へ
                    </button>
                  </div>
                </div>
              </div>
            }
            {/* <div className="row mx-0 px-3-env pt-2 pb-1">
              <div className="col-10 bg-white py-2 px-1 align-self-center border-textbox rounded">
                <div className="row mx-0 text-left">
                  <div className="col-4 px-0">受取店舗</div>
                  <div className="col-8 px-0">{this.state.shopMaster.find(s => s.shop_id === this.state.my_order.find(o => o.order_id === this.props.current_order).receive_shop).shop_name}</div>
                </div>
              </div>
              <div className="col-2 py-1 px-1 align-self-center">
                <button
                  className={`btn btn-enable w-100 py-2 px-2`}
                  onClick={() => this.moveOrderShop()}>
                  変更
                </button>
              </div>
            </div> */}
            <div className="row mx-0 px-3-env pt-1 pb-2">
              <div className="col text-center">
                ご注文内容
              </div>
            </div>
            {
              this.state.my_order.filter(o => o.order_id === this.props.current_order).map((order) =>
                <div key={order.order_id} className="mb-2 p-2 bg-white border border-info rounded mx-2">
                  <div className="row mx-0 px-1-env">
                    <div className="col py-1 px-1 align-self-center border-bottom">
                      <div className="row mx-0 text-left">
                        <div className="col px-0">注文番号　No.{order.receipt_num}</div>
                      </div>
                      <div className="row mx-0 text-left">
                        <div className="col px-0">{this.state.planMaster.find(p => p.plan_id === order.plan_id).plan_name}</div>
                      </div>
                      <div className="row mx-0 px-2 mt-2 mb-2">
                        <div className="col">
                          {/* <div className="row pb-2">
                            <div className="col-5 align-self-center">
                              受取日
                            </div>
                            <div className="col-7 order-datepicker">
                              {this.state.planMaster.find(p => p.plan_id === order.plan_id).year_round_type === "1" &&
                                <DatePicker className="custom-select w-100 text-center border-textbox text-primary font-weight-bold" aria_labal="受取日" locale="ja" dateFormat="yyyy/MM/dd" monthsShown="1" id="receiveDate" name="receiveDate"
                                  popperPlacement='bottom'
                                  popperModifiers={{
                                    flip: {
                                      behavior: ["bottom"] // don't allow it to flip to be above
                                    },
                                  }}
                                  selected={order.receive_date ? new Date(order.receive_date.replace(/-/g, '/')) : null}
                                  minDate={this.getMinDateReceiveDate(order.receive_date, this.state.productMaster.filter(p => order.t_order_detail.map(d => d.product_id).includes(p.product_id)))}
                                  onChange={date => this.setOrderReceiveDate(order.order_id, date ? moment(date).format('YYYYMMDD') : null)}
                                  placeholderText="- 選択 -"
                                  customInput={<button>{order.receive_date ? order.receive_date.replace(/-/g, '/') : "- 選択 -"}</button>}
                                />
                              }
                              {this.state.planMaster.find(p => p.plan_id === order.plan_id).year_round_type !== "1" &&
                                <select className="custom-select w-100 text-center border-textbox text-primary font-weight-bold" aria-label="受取日" value={order.receive_date ? order.receive_date.replace(/-/g, '') : ''} onChange={(e) => this.setOrderReceiveDate(order.order_id, e.target.value)}>
                                  <option value="">- 選択 -</option>
                                  {this.getDuplicateReceiveDate(this.state.productMaster.filter(p => order.t_order_detail.map(d => d.product_id).includes(p.product_id))) //productMasterをcartに含まれるものでフィルタして関数に渡す
                                    .map(item => // receive_dateの配列に変換→重複除外→オプションのDOM要素に変換
                                      <option key={item} value={item}>{item.substr(0, 4) + '/' + item.substr(4, 2) + '/' + item.substr(6, 2)}</option>
                                    )}
                                </select>
                              }
                            </div>
                          </div> */}
                          {/* {order.delivery_flag !== constClass.FLAG.ON &&
                            <div className="row pb-2">
                              <div className="col-5 align-self-center">
                                受取時間帯
                              </div>
                              <div className="col-7">
                                <select className="custom-select w-100 text-center border-textbox text-primary font-weight-bold" aria-label="受取時間帯" value={order.receive_time} onChange={(e) => this.setOrderReceiveTime(order.order_id, e.target.value)}>
                                  <option value="">- 選択 -</option>
                                  {this.getDuplicateReceiveNgTime(
                                    this.state.productMaster.filter(p => order.t_order_detail.map(d => d.product_id).includes(p.product_id))
                                    , this.state.timeMaster
                                  )
                                    .map(item =>
                                      <option key={item.time_id} value={item.time_id}>{item.time_name}</option>
                                    )}
                                </select>
                              </div>
                            </div >
                          }
                          {
                            order.delivery_flag === constClass.FLAG.ON &&
                            <div className="row pb-2">
                              <div className="col-3 align-self-center">
                                配送先
                              </div>
                              <div className="col-7 px-0 align-self-center">
                                <small>{order.delivery_kana}<br /></small>
                                {order.delivery_name}<br />
                                &#x2121;{order.delivery_tel}<br />
                                &#x3012;{order.delivery_postal_code}<br />
                                {order.delivery_address}
                              </div>
                              <div className="col-2 align-self-center text-nowrap">
                                <button
                                  className={`btn btn-enable w-90 py-2 px-2`}
                                  onClick={() => this.moveOrderCustomer()}>
                                  変更
                                </button>
                              </div>
                            </div>
                          } */}
                        </div >
                      </div >
                      {
                        order.t_order_detail.map((item) =>
                          <div key={item.product_id} className="m-2 mb-1">
                            <div className="row mx-0 text-left">
                              <div className="col px-0 text-info">{this.state.productMaster.find(p => p.product_id === item.product_id).product_name}</div>
                            </div>
                            <div className="row mx-0 text-left">
                              <div className="col px-2">発送予定日：{Common.getDateObject(this.state.productMaster.find(p => p.product_id === item.product_id).shipping_date).str}</div>
                            </div>
                            <div className="row mx-0 text-right  align-self-center">
                              <div className="col-5 px-0 align-self-center">
                                &yen;{Common.getSettingValue(this.settings, 'TAX_INCLUDED_FLAG', '0') === '1' ? Math.floor(item.price).toLocaleString() + '(税込)' : Math.floor(item.price_before_tax).toLocaleString()}<br />
                                {Common.getSettingValue(this.settings, 'TAX_INCLUDED_FLAG', '0') !== '1' && <small>&yen;{item.price.toLocaleString()}(税込)</small>}
                              </div>
                              <div className="col-7">
                                <div className="row">
                                  <div className="col-auto text-center p-1 ml-1 align-self-center">
                                    <button
                                      disabled={this.state.disabled || this.state.message_error[item.product_id]}
                                      className={`btn btn-digitalcard w-100 h-100 py-1 px-2`}
                                      onClick={() => { this.subOrder(order.order_id, item.product_id) }}>
                                      －
                                    </button>
                                  </div>
                                  <div className="col text-center py-1 px-1 align-self-center border-textbox">
                                    <div className="bg-white">{item.count}</div>
                                  </div>
                                  <div className="col-auto text-center p-1 mr-1 align-self-center">
                                    <button
                                      disabled={this.state.disabled || this.state.message_error[item.product_id]}
                                      className={`btn btn-digitalcard w-100 h-100 py-1 px-2`}
                                      onClick={() => { this.addOrder(order.order_id, item.product_id) }}>
                                      ＋
                                    </button>
                                  </div>
                                  <div className="col-auto align-self-center pl-1 pr-0">個</div>
                                </div>
                              </div>
                            </div>
                            <div className="row mx-0 text-right  align-self-center">
                              <div className="col px-0 text-danger">{this.state.message[item.product_id]}{this.state.message_error[item.product_id]}</div>
                              <div className="col-auto px-0">
                                <button
                                  className={`btn btn-enable w-100 py-0 px-2`}
                                  onClick={() => { this.deleteOrder(order.order_id, item.product_id) }}>
                                  削除
                                </button>
                              </div>
                            </div>
                          </div>
                        )
                      }
                    </div >
                  </div >
                  <div className="row mx-0 px-1-env pt-1 pb-2">
                    <div className="col">
                      <div className="row">
                        <div className="col-6 text-right">
                          小計
                        </div>
                        <div className="col-6 text-right">
                          &yen;{(this.getOrderTotal(order).subtotal).toLocaleString()}
                        </div>
                      </div>
                      {this.getOrderTotal(order).discount > 0 &&
                        <div className="row">
                          <div className="col-6 text-right">
                            早期割引
                          </div>
                          <div className="col-6 text-right">
                            &yen;{(this.getOrderTotal(order).discount * -1).toLocaleString()}
                          </div>
                        </div>
                      }
                      <div className="row">
                        <div className="col-6 text-right">
                          {Common.getSettingValue(this.settings, 'TAX_INCLUDED_FLAG', '0') === '1' ? '(内税額' : '税額'}
                        </div>
                        <div className="col-6 text-right">
                          &yen;{(this.getOrderTotal(order).tax).toLocaleString()}{Common.getSettingValue(this.settings, 'TAX_INCLUDED_FLAG', '0') === '1' ? ')' : ''}
                        </div>
                      </div>
                      {this.getOrderTotal(order).delivery_price > 0 &&
                        <div className="row">
                          <div className="col-6 text-right">
                            送料
                          </div>
                          <div className="col-6 text-right">
                            &yen;{this.getOrderTotal(order).delivery_price.toLocaleString()}
                          </div>
                        </div>
                      }
                      {/* {this.getOrderTotal(order).discount > 0 &&
                        <div className="row">
                          <div className="col-6 text-right">
                            税込金額
                          </div>
                          <div className="col-6 text-right">
                            &yen;{(this.getOrderTotal(order).subtotal + this.getOrderTotal(order).tax).toLocaleString()}
                          </div>
                        </div>
                      } */}
                      <div className="row">
                        <div className="col-6 text-right">
                          合計
                        </div>
                        <div className="col-6 text-right">
                          &yen;{(this.getOrderTotal(order).total).toLocaleString()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div >
              )
            }
            <div className="row mx-0">
              <div className="col py-1 px-5 align-self-center">
                <button
                  className={`btn btn-enable w-100 py-2 px-2`}
                  onClick={() => this.submitCancel()}>
                  この注文をキャンセルする
                </button>
              </div>
            </div>
          </div >
        </div >
      </div >
    );
  }

  // ご注文履歴・変更
  renderOrderSelect() {
    return (
      <div className="row mx-0">
        <div className="col px-0">
          <div className="row mx-0">
            <div className="col py-2 text-center">
              <span>{Common.getSettingValue(this.settings, 'HISTORY_PRODUCT_LABEL', '')}</span>
            </div>
          </div>
          <div>
            {Common.getSettingValue(this.settings, 'HISTORY_UPAY_DISP_FLAG', '0') === '1' &&
              this.state.my_order.filter(o => ([constClass.ORDER_STATUS.UPAY].includes(o.status) && this.props.page === constClass.HISTORY)).length > 0 && // 履歴の場合に未決済があれば当セクションを表示
              <div className="row mx-0 px-3-env pt-2 pb-1 border-bottom">
                <div className="col py-1 px-1 align-self-center">{/* 企画ID降順・注文番号降順 */}
                  {this.state.my_order.filter(o => ([constClass.ORDER_STATUS.UPAY].includes(o.status))) //未決済のみ
                    .sort((a, b) => (this.props.page === constClass.RECEIVESELECT ? Date.parse(a.receive_date) - Date.parse(b.receive_date) : (a.plan_id === b.plan_id) ? b.order_id - a.order_id : b.plan_id - a.plan_id)).map((order) =>
                      this.renderOrderSelectdetail(order)
                    )}
                </div>
              </div>
            }
            <div className="row mx-0 px-3-env pt-2 pb-1">
              <div className="col py-1 px-1 align-self-center">{/* 企画ID降順・注文番号降順 */}
                {this.state.my_order.filter(o => ([constClass.ORDER_STATUS.REG, constClass.ORDER_STATUS.PRE].includes(o.status) && (this.props.page === constClass.HISTORY || o.delivery_flag !== constClass.FLAG.ON))) //予約済み・事前通知済みのみ、履歴ではない（受取選択）場合は配送ではないデータのみ
                  .sort((a, b) => (this.props.page === constClass.RECEIVESELECT ? Date.parse(a.receive_date) - Date.parse(b.receive_date) : (a.plan_id === b.plan_id) ? b.order_id - a.order_id : b.plan_id - a.plan_id)).map((order) =>
                    this.renderOrderSelectdetail(order)
                  )}
              </div>
            </div>
            <div className="row mx-0 px-3-env pt-2 pb-1 border-top">
              <div className="col py-1 px-1 align-self-center">
                {this.state.my_order.filter(o => (![constClass.ORDER_STATUS.REG, constClass.ORDER_STATUS.PRE, constClass.ORDER_STATUS.UPAY, constClass.ORDER_STATUS.CUPAY].includes(o.status) && this.props.page === constClass.HISTORY)) //履歴の場合の予約済み・事前通知済み以外
                  .sort((a, b) => (this.props.page === constClass.HISTORY ? a.order_id - b.order_id : Date.parse(a.receive_date) - Date.parse(b.receive_date))).map((order) =>
                    this.renderOrderSelectdetail(order)
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderOrderSelectdetail(order) {
    return <div key={order.order_id} className="row mx-0 my-3 text-center">
      <div className="col px-0 bg-white">
        <button
          className={`btn ${order.status === constClass.ORDER_STATUS.UPAY ? `btn-submit-alert` : `btn-enable`} w-100 h-100 py-2 px-2`}
          onClick={() => this.submitCurrentOrder(order.order_id, constClass.RECEIVE)}>
          {/* <h4 className="mb-0 font-weight-bold">{this.getShortDateString(order.receive_date)} {Common.getSettingValue(this.settings, 'HISTORY_PRODUCT_NAME', '')}</h4> */}
          <h4 className="mb-0 font-weight-bold">注文No.{order.receipt_num} ({order.plan_name}) {this.props.page === constClass.HISTORY &&
            <span className="font-weight-normal small"><br />注文日:{Common.getDateObject(this.getDateString(new Date(order.receipt_datetime))).shortStr}&nbsp;&nbsp;{constClass.ORDER_STATUS_NAME_USER[order.status]} {/* &nbsp;
              {this.checkOrderChangeLimit(order.order_id) && <span>{this.getShortDateString(this.getChangeLimitFromOrder(order))}まで{order.status === constClass.ORDER_STATUS.UPAY ? 'にお支払いください' : '変更可能'}</span>} */}
            </span>
          }</h4>
        </button>
      </div>
    </div>
  }


  renderReceive() {
    return (
      <div className="row mx-0">
        <div className="col px-0">
          {!(this.state.my_order.find(o => o.order_id === this.props.current_order)) &&
            <div className="row mx-0 px-0-env pt-1 pb-2">
              <div className="col text-center">情報が取得できませんでした。</div>
            </div>
          }
          {this.state.my_order.find(o => o.order_id === this.props.current_order) &&
            <div>
              {this.props.page === constClass.RECEIVE &&
                <div>
                  {this.state.my_order.find(o => o.order_id === this.props.current_order).status === constClass.ORDER_STATUS.FIN &&
                    <div className="row mx-0 px-0-env pt-1 pb-2">
                      <div className="col text-center">お受け取りが完了しています。</div>
                    </div>
                  }
                  {this.state.my_order.find(o => o.order_id === this.props.current_order).status === constClass.ORDER_STATUS.DFIN &&
                    <div className="row mx-0 px-0-env pt-1 pb-2">
                      <div className="col text-center">完了しています。</div>
                    </div>
                  }
                  {this.state.my_order.find(o => o.order_id === this.props.current_order).status === constClass.ORDER_STATUS.CCL &&
                    <div className="row mx-0 px-0-env pt-1 pb-2">
                      <div className="col text-center">この注文はキャンセルされました。</div>
                    </div>
                  }
                  {this.state.my_order.find(o => o.order_id === this.props.current_order).status === constClass.ORDER_STATUS.CUPAY &&
                    <div className="row mx-0 px-0-env pt-1 pb-2">
                      <div className="col text-center">決済期限が切れています。お手数ですが、再度ご注文ください。</div>
                    </div>
                  }
                  {/* {this.state.my_order.find(o => o.order_id === this.props.current_order).status === constClass.ORDER_STATUS.UPAY &&
                    <div>
                      <div className="row mx-0 px-0-env pt-1">
                        <div className="col text-center">
                          <p className="mb-0 text-danger">お支払いが完了していません</p>
                        </div>
                      </div>
                      <div className="row mx-0 pb-2">
                        <div className="col py-0 px-5 align-self-center">
                          <button
                            className={`btn btn-submit-alert w-100 py-2 px-2`}
                            onClick={() => this.submitPayment(this.props.current_order)}>
                            決済画面へ
                          </button>
                        </div>
                      </div>
                    </div>
                  } */}
                  {([constClass.ORDER_STATUS.REG, constClass.ORDER_STATUS.PRE].includes(this.state.my_order.find(o => o.order_id === this.props.current_order).status) &&
                    this.state.my_order.find(o => o.order_id === this.props.current_order).delivery_flag !== constClass.FLAG.ON) &&
                    <div>
                      <div className="row mx-0 px-0-env pt-1 pb-2">
                        <div className="col text-center">
                          {Common.getBrString(Common.getSettingValue(this.settings, 'CONFIRMATION_STAFF_MESSAGE', ''))}
                        </div>
                      </div>
                      <div className="row mx-0 px-0-env pt-1 pb-2">
                        <div className="col text-center">
                          <button
                            disabled={this.state.disabled}
                            className={`btn-lg btn-submit-alert w-75 py-3 p-env-bottom`}
                            onClick={() => { this.submitReceive() }}>
                            受け取る
                          </button>
                        </div>
                      </div>
                    </div>
                  }
                  {([constClass.ORDER_STATUS.REG, constClass.ORDER_STATUS.PRE].includes(this.state.my_order.find(o => o.order_id === this.props.current_order).status) &&
                    this.state.my_order.find(o => o.order_id === this.props.current_order).delivery_flag === constClass.FLAG.ON &&
                    !!Common.getDateObject(this.state.my_order.find(o => o.order_id === this.props.current_order).t_order_detail.reduce((prev, d) => prev < d.estimated_shipping_date ? d.estimated_shipping_date : prev, '00000000')).str) &&
                    <div className="row mx-0 px-0-env pt-1 pb-2">
                      <div className="col text-center">{Common.getSettingValue(this.settings, 'ORDER_SHIPPING_NOTICE_PREFIX', '')}{Common.getDateObject(this.state.my_order.find(o => o.order_id === this.props.current_order).t_order_detail.reduce((prev, d) => prev < d.estimated_shipping_date ? d.estimated_shipping_date : prev, '00000000')).str}{Common.getSettingValue(this.settings, 'ORDER_SHIPPING_NOTICE_SUFFIX', '')}</div>
                    </div>
                  }
                </div>
              }
              {this.props.page === constClass.CHANGE &&
                <div>
                  {this.state.my_order.find(o => o.order_id === this.props.current_order).status === constClass.ORDER_STATUS.CCL &&
                    <div className="row mx-0 px-0-env pt-1 pb-2">
                      <div className="col text-center">
                        この注文はキャンセルされました。<br />
                      </div>
                    </div>
                  }
                  {this.state.my_order.find(o => o.order_id === this.props.current_order).status === constClass.ORDER_STATUS.FIN &&
                    <div className="row mx-0 px-0-env pt-1 pb-2">
                      <div className="col text-center">
                        お受け取りが完了しました。<br />
                        ご利用ありがとうございました。<br />
                      </div>
                    </div>
                  }
                  {this.state.my_order.find(o => o.order_id === this.props.current_order).status === constClass.ORDER_STATUS.CUPAY &&
                    <div className="row mx-0 px-0-env pt-1 pb-2">
                      <div className="col text-center">
                        決済期限が切れています。<br />
                        お手数ですが、再度ご注文ください。
                      </div>
                    </div>
                  }
                  {/* {this.state.my_order.find(o => o.order_id === this.props.current_order).status === constClass.ORDER_STATUS.UPAY &&
                    <div>
                      <div className="row mx-0 px-0-env pt-1">
                        <div className="col text-center">
                          <p className="mb-0 text-danger">お支払いが完了していません</p>
                        </div>
                      </div>
                      <div className="row mx-0 pb-2">
                        <div className="col py-0 px-5 align-self-center">
                          <button
                            className={`btn btn-submit-alert w-100 py-2 px-2`}
                            onClick={() => this.submitPayment(this.props.current_order)}>
                            決済画面へ
                          </button>
                        </div>
                      </div>
                    </div>
                  } */}
                </div>
              }
              <div className="row mx-0 px-3-env pt-2 pb-2">
                {this.state.my_order.filter(o => o.order_id === this.props.current_order).map(order =>
                  <div key={order.order_id} className="col-12 bg-white py-1 px-1 align-self-center border-textbox rounded">
                    <div className="row mx-0 text-center">
                      <div className="col px-0 bg-white"><h4 className="mb-0 text-danger">注文番号　No. {order.receipt_num}</h4></div>
                    </div>
                    <div className="row mx-0 text-center">
                      <div className="col px-0 bg-white">{order.customer_name} 様</div>
                    </div>
                    <div className="row mx-0 mb-2 text-center">
                      <div className="col px-0 bg-white">注文日：{Common.getDateObject(this.getDateString(new Date(order.receipt_datetime))).str}</div>
                    </div>
                    <div className="col py-2">
                      <div className="row">
                        <div className="col-6 text-right">
                          点数
                        </div>
                        <div className="col-6 text-right">
                          {order.t_order_detail.reduce((sumcount, detail) => sumcount + detail.count, 0)}個
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 text-right">
                          小計
                        </div>
                        <div className="col-6 text-right">
                          &yen;{Math.floor(order.subtotal).toLocaleString()}
                        </div>
                      </div>
                      {order.discount > 0 &&
                        <div className="row mb-1">
                          <div className="col-6 text-right">
                            早期割引
                          </div>
                          <div className="col-6 text-right">
                            &yen;{Math.floor(order.discount * -1).toLocaleString()}
                          </div>
                        </div>
                      }
                      <div className="row border-bottom mb-1">
                        <div className="col-6 text-right">
                          {Common.getSettingValue(this.settings, 'TAX_INCLUDED_FLAG', '0') === '1' ? '(内税額' : '税額'}
                        </div>
                        <div className="col-6 text-right">
                          &yen;{Math.floor(order.tax).toLocaleString()}{Common.getSettingValue(this.settings, 'TAX_INCLUDED_FLAG', '0') === '1' ? ')' : ''}
                        </div>
                      </div>
                      {order.delivery_price > 0 &&
                        <div className="row mb-1">
                          <div className="col-6 text-right">
                            送料
                          </div>
                          <div className="col-6 text-right">
                            &yen;{Math.floor(order.delivery_price).toLocaleString()}
                          </div>
                        </div>
                      }
                      {/* {order.discount > 0 &&
                        <div className="row border-bottom mb-1">
                          <div className="col-6 text-right">
                            税込金額
                          </div>
                          <div className="col-6 text-right">
                            &yen;{(Math.floor(order.total) + Math.floor(order.discount)).toLocaleString()}
                          </div>
                        </div>
                      } */}
                      <div className="row text-danger">
                        <div className="col-6 text-right">
                          <h4 className="mb-0">合計</h4>
                        </div>
                        <div className="col-6 text-right">
                          <h4 className="mb-0">&yen;{Math.floor(order.total).toLocaleString()}</h4>
                        </div>
                      </div>
                      {order.payment_fin_flag === constClass.FLAG.ON &&
                        <div className="row justify-content-center mt-1">
                          <div className="col-auto bg-danger text-white py-1">
                            <h4 className="mb-0">※お支払い済み</h4>
                          </div>
                        </div>
                      }
                      {/* <div className="row mx-0 mt-2">
                        <div className="col-4 pr-3 pl-0 bg-white text-left">受取日</div>
                        <div className="col-8 px-0 bg-white text-left">{moment(order.receive_date).format('YYYY年M月D日(dd)')}</div>
                      </div> */}
                      {/* {order.delivery_flag !== constClass.FLAG.ON &&
                        <div className="row mx-0">
                          <div className="col-4 pr-3 pl-0 bg-white text-left">受取時間帯</div>
                          <div className="col-8 px-0 bg-white text-left">{this.state.timeMaster.find(t => String(t.time_id) === order.receive_time) ? this.state.timeMaster.find(t => String(t.time_id) === order.receive_time).time_name : ''}</div>
                        </div>
                      }
                      {order.delivery_flag === constClass.FLAG.ON &&
                        <div className="row mx-0">
                          <div className="col-4 pr-3 pl-0 bg-white text-left">配送先</div>
                          <div className="col-8 px-0 align-self-center">
                            <small>{order.delivery_kana}<br /></small>
                            {order.delivery_name}<br />
                            &#x2121;{order.delivery_tel}<br />
                            &#x3012;{order.delivery_postal_code}<br />
                            {order.delivery_address}
                          </div>
                        </div>
                      } */}
                      <div className="row mx-0 text-left mt-4">
                        <div className="col px-0 bg-white">{order.plan_name}</div>
                      </div>
                      {order.t_order_detail.map(detail =>
                        <div key={detail.product_id}>
                          <div className="row mx-1 text-left border-top">
                            {/* <div className="col-1 px-0 bg-white">{detail.product_code}</div> */}
                            <div className="col px-0 bg-white text-info">{detail.product_name}</div>
                          </div>
                          {!!Common.getDateObject(detail.estimated_shipping_date).str &&
                            <div className="row mx-1 text-left">
                              <div className="col-1 px-0 bg-white"></div>
                              <div className="col-11 px-0 bg-white">{Common.getDateObject(detail.estimated_shipping_date).str}以降発送予定</div>
                            </div>
                          }
                          {!!detail.notice &&
                            <div className="row mx-1 text-left">
                              <div className="col-1 px-0 bg-white"></div>
                              <div className="col-11 px-0 bg-white">備考：{detail.notice}</div>
                            </div>
                          }
                          <div className="row mx-2 text-right">
                            <div className="col-6 px-0 bg-white">{detail.count}個</div>
                            <div className="col-6 px-0 bg-white">
                              &yen;{Common.getSettingValue(this.settings, 'TAX_INCLUDED_FLAG', '0') === '1' ? Math.floor(detail.price * detail.count).toLocaleString() + '(税込)' : Math.floor(detail.price_before_tax * detail.count).toLocaleString()}<br />
                              {Common.getSettingValue(this.settings, 'TAX_INCLUDED_FLAG', '0') !== '1' && <small>&yen;{(detail.price * detail.count).toFixed(2).toLocaleString()}(税込)</small>}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {this.state.my_order.find(o => o.order_id === this.props.current_order).status === constClass.ORDER_STATUS.UPAY &&
                <div>
                  <div className="row mx-0 px-0-env pt-1 pb-2">
                    <div className="col text-center">
                      <p className="mb-0 text-danger">お支払いが完了していません</p>
                    </div>
                  </div>
                  <div className="row mx-0 pb-2">
                    <div className="col py-0 px-5 align-self-center">
                      <button
                        className={`btn btn-submit-alert w-100 py-2 px-2`}
                        onClick={() => this.submitPayment(this.props.current_order)}>
                        決済画面へ
                      </button>
                    </div>
                  </div>
                </div>
              }
              {/* {([constClass.ORDER_STATUS.REG, constClass.ORDER_STATUS.PRE].includes(this.state.my_order.find(o => o.order_id === this.props.current_order).status) &&
                !this.checkOrderChangeLimit()) &&
                <div>
                  <div className="row mx-0 px-0-env pt-1 pb-2">
                    <div className="col text-center">
                      {Common.getBrString(Common.getSettingValue(this.settings, 'POSSIBILITY_DELAY_MESSAGE', ''))}
                      {this.state.my_order.filter(o => o.order_id === this.props.current_order).map((order) =>
                        this.state.shopMaster.find(s => s.shop_id === order.receive_shop) ? this.state.shopMaster.find(s => s.shop_id === order.receive_shop).shop_name + ' ' + this.state.shopMaster.find(s => s.shop_id === order.receive_shop).shop_tel : null
                      )}
                    </div>
                  </div>
                </div>
              } */}
            </div>
          }
        </div>
      </div>
    );
  }

  renderPayment() {
    return (
      <div className="row mx-0">
        <div className="col px-0">
          <Payment
            siteId={this.state.siteId}
            lineId={this.props.liff_access_token}
            id={this.state.payment_data.id}
            amount={this.state.payment_data.amount}
            fee={this.state.payment_data.fee}
            backUrl={this.state.payment_data.backUrl}
            returnUrl={this.state.payment_data.returnUrl}
            checkUrl={this.state.payment_data.checkUrl}
            abortUrl={`/${this.state.siteId}/`}
            backFunc={this.returnPayment}
            returnFunc={this.returnPayment} />
        </div>
      </div>
    );
  }

  renderEmployee() {
    return (
      <div className="row mx-0">
        <div className="col px-0">
          <div className="row mx-0 my-3 text-center">
            <div className="col px-0">
              従業員の方は、<br />
              以下のボタンで従業員登録をお願いします。
            </div>
          </div>
          {(this.state.my_customer && this.state.my_customer.employee_flag === '1') &&
            <div>
              <div className="row mx-0 px-0-env pt-1 pb-2">
                <div className="col text-center">
                  <button
                    disabled={true}
                    className={`btn-lg btn-submit-alert w-100 py-3 p-env-bottom`}
                    onClick={() => { this.submitEmployee() }}>
                    従業員登録済み
                  </button>
                </div>
              </div>
              <div className="row mx-0 px-0-env pt-1 pb-2">
                <div className="col text-center">
                  <span className="align-middle">従業員登録の解除は</span>
                  <button className="btn btn-link p-0 border-0" onClick={() => this.submitEmployeeCancel()}>こちら</button>
                </div>
              </div>
            </div>
          }
          {(!this.state.my_customer || this.state.my_customer.employee_flag !== '1') &&
            <div className="row mx-0 px-0-env pt-1 pb-2">
              <div className="col text-center">
                <button
                  disabled={this.state.disabled}
                  className={`btn-lg btn-submit-alert w-100 py-3 p-env-bottom`}
                  onClick={() => { this.submitEmployee() }}>
                  従業員登録
                </button>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }

  renderBottom() {
    const Historybackbutton = (h) => {
      return (
        <div className="col-3 text-center p-0">
          <button
            className={`btn-lg btn-submit-enable w-100 py-3 p-env-bottom`}
            onClick={() => {
              if (this.props.history.length <= 1) {
                this.props.history.push(`/${this.state.siteId}/?page=${constClass.MENU}`);
              } else {
                this.props.history.goBack();
              }
              this.scrollPageTop();
            }}>
            戻る
          </button>
        </div>
      )
    }
    return (
      <footer className="footer text-center">
        <div className="container m-0 p-0 mw-100" ref={this.footerRef}>
          {(this.props.page === constClass.REQUEST) &&
            <div className="row mx-0 bg-gray-alpha">
              <div className="col text-center p-0">
                <div className="row mx-0">
                  <div className="col-auto text-left py-1 px-2">
                    ●選択中商品　合計金額
                  </div>
                  <div className="col text-right py-1 px-2">
                    &yen;{(this.state.cart.reduce((sum, plan) => sum + this.getOrderTotal(plan).total, 0)).toLocaleString()}(税込)
                  </div>
                </div>
              </div>
            </div>
          }
          <div className="row mx-0">
            {(this.props.page === constClass.PLAN) && (
              <div className="col text-center p-0">
                <div className="row mx-0">
                  <Historybackbutton />
                  <div className="col-9 text-center p-0">
                    <button
                      disabled={!this.state.receive_shop || this.state.planMaster.length <= 0 || this.state.disabled}
                      className={`btn-lg btn-submit w-100 py-3 p-env-bottom`}
                      onClick={() => { this.submitReceiveShop() }}>
                      商品選択へ進む
                    </button>
                  </div>
                </div>
              </div>
            )}
            {(this.props.page === constClass.REQUEST) && (
              <div className="col text-center p-0">
                <div className="row mx-0">
                  <Historybackbutton />
                  <div className="col-9 text-center p-0">
                    <button
                      disabled={this.state.cart.length <= 0 ||
                        this.state.cart.reduce((flag, c) => (flag || c.detail.reduce((prev, d) => (prev || (this.state.productMaster.find(p => p.product_id === d.productId).notice_flag === constClass.FLAG.ON && !d.notice)), false)), false) ||
                        this.state.disabled}
                      className={`btn-lg btn-submit w-100 py-3 p-env-bottom`}
                      onClick={() => this.submitRequest()}>
                      ご注文確認画面へ進む
                    </button>
                  </div>
                </div>
              </div>
            )}
            {(this.props.page === constClass.USER) && (
              <div className="col text-center p-0">
                <div className="row mx-0">
                  <Historybackbutton />
                  <div className="col-9 text-center p-0">
                    <button
                      // disabled={!this.state.name || !this.state.kana || !this.state.tel || this.state.disabled || !this.state.privacypolicy || this.state.message.tel
                      /*|| (this.state.planMaster.filter(p => this.state.cart.map(c => c.planId).includes(p.plan_id)).reduce((prev, p) => p.delivery_flag === constClass.FLAG.ON ? true : prev, false) && (!this.state.postal_code || !this.state.address))}*/
                      disabled={this.checkUserInvalid()} className="btn-lg btn-submit w-100 py-3 p-env-bottom"
                      onClick={() => { this.submitCustomer(constClass.CONFIRM) }}>
                      ご注文確認画面へ進む
                    </button>
                  </div>
                </div>
              </div>
            )}
            {(this.props.page === constClass.CONFIRM) && (
              <div className="col text-center p-0">
                <div className="row mx-0">
                  <Historybackbutton />
                  <div className="col-9 text-center p-0">
                    <button
                      disabled={this.state.cart.length <= 0 ||
                        // this.state.cart.reduce((flag, plan) => (flag || !plan.receiveDate), false) ||
                        this.state.disabled}
                      className="btn-lg btn-submit w-100 py-3 p-env-bottom"
                      onClick={() => { this.submit() }}>
                      {(this.state.cart.filter(c => c.payment_flag === constClass.FLAG.ON).length > 0 && this.state.cart.reduce((prev, c) => prev + this.getOrderTotal(c).total, 0) > 0) ? "次へ" : "注文確定"}
                    </button>
                  </div>
                </div>
              </div>
            )}
            {(this.props.page === constClass.CHANGE) && (
              <div className="col text-center p-0">
                <div className="row mx-0">
                  <div className="col-3 text-center p-0">
                    <button
                      className={`btn-lg btn-submit-enable w-100 py-3 p-env-bottom`}
                      onClick={() => this.cancelChange()}>
                      戻る
                    </button>
                  </div>
                  {(this.checkOrderChangeLimit()) &&
                    <div className="col-9 text-center p-0">
                      <button
                        disabled={!this.state.my_order.reduce((flag, order) => (flag || order.changed), false)
                          || this.state.my_order.reduce((flag, order) => (flag || !order.receive_date), false)
                          || this.state.my_order.reduce((flag, order) => (flag || order.t_order_detail.length <= 0), false)
                          || Object.entries(this.state.message_error).reduce((flag, item) => flag || item[1], false)
                          || this.state.disabled}
                        className="btn-lg btn-submit w-100 py-3 p-env-bottom"
                        onClick={() => { this.submitChange() }}>
                        注文変更
                      </button>
                    </div>
                  }
                </div>
              </div>
            )}
            {(this.props.page === constClass.ORDERUSERCHANGE) && (
              <div className="col text-center p-0">
                <div className="row mx-0">
                  <div className="col-3 text-center p-0">
                    <button
                      className={`btn-lg btn-submit-enable w-100 py-3 p-env-bottom`}
                      onClick={() => this.cancelOrderCustomer()}>
                      戻る
                    </button>
                  </div>
                  <div className="col-9 text-center p-0">
                    <button
                      disabled={!this.state.name || !this.state.kana || !this.state.tel || this.state.disabled || !this.state.privacypolicy || this.state.message.kana || this.state.message.tel || this.state.message.delivery_kana || this.state.message.delivery_tel
                        || (this.state.my_order.find(o => o.order_id === this.props.current_order).delivery_flag === constClass.FLAG.ON && (!this.state.postal_code || !this.state.address))}
                      className="btn-lg btn-submit w-100 py-3 p-env-bottom"
                      onClick={() => { this.submitOrderCustomer() }}>
                      保存
                    </button>
                  </div>
                </div>
              </div>
            )}
            {(this.props.page === constClass.ORDERSHOPCHANGE) && (
              <div className="col text-center p-0">
                <div className="row mx-0">
                  <div className="col-3 text-center p-0">
                    <button
                      className={`btn-lg btn-submit-enable w-100 py-3 p-env-bottom`}
                      onClick={() => this.cancelOrderShop()}>
                      戻る
                    </button>
                  </div>
                  <div className="col-9 text-center p-0">
                    <button
                      disabled={!this.state.receive_shop || this.state.disabled}
                      className="btn-lg btn-submit w-100 py-3 p-env-bottom"
                      onClick={() => { this.submitOrderShop() }}>
                      保存
                    </button>
                  </div>
                </div>
              </div>
            )}
            {(this.props.page === constClass.USERCHANGE) && (
              <div className="col text-center p-0">
                <div className="row mx-0">
                  <Historybackbutton />
                  <div className="col-9 text-center p-0">
                    <button
                      disabled={!this.state.name || !this.state.kana || !this.state.tel || this.state.disabled || !this.state.privacypolicy || this.state.message.kana || this.state.message.tel}
                      className="btn-lg btn-submit w-100 py-3 p-env-bottom"
                      onClick={() => { this.submitCustomer('') }}>
                      保存
                    </button>
                  </div>
                </div>
              </div>
            )}
            {(this.props.page === constClass.RECEIVE
              || this.props.page === constClass.RECEIVESELECT
              || this.props.page === constClass.HISTORY) && (
                <div className="col text-center p-0">
                  <div className="row mx-0">
                    <Historybackbutton />
                  </div>
                </div>

              )}
            {(this.props.page === constClass.RECEIVEFINISH
              || this.props.page === constClass.ORDERFINISH
              || this.props.page === constClass.CHANGEFINISH
              || this.props.page === constClass.MEMBERFINISH
              || this.props.page === constClass.EMPLOYEE) && (
                <div className="col text-center p-0">
                  <div className="row mx-0">
                    <div className="col-3 text-center p-0">
                      <button
                        className={`btn-lg btn-submit-enable w-100 py-3 p-env-bottom`}
                        onClick={() => { this.props.history.push(`/${this.state.siteId}/?page=${constClass.MENU}`); }}>
                        戻る
                      </button>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </footer>
    );
  }

  // 各画面設定
  render() {
    const pageClass = (page) => {
      switch (page) {
        case null:
        case constClass.PAYMENT:
          return 'page-order-full';
        default:
          return 'page-order-btn';
      }
    }
    return (
      this.state.ready ?
        Common.checkOrderPage(this.props.page) ? <div className="liff-top bg-lightwhite font-weight-bold" style={{ '--header-order-height': `${this.state.headerHeight}px`, '--footer-order-height': `${this.state.footerHeight}px` }}>
          <DocumentMeta {...this.meta} />
          {this.renderHeader()}
          <div className={pageClass(this.props.page)} ref={this.pageRef}>
            {(this.props.page !== constClass.PLAN) && this.renderPagetitle()}
            {(this.props.page === undefined || this.props.page === null || this.props.page === '') &&
              <div>
                {this.renderMenu()}
              </div>
            }
            {this.props.page === constClass.PLAN &&
              <div>
                {this.renderPlan()}
                {this.renderReceiveShop(this.state.siteId)} {/*受け取り希望店舗　選択*/}
              </div>
            }
            {this.props.page === constClass.REQUEST &&
              <div>
                {this.renderCondition()}
                {this.renderRequest()}
              </div>
            }
            {this.props.page === constClass.USER &&
              <div>
                {this.renderUser()}
              </div>
            }
            {this.props.page === constClass.CONFIRM &&
              <div>
                {this.renderConfirm()}
              </div>
            }
            {this.props.page === constClass.ORDERFINISH &&
              <div>
                {this.renderThanks()}
              </div>
            }
            {this.props.page === constClass.HISTORY &&
              <div>
                {this.renderOrderSelect()}
              </div>
            }
            {this.props.page === constClass.CHANGE &&
              <div>
                {this.checkOrderChangeLimit() && this.renderChange()}
                {!this.checkOrderChangeLimit() && this.renderReceive()}
              </div>
            }
            {this.props.page === constClass.ORDERUSERCHANGE &&
              <div>
                {this.renderUser()}
              </div>
            }
            {this.props.page === constClass.ORDERSHOPCHANGE &&
              <div>
                {this.renderReceiveShop()}
              </div>
            }
            {this.props.page === constClass.CHANGEFINISH &&
              <div>
                {this.renderThanks()}
              </div>
            }
            {this.props.page === constClass.MEMBERFINISH &&
              <div>
                {this.renderThanks()}
              </div>
            }
            {this.props.page === constClass.RECEIVESELECT &&
              <div>
                {this.renderOrderSelect()}
              </div>
            }
            {this.props.page === constClass.RECEIVE &&
              <div>
                {this.renderReceive()}
              </div>
            }
            {this.props.page === constClass.RECEIVEFINISH &&
              <div>
                {this.renderThanks()}
              </div>
            }
            {this.props.page === constClass.USERCHANGE &&
              <div>
                {this.renderUser()}
              </div>
            }
            {this.props.page === constClass.EMPLOYEE &&
              <div>
                {this.renderEmployee()}
              </div>
            }
            {this.props.page === constClass.PAYMENT &&
              <div>
                {this.renderPayment()}
              </div>
            }
          </div>
          {!(this.props.page === undefined || this.props.page === null || this.props.page === '') && this.renderBottom()}
          {this.state.disabled && <Loading />}
          <Modal isOpen={Common.checkContractPeriod(this.settings) === false} style={this.modalStyle}>
            <div className="text-center section-white m-0 py-2">
              <h4>サービス契約期間外です</h4>
            </div>
          </Modal>
        </div>
          : null
        : this.state.not_liff_flag ? <div className="liff-top bg-lightwhite font-weight-bold">
          <DocumentMeta {...this.meta} />
          {this.renderHeader()}
          <div className="row mx-0">
            <div className="col px-0">
              <div className="row mx-0 my-3 text-center">
                <div className="col px-0">
                  LINEをご利用ください。
                </div>
              </div>
              <div className="row mx-0 px-0-env pt-1 pb-2">
                <div className="col text-center">
                  <button
                    disabled={this.state.disabled}
                    className={`btn-lg btn-enable w-100 py-3 p-env-bottom`}
                    onClick={() => { window.location.replace(Common.getSettingValue(this.settings, 'API_LIFF_URL')); }}>
                    LINEで開く
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
          : (Common.checkOrderPage(this.props.page) || this.props.page === constClass.PAYMENT) ? <Loading /> : null)
  }
}

export default withRouter(Order);