import { Payment } from 'nis-common-frontend';
import React, { useEffect } from 'react';
import Common from '../Common/common';

const PaymentHandler = ({ siteId, lineId, id, amount, fee, backUrl, returnUrl, redirectReturnUrl, checkUrl, abortUrl, backFunc, returnFunc, setBack, setNext, setting }) => {

  useEffect(() => {
    setBack({
      title: '戻る',
      render: false,
      disabled: true,
      onClick: () => { }
    });
    setNext({
      title: '次へ',
      render: false,
      disabled: true,
      onClick: () => { }
    });
  }, [setBack, setNext]);

  return (
    <Payment
      siteId={siteId}
      lineId={lineId}
      id={id}
      amount={amount}
      fee={fee}
      backUrl={backUrl} 
      returnUrl={returnUrl}
      redirectReturnUrl={redirectReturnUrl}
      checkUrl={checkUrl}
      abortUrl={abortUrl}
      backFunc={backFunc}
      returnFunc={backFunc}
      infoMessage={(id && String(id).split('_').length === 1) ? Common.getSettingValue(setting, 'PAYMENT_LIMIT_MESSAGE', '') : ''}
    />
  )
}

export default PaymentHandler;