import React from 'react';
import Common from '../Common/common';

const InputComponent = {};

InputComponent.renderLabel = (label, value) => {
  return <React.Fragment>
    <div className="input-label row mx-0 px-3-env">
      <div className="col px-0">
        <span>{label}</span>
      </div>
    </div>
    <div className="input-body form-group">
      <div className="input-control row mx-0 px-3-env text-left">
        <div className={`col px-3 py-1 align-self-center border`}>{Common.getBrString(value)}</div>
      </div>
    </div>
  </React.Fragment>
}

InputComponent.renderText = (label, value, name, changeHandler, message, placeholder = null, length = null, required = false, prefix = null, suffix = null, blurHandler = null) => {
  return <React.Fragment>
    {label && <div className="input-label row mx-0 px-3-env">
      <div className="col px-0">
        <span>{label}</span>
      </div>
      {required &&
        <div className="col-auto px-0">
          <span className="text-required">必須</span>
        </div>
      }
    </div>
    }
    <div className="input-body form-group">
      <div className="input-control row mx-0 px-3-env text-left">
        {prefix &&
          <div className={`col-auto px-0 align-self-center`}><span>{prefix}</span></div>
        }
        <div className={`col px-0 align-self-center`}><input type={'text'} className="form-control" id={name} name={name} value={value} onChange={changeHandler} placeholder={placeholder} maxLength={length} onBlur={blurHandler}></input></div>
        {suffix &&
          <div className={`col-auto px-0 align-self-center`}><span>{suffix}</span></div>
        }
      </div>
      {message &&
        <div className="input-message row mx-0 px-3-env text-left">
          <div className="col px-0 text-danger font-weight-normal">{Common.getBrString(message)}</div>
        </div>
      }
    </div>
  </React.Fragment>
}

InputComponent.renderDate = (label, value, name, changeHandler, message, placeholder = null, length = null, required = false) => {
  // const setYears = (from, to) => {
  //   const items = [];
  //   const nowYear = new Date().getFullYear();
  //   var range_from = from ? from : nowYear;
  //   var range_to = to ? to : nowYear;
  //   items.push(<option value="" key={"-"}>- 年 -</option>);
  //   if (range_from < range_to) {
  //     for (let i = range_from; i <= range_to; i++) {
  //       items.push(<option value={i} key={i}>{i}年</option>);
  //     }
  //   } else {
  //     for (let i = range_from; i >= range_to; i--) {
  //       items.push(<option value={i} key={i}>{i}年</option>);
  //     }
  //   }
  //   return items;
  // }
  const setMonth = () => {
    const items = [];
    items.push(<option value="" key={"-"}>- 月 -</option>)
    for (let i = 1; i <= 12; i++) {
      items.push(<option value={("00" + i).slice(-2)} key={i}>{i}月</option>)
    }
    return items;
  }
  const setDay = (value) => {
    const items = [];
    const year = value.slice(0, 4);
    const month = value.slice(4, 6);
    items.push(<option value="" key={"-"}>- 日 -</option>)
    for (let i = 1; i <= 31; i++) {
      if (!year || !Number(month) || (new Date(year + '/' + month + '/' + ("00" + i).slice(-2)).getDate() === i)) {
        items.push(<option value={("00" + i).slice(-2)} key={i}>{i}日</option>)
      }
    }
    return items;
  }
  const handleDateChange = (e, changeEvent) => {
    const target = e.target;
    // var temp = Number(document.getElementById(name + '-y') ? document.getElementById(name + '-y').value : 0) * 10000;
    var temp = 0;
    temp += Number(document.getElementById(name + '-m') ? document.getElementById(name + '-m').value : 0) * 100;
    temp += Number(document.getElementById(name + '-d') ? document.getElementById(name + '-d').value : 0);
    changeEvent({
      target: {
        type: 'text',
        name: target.name,
        value: ("00000000" + temp).slice(-8)
      }
    })
  }
  return <React.Fragment>
    {label && <div className="input-label row mx-0 px-3-env">
      <div className="col px-0">
        <span>{label}</span>
      </div>
      {required &&
        <div className="col-auto px-0">
          <span className="text-required">必須</span>
        </div>
      }
    </div>
    }
    <div className="input-body form-group">
      <div className="input-control row mx-0 px-3-env text-left">
        <div className={`col px-0 align-self-center`}>
          <div className="row px-0 mx-0">
            {/* <div className={`col-4 px-0 mx-0 align-self-center ${name + '-y'}`}>
              <select className="form-control text-center" id={name + '-y'} name={name} onChange={(e) => handleDateChange(e, changeHandler)} value={value.slice(0, 4)}>
                {setYears(1900, null)}
              </select>
            </div> */}
            <div className={`col-6 px-1 mx-0 align-self-center ${name + '-m'}`}>
              <select className="form-control text-center" id={name + '-m'} name={name} onChange={(e) => handleDateChange(e, changeHandler)} value={value.slice(4, 6)}>
                {setMonth()}
              </select>
            </div>
            <div className={`col-6 px-1 mx-0 align-self-center ${name + '-d'}`}>
              <select className="form-control text-center" id={name + '-d'} name={name} onChange={(e) => handleDateChange(e, changeHandler)} value={value.slice(6, 8)}>
                {setDay(value)}
              </select>
            </div>
          </div>
        </div>
      </div>
      {message &&
        <div className="input-message row mx-0 px-3-env text-left">
          <div className="col px-0 text-danger font-weight-normal">{Common.getBrString(message)}</div>
        </div>
      }
    </div>
  </React.Fragment>
}

export default InputComponent;