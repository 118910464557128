import { combineReducers, configureStore } from '@reduxjs/toolkit';

import User from '../Slices/User';
import BackyardCondition from '../Slices/BackyardCondition';
import BackyardOrderCondition from '../Slices/BackyardOrderCondition';

const rootReducer = combineReducers({
  User: User.reducer,
  BackyardCondition: BackyardCondition.reducer,
  BackyardOrderCondition: BackyardOrderCondition.reducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;