import { createSlice } from '@reduxjs/toolkit';
import constClass from '../Constants/Constants';

// Stateの初期状態
const initialState = {
  shop: '',
  plan: '',
  receiveDate: null,
  selectReceiveDate: null,
  status: [constClass.ORDER_STATUS.REG/*, constClass.ORDER_STATUS.PRE, constClass.ORDER_STATUS.FIN, constClass.ORDER_STATUS.DFIN, constClass.ORDER_STATUS.CCL*/],
  selectStatus: [
    { value: constClass.ORDER_STATUS.REG, label: constClass.ORDER_STATUS_NAME.REG },
    // { value: constClass.ORDER_STATUS.PRE, label: constClass.ORDER_STATUS_NAME.PRE },
    // { value: constClass.ORDER_STATUS.FIN, label: constClass.ORDER_STATUS_NAME.FIN },
    // { value: constClass.ORDER_STATUS.DFIN, label: constClass.ORDER_STATUS_NAME.DFIN },
    // { value: constClass.ORDER_STATUS.CCL, label: constClass.ORDER_STATUS_NAME.CCL }
  ],
  isReload: true,
  shopMaster: [],
  planMaster: [],
  productMaster: [],
  sortOrder: [],
  index: 3,
  initFlag: false,
  receiptDateStart: null,
  receiptDateEnd: null,
  shippingDate: null,
  customerNo: '',
};

const BackyardOrderCondition = createSlice({
  name: "BackyardOrderCondition",
  initialState: initialState,
  reducers: {
    setShop: (state, action) => ({
      ...state,
      shop: action.payload,
      // isReload: true,
    }),
    setPlan: (state, action) => ({
      ...state,
      plan: action.payload,
      // isReload: true,
    }),
    setSelectReceiveDate: (state, action) => ({
      ...state,
      receiveDate: action.payload ? action.payload.map((item) => item.value) : null,
      selectReceiveDate: action.payload,
      // isReload: true,
    }),
    setSelectStatus: (state, action) => ({
      ...state,
      status: action.payload ? action.payload.map((item) => item.value) : null,
      selectStatus: action.payload,
      // isReload: true,
    }),
    setIsReload: (state, action) => ({
      ...state,
      isReload: action.payload,
    }),
    setShopMaster: (state, action) => ({
      ...state,
      shopMaster: action.payload,
    }),
    setPlanMaster: (state, action) => ({
      ...state,
      planMaster: action.payload,
    }),
    setProductMaster: (state, action) => ({
      ...state,
      productMaster: action.payload,
    }),
    setInitFlag: (state) => ({
      ...state,
      initFlag: true,
    }),
    setSortOrder: (state, action) => ({
      ...state,
      sortOrder: action.payload,
    }),
    setReceiptDateStart: (state, action) => ({
      ...state,
      receiptDateStart: action.payload,
      // isReload: true,
    }),
    setReceiptDateEnd: (state, action) => ({
      ...state,
      receiptDateEnd: action.payload,
      // isReload: true,
    }),
    setShippingDate: (state, action) => ({
      ...state,
      shippingDate: action.payload,
      // isReload: true,
    }),
    setCustomerNo: (state, action) => ({
      ...state,
      customerNo: action.payload,
      // isReload: true,
    }),
    setIndex: (state, action) => ({
      ...state,
      index: action.payload,
      sortOrder: [],
      isReload: true,
    }),
    login: (state) => ({
      ...initialState,
    }),
    logout: (state) => ({
      ...initialState,
    })
  }
});

// 個別でも使えるようにActionCreatorsをエクスポートしておく
export const { setShop, setPlan, setSelectReceiveDate, setSelectStatus, setIsReload, setShopMaster, setPlanMaster, setProductMaster, setInitFlag, setSortOrder, setReceiptDateStart, setReceiptDateEnd, setShippingDate, setCustomerNo, logout } = BackyardOrderCondition.actions;

export default BackyardOrderCondition;