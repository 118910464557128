import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import util from 'util';
import constClass from '../../Constants/Constants';
import { useToasts } from 'react-toast-notifications';
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import ja from 'date-fns/locale/ja';
import moment from 'moment';
// import Select from 'react-select';
import Encoding from 'encoding-japanese';
// import CreatableSelect from 'react-select/creatable';
import ProductList from './ProductList';
import Papa from "papaparse";
import { CSVLink } from "react-csv";
import Common from '../Common/common.js';
import BigNumber from 'bignumber.js';
import Modal from 'react-modal';
import imageCompression from "browser-image-compression";
registerLocale('ja', ja)

const Product = (props) => {
  const { user, conditionActions } = props;
  const [plan, setPlan] = useState({ plan_id: null, product_category_id: null, year_round_type: null, product_past_flag: false });
  // const [limitDate, setLimitDate] = useState({ min: null, max: null });
  // const [shopMaster, setShopMaster] = useState([]);
  const [planMaster, setPlanMaster] = useState([]);
  const [productCategoryMaster, setProductCategoryMaster] = useState([]);
  // const [productDeptMaster, setProductDeptMaster] = useState([]);
  // const [timeMaster, setTimeMaster] = useState([]);
  const [productData, setProductData] = useState(null);
  const [lockData, setLockData] = useState(false);
  const [csvProductData, setCsvProductData] = useState({ list: [], header: [] });
  const [importDisplayData, setImportDisplayData] = useState(null);
  const [importData, setImportData] = useState(null);
  const [errMessage, setErrMessage] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const { addToast } = useToasts();

  const [values, setValues] = useState({
    plan_id: null,
    year_round_type: "0",
    product_id: null,
    product_code: '',
    product_name: '',
    product_category_id: "",
    product_dept_code: constClass.PRODUCT_DEPT_DEFAULT,
    product_detail: '',
    product_image: null,
    price_before_tax: 0,
    price: 0,
    tax_rate: 0.1,
    order_limit: null,
    change_limit: null,
    receipt_limit_days: null,
    change_limit_days: null,
    point: 0,
    point_rate: 0,
    early_discount_rate: 0,
    early_discount_start: null,
    early_discount_end: null,
    stock: 0,
    order_start: null,
    cart_limit: 0,
    shipping_date: null,
    jan_code: '',
    upd_date: null,
    orderLimit: null,
    changeLimit: null,
    earlyDiscountStart: null,
    earlyDiscountEnd: null,
    orderStart: null,
    shippingDate: null,
    vip_flag: constClass.FLAG.OFF,
    notice_flag: constClass.FLAG.OFF,
    shipping_fee_flag: constClass.FLAG.OFF,
    uploadfile: null,
    uploadfiletype: null,
    deletefile: null
  });
  const [ngShop, setNgshopValues] = useState([]);
  // const [ngTime, setNgtimeValues] = useState([]);
  const [receiveDate, setReceiveDateValues] = useState([]);
  const [settings, setSettings] = useState([]);

  const modalConfirmStyle = {
    overlay: {
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: "rgba(0,0,0,0.5)"
    },
    content: {
      left: '10%',
      right: '10%',
      top: '15%',
      bottom: 'auto',
      padding: "0px",
      maxHeight: "80%",
      width: "80%",
      overflowX: "hidden",
      pointerEvents: "auto",
      backgroundClip: "padding-box",
      border: "1px rgba(0,0,0,.2)",
      overflow: "visible",
    }
  };

  const refreshProduct = useCallback(async () => {
    setLockData(true);
    const jwt = localStorage.getItem('jwt');
    const filter = {
      "site_id": props.match.params.siteId,
      "operator": "and",
      "where": [{ "site_id": props.match.params.siteId }]
    }
    const planPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/plan/search/`, filter, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      }
    });
    const settingPromise = axios.get(`${process.env.REACT_APP_BACKEND_URL}/setting/${props.match.params.siteId}`);
    const categoryPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/productcategory/search/`, filter, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      }
    });
    const plandata = (await planPromise).data;
    setPlanMaster(plandata);
    const settingData = (await settingPromise).data;
    setSettings(settingData);
    // const shop = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/shop/search/`, filter, {
    //   headers: {
    //     Authorization: `Bearer ${jwt}`,
    //   }
    // })).data.map((val) => { return { value: val.shop_id, label: val.shop_name }; });
    // setShopMaster(shop);
    const productCategory = (await categoryPromise).data;
    setProductCategoryMaster(productCategory);
    // const productdept = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/productdept/search/`, filter, {
    //   headers: {
    //     Authorization: `Bearer ${jwt}`,
    //   }
    // })).data;
    // setProductDeptMaster(productdept);
    // const time = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/receive_time/search/`, filter, {
    //   headers: {
    //     Authorization: `Bearer ${jwt}`,
    //   }
    // })).data.map((val) => { return { value: val.time_id, label: val.time_name }; });
    // setTimeMaster(time);
    var reg_params = {
      "site_id": props.match.params.siteId,
      "operator": "and",
      "where": [{ "site_id": props.match.params.siteId }]
    }
    // if (plan.plan_id === 0 && plandata.length > 0) { //企画が複数あったら、開催中企画を表示。なければ最新企画を表示
    //   const nowdate = moment().format("YYYYMMDD");
    //   const nowplan = plandata.find((item) => item.plan_start <= nowdate && item.plan_end >= nowdate);
    //   const defaultplan = nowplan ? nowplan : plandata.slice(-1)[0];
    //   setPlan({ ...plan, plan_id: defaultplan.plan_id, year_round_type: defaultplan.year_round_type });
    //   productReset({ ...plan, plan_id: defaultplan.plan_id, year_round_type: defaultplan.year_round_type })
    //   return;
    // }
    if (plan.plan_id !== null && plan.plan_id !== '') {
      reg_params.where.push({ "plan_id": plan.plan_id });
    }
    if (plan.product_category_id !== null && plan.product_category_id !== '') {
      reg_params.where.push({ "product_category_id": plan.product_category_id });
    }
    if (!plan.product_past_flag) {
      reg_params.where.push({
        operator: "or",
        where: [
          {
            operator: "gte",
            attr: "shipping_date",
            val: moment().format("YYYYMMDD")
          },
          {
            operator: "gte",
            attr: "order_limit",
            val: moment().format("YYYYMMDD")
          },
        ]
      });
    }
    const data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/product/search/`, reg_params, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      }
    })).data;
    // data.sort((a, b) => a.product_id - b.product_id);  // 昇順ソート
    setProductData(data);
    setNgshopValues([]);
    // setNgtimeValues([]);
    setReceiveDateValues([]);
    // setLimitDate({ min: null, max: null });
    createCsvdata(data);
    setLockData(false);
  }, [plan, props.match.params.siteId]);

  const strToDate = (date) => {
    if (!date) return null;
    return new Date(date.substr(0, 4) + '/' + date.substr(4, 2) + '/' + date.substr(6, 2));
  }

  const dateToStore = (date) => {
    if (date === null) return "";
    return moment(date).format("YYYYMMDD");;
  }

  const createCsvdata = (data) => {
    setLockData(true);
    setCsvProductData(null);
    const list = [];
    const head = [];
    head.push({ label: "種別", key: "plan_name" });
    head.push({ label: "商品コード", key: "product_code" });
    head.push({ label: "商品名", key: "product_name" });
    head.push({ label: "商品カテゴリID", key: "product_category_id" });
    head.push({ label: "商品詳細", key: "product_detail" });
    head.push({ label: "税込単価", key: "price" });
    head.push({ label: "税別単価", key: "price_before_tax" });
    head.push({ label: "税率", key: "tax_rate" });
    head.push({ label: "送料が必要", key: "shipping_fee_flag" });
    head.push({ label: "注文開始日", key: "order_start" });
    head.push({ label: "締め切り日", key: "order_limit" });
    head.push({ label: "発送予定日", key: "shipping_date" });
    head.push({ label: "在庫数", key: "stock" });
    head.push({ label: "1人あたり注文上限数", key: "cart_limit" });
    head.push({ label: "VIP限定", key: "vip_flag" });
    head.push({ label: "備考入力", key: "notice_flag" });
    data.forEach(p => {
      var product = {
        plan_id: p.plan_id,
        plan_name: p.plan_name,
        product_code: p.product_code,
        product_name: p.product_name.replace(/"/g, '""'),
        product_category_id: p.product_category_id,
        product_detail: p.product_detail.replace(/"/g, '""'),
        price_before_tax: p.price_before_tax,
        price: p.price,
        tax_rate: p.tax_rate,
        shipping_fee_flag: p.shipping_fee_flag === constClass.FLAG.ON ? p.shipping_fee_flag : constClass.FLAG.OFF,
        order_start: p.order_start,
        order_limit: p.order_limit,
        shipping_date: p.shipping_date,
        stock: p.stock,
        cart_limit: p.cart_limit,
        vip_flag: p.vip_flag === constClass.FLAG.ON ? p.vip_flag : constClass.FLAG.OFF,
        notice_flag: p.notice_flag === constClass.FLAG.ON ? p.notice_flag : constClass.FLAG.OFF,
      };
      list.push(product);
    });
    setCsvProductData({ header: head, list: list });
  }

  // ファイルdata
  const handleFile = async (e) => {
    const value = e.target.files[0];
    var reader = new FileReader();
    reader.onloadend = () => {
      const codes = new Uint8Array(reader.result);
      const encoding = Encoding.detect(codes);
      const csv = Encoding.convert(codes, {
        to: 'UNICODE',
        from: encoding,
        type: 'string',
      });
      var List = []; // 登録データ
      var ErrBox = []; // エラーデータ
      var check = null; // チェックデータ
      var errflag = false; // エラーフラグ
      var errflagDate = false; // date型エラーフラグ
      var errflagNumber = false; // 数値型エラーフラグ
      // var errflagEarly = false; // 早割りエラーフラグ
      var errflagCategory = false; //カテゴリエラーフラグ
      // var errflagDept = false; //商品デプトエラーフラグ
      var errflagTax = false; //税率エラーフラグ
      var errflagPlan = false; //企画IDエラーフラグ
      // var errflagPoint = false; //ポイント還元率エラーフラグ
      // var errflagNgShop = false; //受取不可店舗ID重複エラーフラグ
      // var errflagShop = false; //受取不可店舗IDエラーフラグ
      // var errflagReceive = false; //受取可能日重複エラーフラグ
      // var errflagReceiveDate = false; //受取可能日date型エラーフラグ
      var errflagProductCode = false; //商品コードエラーフラグ
      var errflagCheckbox = false; //チェックボックスエラーフラグ
      var errflagDateCheck = false; // 日付整合性エラーフラグ
      var dataflag = false; // データフラグ
      var filename = value.name; //ファイル名

      if (filename.slice((filename.lastIndexOf('.')) + 1) !== "csv") { // ファイル形式チェック
        window.alert("csv形式のファイルをアップロードしてください");
        return;
      }

      const imp = Papa.parse(csv, { skipEmptyLines: true }).data;

      try {
        imp.forEach((row, rowIdx) => {
          if (rowIdx <= 0) {
            return;
          }
          check = {
            "plan_name": row[0].replace(/"/g, '').trim(),
            "product_code": row[1].replace(/"/g, '').trim(),
            "product_name": row[2].replace(/"/g, '').trim(),
            "product_category_id": row[3].replace(/"/g, '').trim(),
            "product_detail": row[4].replace(/"/g, '').trim(),
            "price": row[5].replace(/"/g, '').trim(),
            "price_before_tax": row[6].replace(/"/g, '').trim(),
            "tax_rate": row[7].replace(/"/g, '').trim(),
            "shipping_fee_flag": row[8].replace(/"/g, '').trim(),
            "order_start": row[9].replace(/"/g, '').trim(),
            "order_limit": row[10].replace(/"/g, '').trim(),
            "shipping_date": row[11].replace(/"/g, '').trim(),
            "stock": row[12].replace(/"/g, '').trim(),
            "cart_limit": row[13].replace(/"/g, '').trim(),
            "vip_flag": row[14].replace(/"/g, '').trim(),
            "notice_flag": row[15].replace(/"/g, '').trim(),
          };
          check = {
            ...check,
            cart_limit: check.cart_limit ? Number(check.cart_limit) : 0,
            shipping_fee_flag: check.shipping_fee_flag ? check.shipping_fee_flag : constClass.FLAG.OFF,
            vip_flag: check.vip_flag ? check.vip_flag : constClass.FLAG.OFF,
            notice_flag: check.notice_flag ? check.notice_flag : constClass.FLAG.OFF,
          }

          var order_start_date = check.order_start ? new Date(moment(check.order_start).format('YYYY/MM/DD')) : null;
          var order_limit_date = check.order_limit ? new Date(moment(check.order_limit).format('YYYY/MM/DD')) : null;
          var shipping_date_date = check.shipping_date ? new Date(moment(check.shipping_date).format('YYYY/MM/DD')) : null;

          var plan_names = planMaster.map(data => data.plan_name);
          var product_category_ids = productCategoryMaster.map(data => data.product_category_id);

          // データチェック
          if (!check.plan_name) {
            errflag = true;
            ErrBox.push(rowIdx + '行目：種別を入力してください');
          }
          if (!check.product_code) {
            errflag = true;
            ErrBox.push(rowIdx + '行目：商品コードを入力してください');
          }
          if (!check.product_name) {
            errflag = true;
            ErrBox.push(rowIdx + '行目：商品名を入力してください');
          }
          if (!check.product_category_id) {
            errflag = true;
            ErrBox.push(rowIdx + '行目：商品カテゴリIDを入力してください');
          }
          if (!check.price) {
            errflag = true;
            ErrBox.push(rowIdx + '行目：税込単価を入力してください');
          }
          if (!check.price_before_tax) {
            errflag = true;
            ErrBox.push(rowIdx + '行目：税別単価を入力してください');
          }
          if (!check.tax_rate) {
            errflag = true;
            ErrBox.push(rowIdx + '行目：税率を入力してください');
          }
          if (!check.order_start) {
            errflag = true;
            ErrBox.push(rowIdx + '行目：注文開始日を入力してください');
          }
          if (!check.order_limit) {
            errflag = true;
            ErrBox.push(rowIdx + '行目：締め切り日を入力してください');
          }

          if (!plan_names.includes(check.plan_name)) {
            errflagPlan = true;
            ErrBox.push(rowIdx + '行目：種別の値が正しくありません');
          } else {
            var plan = planMaster.find((data) => data.plan_name === check.plan_name) ? planMaster.find((data) => data.plan_name === check.plan_name) : {};
            check.plan_id = plan.plan_id;
            if (plan.year_round_type === "1") {
              // if (!check.receipt_limit_days) {
              //   errflag = true;
              //   ErrBox.push(rowIdx + '行目：受取可能日数を入力してください');
              // }
              // if (!check.change_limit_days) {
              //   errflag = true;
              //   ErrBox.push(rowIdx + '行目：変更可能日数を入力してください');
              // }
              // if (isNaN(check.receipt_limit_days) || isNaN(check.change_limit_days)) {
              //   errflagNumber = true;
              //   ErrBox.push(rowIdx + '行目：数値の形式が正しくありません');
              // } else {
              //   if (check.receipt_limit_days > check.change_limit_days) {
              //     if (!window.confirm('受取可能日数と変更可能日数が逆転しています。登録してよろしいですか？')) {
              //       ErrBox.push(rowIdx + '行目：受取可能日数と変更可能日数が逆転しています');
              //     }
              //   }
              // }
              check.stock = Number(check.stock);
            } else {
              // if (!check.change_limit) {
              //   errflag = true;
              //   ErrBox.push(rowIdx + '行目：変更可能日を入力してください');
              // }
              if (!check.stock) {
                errflag = true;
                ErrBox.push(rowIdx + '行目：在庫数を入力してください');
              } else {
                check.stock = Number(check.stock);
              }
              if (Number(check.stock) <= 0) {
                ErrBox.push(rowIdx + '行目：在庫は1以上の値を入力してください');
              }
            }
          }
          // receive_date_all.forEach((date) => {
          //   if (date && isNaN(date.getDate())) {
          //     errflagReceiveDate = true;
          //     ErrBox.push(rowIdx + '行目：受取可能日の形式が正しくありません');
          //   }
          // })
          // if (new Set(receive_date_all).size !== receive_date_all.length) {
          //   errflagReceive = true;
          //   ErrBox.push(rowIdx + '行目：受取可能日が重複しています');
          // }
          // if (isNaN(check.stock)) {
          //   errflag = true;
          //   ErrBox.push(rowIdx + '行目：在庫数を入力してください');
          // }
          if ((order_limit_date !== null && isNaN(order_limit_date.getDate())) || (order_start_date !== null && isNaN(order_start_date.getDate()))
            || (shipping_date_date !== null && isNaN(shipping_date_date.getDate()))) { //date型チェック
            errflagDate = true;
            ErrBox.push(rowIdx + '行目：日付の形式が正しくありません');
          }
          if ((check.price_before_tax !== null && isNaN(check.price_before_tax)) || (check.price !== null && isNaN(check.price))
            || (check.stock !== null && isNaN(check.stock)) || (check.cart_limit !== null && isNaN(check.cart_limit))) { //数値型チェック
            errflagNumber = true;
            ErrBox.push(rowIdx + '行目：数値の形式が正しくありません');
          }
          if (check.tax_rate && (isNaN(check.tax_rate) || !(check.tax_rate >= 0 && check.tax_rate < 1))) {//税率チェック
            errflagTax = true;
            ErrBox.push(rowIdx + '行目：税率は0以上1未満で入力してください');
          }
          if (![constClass.FLAG.ON, constClass.FLAG.OFF].includes(check.shipping_fee_flag)) {//送料が必要チェック
            errflagCheckbox = true;
            ErrBox.push(rowIdx + '行目：送料が必要な場合は1,不要な場合は0で入力してください');
          }
          if (![constClass.FLAG.ON, constClass.FLAG.OFF].includes(check.vip_flag)) {//送料が必要チェック
            errflagCheckbox = true;
            ErrBox.push(rowIdx + '行目：VIP会員限定の場合は1,そうでない場合は0で入力してください');
          }
          if (![constClass.FLAG.ON, constClass.FLAG.OFF].includes(check.notice_flag)) {//送料が必要チェック
            errflagCheckbox = true;
            ErrBox.push(rowIdx + '行目：備考入力が必要な場合は1,そうでない場合は0で入力してください');
          }
          // if (check.early_discount_rate && (isNaN(check.early_discount_rate) || !(check.early_discount_rate >= 0 && check.early_discount_rate < 1))) {//早割りチェック
          //   errflagEarly = true;
          //   ErrBox.push(rowIdx + '行目：早割割引率は0以上1未満で入力してください');
          // }
          // if (check.point_rate && (isNaN(check.point_rate) || !(check.point_rate >= 0 && check.point_rate < 1))) {//ポイント付与率チェック
          //   errflagPoint = true;
          //   ErrBox.push(rowIdx + '行目：ポイント付与率は0以上1未満で入力してください');
          // }
          if (!product_category_ids.includes(Number(check.product_category_id))) {
            errflagCategory = true;
            ErrBox.push(rowIdx + '行目：商品カテゴリIDの値が正しくありません');
          }
          // if (check.product_dept_code && !product_dept_code.includes(Number(check.product_dept_code))) {
          //   errflagDept = true;
          //   ErrBox.push(rowIdx + '行目：商品デプトの値が正しくありません');
          // }
          // shop_data.forEach((data) => {
          //   if (data && !shop_id.includes(Number(data))) {
          //     errflagShop = true;
          //     ErrBox.push(rowIdx + '行目：受取不可店舗IDの値が正しくありません');
          //   }
          // })
          // if (new Set(shop_data).size !== shop_data.length) {
          //   errflagNgShop = true;
          //   ErrBox.push(rowIdx + '行目：受取不可店舗IDが重複しています');
          // }
          // receive_ng_time_data.forEach((data) => {
          //   if (data && !time_id.includes(Number(data))) {
          //     errflagNgTime = true;
          //     ErrBox.push(rowIdx + '行目：受取不可時間帯IDの値が正しくありません');
          //   }
          // })
          // if (new Set(receive_ng_time_data).size !== receive_ng_time_data.length) {
          //   errflagNgTime = true;
          //   ErrBox.push(rowIdx + '行目：受取不可時間帯IDが重複しています');
          // }

          if (List.find(data => Number(data.plan_id) === Number(check.plan_id) && String(data.product_code) === String(check.product_code))) {
            ErrBox.push(rowIdx + '行目：１つの種別に同じ商品コードを登録することはできません');
            errflagProductCode = true;
          }

          if (order_start_date > order_limit_date) {
            ErrBox.push(rowIdx + '行目：注文開始日と締め切り日が逆転しています');
            errflagDateCheck = true;
          }

          if (check.shipping_date && order_limit_date > shipping_date_date) {
            ErrBox.push(rowIdx + '行目：締め切り日と発送予定日が逆転しています');
            errflagDateCheck = true;
          }

          dataflag = true;
          if (errflag === false) {
            List.push(check);
          }
        });
      } catch (e) {
        console.log(e);
        window.alert("ファイルのデータ内容が不正です");
        return;
      }

      // エラーメッセージ
      if (dataflag === false) {
        ErrBox.push("取り込むデータがありません");
        window.alert("取り込むデータがありません");
      }
      if (errflag === true) {
        window.alert("取込エラー");
      }
      if (errflagDate === true) {
        window.alert("日付の形式が正しくありません");
      }
      if (errflagNumber === true) {
        window.alert("数値の形式が正しくありません");
      }
      if (errflagTax === true) {
        window.alert("税率は0以上1未満で入力してください");
      }
      // if (errflagEarly === true) {
      //   window.alert("早割割引率は0以上1未満で入力してください");
      // }
      // if (errflagPoint === true) {
      //   window.alert("ポイント付与率は0以上1未満で入力してください");
      // }
      if (errflagPlan === true) {
        window.alert("種別の値が正しくありません");
      }
      if (errflagCategory === true) {
        window.alert("商品カテゴリIDの値が正しくありません");
      }
      // if (errflagDept === true) {
      //   window.alert("商品デプトの値が正しくありません");
      // }
      // if (errflagShop === true) {
      //   window.alert("受取不可店舗IDの値が正しくありません");
      // }
      // if (errflagNgShop === true) {
      //   window.alert("受取不可店舗IDが重複しています");
      // }
      // if (errflagReceiveDate === true) {
      //   window.alert("受取可能日の形式が正しくありません");
      // }
      // if (errflagReceive === true) {
      //   window.alert("受取可能日が重複しています");
      // }
      // if (errflagNgTime === true) {
      //   window.alert("受取不可時間帯IDの値が正しくありません");
      // }
      if (errflagProductCode === true) {
        window.alert("１つの種別に同じ商品コードを登録することはできません");
      }
      if (errflagCheckbox === true) {
        window.alert("チェックボックス形式の値が正しくありません");
      }
      if (errflagDateCheck === true) {
        window.alert("日付が逆転しています");
      }
      imp.shift();//１行目を削除
      setImportDisplayData(imp);
      setImportData(List);
      setErrMessage(ErrBox);
    }
    reader.readAsArrayBuffer(value);
  }

  // // CSVデータ反映：処理
  const handleExecClick = async () => {
    const jwt = localStorage.getItem('jwt');
    const params = {
      site_id: props.match.params.siteId,
    };
    try {
      var product_data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/product/search/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      })).data;
    } catch (err) {
      console.log(err);
      if (err.response.data) {
        return addToast(err.response.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        return addToast(err.message, { appearance: 'error', autoDismiss: true });
      }
    }

    let res;
    setLockData(true);
    var import_data = [];

    importData.forEach((data) => {
      var product = product_data.find((p) => Number(p.plan_id) === Number(data.plan_id) && String(p.product_code) === String(data.product_code));
      // var date_ary = data.receive_date !== "" ? data.receive_date.replace(/'/g, '').split(',') : "";
      // var shop_ary = data.shop_id !== "" ? data.shop_id.split(',') : "";
      // var time_ary = (Common.getSettingValue(settings, 'USE_RECEIVE_NG_TIME', "0") === "1" && data.receive_ng_time !== "") ? data.receive_ng_time.split(',') : "";
      var date_ary = [];
      var shop_ary = [];
      var time_ary = [];
      var add_data = {
        site_id: props.match.params.siteId,
        plan_id: data.plan_id,
        product_id: product ? product.product_id : "",
        product_code: data.product_code,
        product_name: data.product_name,
        product_category_id: data.product_category_id,
        product_detail: data.product_detail,
        price_before_tax: data.price_before_tax,
        product_dept_code: constClass.PRODUCT_DEPT_DEFAULT,
        price: data.price,
        tax_rate: data.tax_rate,
        order_start: data.order_start,
        order_limit: data.order_limit,
        change_limit: '',
        receipt_limit_days: 0,
        change_limit_days: 0,
        point: 0,
        point_rate: 0,
        early_discount_rate: 0,
        early_discount_start: '',
        early_discount_end: '',
        stock: data.stock,
        shipping_date: data.shipping_date,
        shipping_fee_flag: data.shipping_fee_flag,
        vip_flag: data.vip_flag,
        notice_flag: data.notice_flag,
        cart_limit: data.cart_limit,
        jan_code: '',
        ins_name: user.userId,
        upd_name: user.userId,
      };
      add_data.m_product_receive = date_ary.length > 0 ? date_ary.map((val) => { return { product_id: add_data.product_id, receive_date: Number(val) } }) : [];
      add_data.m_ng_shop = shop_ary.length > 0 ? shop_ary.map((val) => { return { product_id: add_data.product_id, shop_id: Number(val) } }) : [];
      add_data.m_receive_ng_time = time_ary.length > 0 ? time_ary.map((val) => { return { product_id: add_data.product_id, time_id: Number(val) } }) : [];
      if (planMaster.find(data => Number(data.plan_id) === Number(add_data.plan_id)).year_round_type === "1") {
        add_data.change_limit = 99991231;
        if (!add_data.order_limit) data.order_limit = 99991231;
      } else {
        add_data.receipt_limit_days = 0;
        add_data.change_limit_days = 0;
      }
      if (add_data.point === "") { // ポイント付与数value値チェック
        add_data.point = 0;
      }
      if (add_data.point_rate === "") { // ポイント付与率value値チェック
        add_data.point_rate = 0;
      }
      if (add_data.early_discount_rate === "") { // 早割割引率value値チェック
        add_data.early_discount_rate = 0;
      }
      import_data.push(add_data);
    });
    try {
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/product/import/${props.match.params.siteId}`, import_data, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      });
    } catch (err) {
      console.log(err);
      if (err.response.data) {
        addToast(err.response.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.message, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      console.log(res);
      if (!res) {
      } else if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        setConfirm(false);
        addToast('登録しました。', { appearance: 'success', autoDismiss: true });
        await refreshProduct();
        setImportDisplayData(null);
      }
      setLockData(false);
      productReset(plan);
      conditionActions.logout();
    }
  }

  // CSVデータインポート
  const clearImportData = () => {
    setImportDisplayData(null);
    setImportData(null);
    setErrMessage(null);
    setConfirm(false);
  }

  // CSV戻るボタン
  const handleBackClick = () => {
    setImportDisplayData(null);
    setImportData(null);
    setErrMessage(null);
  }


  // 編集ボタン：処理
  const handleProductUpdateClick = (data) => {
    var data2 = data;
    data2.orderLimit = strToDate(data.order_limit);
    data2.changeLimit = strToDate(data.change_limit);
    data2.earlyDiscountStart = strToDate(data.early_discount_start);
    data2.earlyDiscountEnd = strToDate(data.early_discount_end);
    data2.orderStart = strToDate(data.order_start);
    data2.shippingDate = strToDate(data.shipping_date);
    data2.deletefile = null;
    data2.uploadfiletype = null;
    data2.jan_code = data2.jan_code ? data2.jan_code : '';
    if (data.product_image != null) data2.uploadfile = '/' + props.match.params.siteId + constClass.PRODUCT_IMAGE_DIR + data.product_image;
    else data2.uploadfile = null;
    setValues(data2);

    var data3 = data.m_product_receive.map((a) => {
      return { value: a.receive_date, label: a.receive_date };
    });
    setReceiveDateValues(data3);

    var data4 = data.m_ng_shop.map((a) => {
      return { value: a.shop_id, label: a.ng_shop.shop_name };
    });
    setNgshopValues(data4);

    // const data5 = planMaster.find(p => p.plan_id.toString() === data.plan_id.toString());
    // if (data5) {
    //   setLimitDate({ ...limitDate, min: strToDate(data5.plan_start), max: strToDate(data5.plan_end) });
    // }
    // var data6 = data.m_receive_ng_time.map((a) => {
    //   return { value: a.time_id, label: timeMaster.find(x => x.value.toString() === a.time_id.toString()).label };
    // });
    // setNgtimeValues(data6);
  }

  // リセット処理
  const productReset = (
    plan = {
      plan_id: '',
      year_round_type: "0",
      product_category_id: "0"
    }
  ) => {
    setValues({
      plan_id: plan.plan_id,
      year_round_type: plan.year_round_type,
      product_id: null,
      product_code: '',
      product_name: '',
      product_category_id: plan.product_category_id,
      product_dept_code: constClass.PRODUCT_DEPT_DEFAULT,
      product_detail: '',
      product_image: null,
      price_before_tax: 0,
      price: 0,
      tax_rate: 0.1,
      order_limit: null,
      change_limit: null,
      receipt_limit_days: null,
      change_limit_days: null,
      point: 0,
      point_rate: 0,
      early_discount_rate: 0,
      early_discount_start: null,
      early_discount_end: null,
      stock: 0,
      order_start: null,
      cart_limit: 0,
      shipping_date: null,
      jan_code: '',
      upd_date: null,
      m_product_receive: [],
      m_ng_shop: [],
      orderLimit: null,
      changeLimit: null,
      earlyDiscountStart: null,
      earlyDiscountEnd: null,
      orderStart: null,
      shippingDate: null,
      vip_flag: constClass.FLAG.OFF,
      notice_flag: constClass.FLAG.OFF,
      shipping_fee_flag: constClass.FLAG.OFF,
      uploadfile: null,
      uploadfiletype: null,
      deletefile: null
    });
    setNgshopValues([]);
    // setNgtimeValues([]);
    setReceiveDateValues([]);
    // setLimitDate({ min: null, max: null });
  }

  // 登録ボタン：処理
  const handleProductRegistClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    if (!data.plan_id) {
      window.alert('種別を選択してください。');
      return;
    }
    if (!data.product_code) {
      window.alert('商品コードを入力してください。');
      return;
    }
    if (!data.product_name) {
      window.alert('商品名を入力してください。');
      return;
    }
    if (!data.product_category_id) {
      window.alert('カテゴリを選択してください。');
      return;
    }
    // if (!data.product_dept_code) {
    //   window.alert('商品デプトを入力してください。');
    //   return;
    // }
    if (!data.price) {
      window.alert('金額(税込)を入力してください。');
      return;
    }
    if (!data.price_before_tax) {
      window.alert('金額(税別)を入力してください。');
      return;
    }
    if (!data.tax_rate) {
      window.alert('税率を入力してください。');
      return;
    }
    if (data.year_round_type === "1") {
      // if (!data.receipt_limit_days && data.receipt_limit_days !== 0) {
      //   window.alert('受取可能日数を入力してください。');
      //   return;
      // }
      // if (!data.change_limit_days && data.change_limit_days !== 0) {
      //   window.alert('変更可能日数を入力してください。');
      //   return;
      // }
      // if (parseInt(data.receipt_limit_days) < parseInt(data.change_limit_days)) {
      //   if (!window.confirm(`受取可能日数と変更可能日数が逆転しています。登録してよろしいですか？`)) {
      //     return;
      //   }
      // }
      if (data.stock && isNaN(Number(data.stock))) {
        window.alert('在庫数を正しく入力してください。');
        return;
      }
      data.changeLimit = new Date('9999/12/31');
      if (!data.orderLimit) data.orderLimit = new Date('9999/12/31');
    } else {
      if (!data.orderLimit) {
        window.alert('締め切り日を入力してください。');
        return;
      }
      // if (!data.changeLimit) {
      //   window.alert('変更可能日を入力してください。');
      //   return;
      // }
      if (!data.stock || isNaN(Number(data.stock)) || Number(data.stock) === 0) {
        window.alert('在庫数を入力してください。');
        return;
      }
      if (data.point === "") { // ポイント付与数value値チェック
        data.point = 0;
      }
      if (data.point_rate === "") { // ポイント付与率value値チェック
        data.point_rate = 0;
      }
      data.early_discount_rate = data.early_discount_rate ? data.early_discount_rate.replace(/\s/g, "") : "";
      if (data.early_discount_rate === "") { // 早割割引率value値チェック
        data.early_discount_rate = 0;
      }
      if (isNaN(data.early_discount_rate) || !(data.early_discount_rate >= 0 && data.early_discount_rate < 1)) {
        window.alert('早割割引率は0以上1未満で入力してください!');
        return;
      }
      data.receipt_limit_days = 0;
      data.change_limit_days = 0;
    }
    var params = data;
    params.order_limit = dateToStore(data.orderLimit);
    params.change_limit = dateToStore(data.changeLimit);
    params.early_discount_start = dateToStore(data.earlyDiscountStart);
    params.early_discount_end = dateToStore(data.earlyDiscountEnd);
    params.order_start = dateToStore(data.orderStart);
    params.shipping_date = dateToStore(data.shippingDate);
    if (data.uploadfiletype === null) data.uploadfile = null;
    params.m_ng_shop = ngShop.map((a) => {
      return { product_id: params.product_id, shop_id: a.value };
    });
    params.m_product_receive = receiveDate.map((a) => {
      return { product_id: params.product_id, receive_date: a.value };
    });
    // params.m_receive_ng_time = ngTime.map((a) => {
    //   return { product_id: params.product_id, time_id: a.value };
    // });
    params.m_receive_ng_time = [];
    params.stock = (data.stock && !isNaN(Number(data.stock))) ? Number(data.stock) : 0;
    params.cart_limit = (data.cart_limit && !isNaN(Number(data.cart_limit))) ? Number(data.cart_limit) : 0;
    params.ins_name = user.userId;
    params.upd_name = user.userId;

    let res;
    try {
      setLockData(true);
      if (params.product_id === null || params.product_id === "") { /* 新規登録 */
        res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/product/${props.match.params.siteId}`, params, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        });
      } else {                                                      /* 編集 */
        res = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/product/${props.match.params.siteId}/${params.product_id}`, params, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        });
      }
    } catch (err) {
      console.log(err);
      if (err.response.data !== null) {
        addToast(err.response.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (!res) {
      } else if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('登録しました。', { appearance: 'success', autoDismiss: true });
        await refreshProduct();
      }
      setLockData(false);
      //入力項目リセット
      productReset(plan);
      conditionActions.logout();
    }
  }

  // 削除ボタン：処理
  const handleProductDeleteClick = async (data) => {
    const jwt = localStorage.getItem('jwt');

    if (!window.confirm(`商品[${data.product_id}:${data.product_name}]を削除します。よろしいですか？`)) {
      return;
    }
    const params = {
      product_id: data.product_id,
      upd_date: data.upd_date
    };
    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/product/delete/${props.match.params.siteId}/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true })
          ;
      } else {
        addToast('削除しました。', { appearance: 'success', autoDismiss: true });
        await refreshProduct();
      }
      setLockData(false);
      productReset();
      conditionActions.logout();
    }
  }

  // 編集ボタン
  const renderUpdateButton = (data) => {
    return (
      <button
        className={`btn btn-primary mx-1`}
        disabled={lockData}
        onClick={() => handleProductUpdateClick(data)}>
        編集
      </button>
    )
  }

  // 登録ボタン
  const renderRegistButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || data.plan_id === null}
        className={`btn btn-primary mx-1 w-50`}
        onClick={() => handleProductRegistClick(data)}>
        登録
      </button>
    )
  }

  // 削除ボタン
  const renderDeleteButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || data.product_id === null}
        className={`btn btn-primary mx-1 w-50`}
        onClick={() => handleProductDeleteClick(data)}>
        削除
      </button>
    )
  }

  // リセットボタン
  const renderResetButton = () => {
    return (
      <button type="button"
        disabled={false}
        className={`btn btn-primary mx-1`}
        onClick={() => productReset(plan)}>
        リセット
      </button>
    )
  }


  // const handleChangeReviceDate = (data) => {
  //   setReceiveDateValues(data);
  // }

  // ～日付系変更イベント
  const handleChangeDate = (name, value) => {
    setValues({ ...values, [name]: value });
  }

  // value値変更イベント
  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? (target.checked ? constClass.FLAG.ON : constClass.FLAG.OFF) : target.value;
    const name = target.name;
    var data = { ...values, [name]: value };
    if (name === "plan_id") {
      const data2 = planMaster.find(p => p.plan_id.toString() === value.toString());
      if (data2) {
        data = { ...data, year_round_type: data2.year_round_type };
        // setLimitDate({ ...limitDate, min: strToDate(data2.plan_start), max: strToDate(data2.plan_end) });
      }
    }
    if (name === "price" || name === "price_before_tax" || name === "tax_rate") {
      if (data.tax_rate === '') {
        data.tax_rate = 0;
      }
      var tax;
      const choosetax = Common.getSettingValue(settings, 'TAX_INCLUDED_FLAG', constClass.TAX_INCLUDED_FLAG.OUTTAX);
      const round = Common.getSettingValue(settings, 'TAX_ROUNDING_FLAG', constClass.TAX_ROUNDING_FLAG.ROUNDDOWN);
      if (name === "price") {
        tax = BigNumber(value).minus(BigNumber(value).div(BigNumber(values.tax_rate).plus(1)));
      } else if (name === "price_before_tax") {
        tax = BigNumber(value).times(values.tax_rate);
      } else if (name === "tax_rate") {
        if (choosetax === constClass.TAX_INCLUDED_FLAG.INTAX) {
          tax = BigNumber(values.price).minus(BigNumber(values.price).div(BigNumber(value).plus(1)));
        } else {
          tax = BigNumber(values.price_before_tax).times(value);
        }
      }
      if (round === constClass.TAX_ROUNDING_FLAG.ROUNDUP) {
        // 切り上げ
        tax = BigNumber(tax).dp(2, BigNumber.ROUND_UP);
      } else if (round === constClass.TAX_ROUNDING_FLAG.ROUNDHALFUP) {
        // 四捨五入
        tax = BigNumber(tax).dp(2, BigNumber.ROUND_HALF_UP);
      } else if (round === constClass.TAX_ROUNDING_FLAG.ROUNDDOWN) {
        // 切り捨て
        tax = BigNumber(tax).dp(2, BigNumber.ROUND_DOWN);
      }
      var result;
      if (name === "price") {
        result = BigNumber(value).minus(tax);
        data = { ...data, price_before_tax: value === '' ? '' : result };
      } else if (name === "price_before_tax") {
        result = BigNumber(value).plus(tax);
        data = { ...data, price: value === '' ? '' : result };
      } else if (name === "tax_rate") {
        if (choosetax === constClass.TAX_INCLUDED_FLAG.INTAX) {
          result = BigNumber(values.price).minus(tax);
          data = { ...data, price_before_tax: values.price === '' || value === '' ? values.price : result };
        } else {
          result = BigNumber(values.price_before_tax).plus(tax);
          data = { ...data, price: values.price_before_tax === '' || value === '' ? values.price_before_tax : result };
        }
      }
    }
    setValues(data);
  }

  // 「企画」条件変更イベント
  const handleChangePlan = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? (target.checked ? constClass.FLAG.ON : constClass.FLAG.OFF) : target.value;
    const name = target.name;
    const selectPlan = planMaster.find(p => p.plan_id.toString() === value.toString());
    const p = {
      ...plan,
      [name]: value,
      year_round_type: selectPlan ? selectPlan.year_round_type : plan.year_round_type
    };
    setPlan(p);
    //入力項目リセット
    productReset(p);
  }

  // 「商品カテゴリ」条件変更イベント
  const handleChangeProductCategory = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    const p = { ...plan, [name]: value };
    setPlan(p);
    //入力項目リセット
    productReset(p);
  }

  // 「商品カテゴリ」条件変更イベント
  const handleChangePastFlag = (e) => {
    const target = e.target;
    const value = target.checked ? true : false;
    const name = target.name;
    const p = { ...plan, [name]: value };
    setPlan(p);
    //入力項目リセット
    productReset(p);
  }

  // const handleChangeNgshop = (data) => {
  //   setNgshopValues(data);
  // }
  // const handleChangeNgtime = (data) => {
  //   setNgtimeValues(data);
  // }

  // 「商品画像」変更イベント
  const handleChangeFile = async (e) => {
    const file = e.target.files[0];
    const compressOption = {
      maxSizeMB: 1, // 最大ファイルサイズ
      maxWidthOrHeight: 1024 // 最大画像幅もしくは高さ
    }
    const compressFile = await imageCompression(file, compressOption);
    const url = await imageCompression.getDataUrlFromFile(compressFile);
    setValues({
      ...values,
      uploadfile: url,
      uploadfiletype: file.type,
      deletefile: values.product_image
    });
  }
  const handleDeleteFile = () => {
    setValues({ ...values, uploadfile: null, uploadfiletype: null, deletefile: values.product_image });
  }

  // 初期実行トリガー
  useEffect(() => {
    refreshProduct();
  }, [plan, refreshProduct]);

  const renderInput = () => {
    return (
      <div className="mx-1">
        <div className="px-2 print-none">
          <React.Fragment>
            {csvProductData &&
              <CSVLink className="btn btn-primary mb-3 px-2 mr-3" data={csvProductData.list} headers={csvProductData.header} filename={"グッズデータ.csv"} accept=".csv">
                CSVエクスポート
              </CSVLink>
            }
            <button
              className="btn btn-primary mb-3 px-2"
              onClick={() => { setConfirm(true) }}>
              CSVインポート
            </button>
          </React.Fragment>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center">
            <span className='needmark'>※</span>
            <span>種別</span>
          </div>
          <div className="col-4">
            <select className="custom-select w-100" aria-label="企画" name="plan_id" value={values.plan_id || ''} onChange={handleChange}>
              <option value="">選択してください</option>
              {planMaster.map((item) =>
                <option key={item.plan_id} value={item.plan_id}>{item.plan_name}</option>
              )}
            </select>
          </div>
          <div className="col-6">
            複数商品がある場合、種別ごとに1つの注文（発送）になります。<br />
            送料が必要な商品は、1注文ごとに配送料を追加してお支払いいただきます。
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center">
            <span className='needmark'>※</span>
            <span>商品コード 商品名</span>
          </div>
          <div className="col-2 text-center">
            <input type="text" className="form-control" name="product_code" value={values.product_code || ''} onChange={handleChange} placeholder="商品コードを入力してください" />
          </div>
          <div className="col-4 text-center">
            <input type="text" className="form-control" name="product_name" value={values.product_name || ''} onChange={handleChange} placeholder="商品名を入力してください" />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center">
            <span className='needmark'>※</span>
            <label htmlFor="product_category_id">カテゴリ</label>
          </div>
          <div className="col-4">
            <select className="custom-select w-100" aria-label="カテゴリ" name="product_category_id" value={values.product_category_id || ''} onChange={handleChange}>
              <option value="">選択してください</option>
              {productCategoryMaster.map((item) =>
                <option key={item.product_category_id} value={item.product_category_id}>{item.product_category_name}</option>
              )}
            </select>
          </div>
          <div className="col-6">
            会員様が商品を絞り込む際に使用するカテゴリです。
          </div>
        </div>
        {/* <div className="row mb-3">
          <div className="col-2 text-center">
            <span className='needmark'>※</span>
            <label htmlFor="product_category_id">商品デプト</label>
          </div>
          <div className="col-4">
            <select className="custom-select w-100" aria-label="商品デプト" name="product_dept_code" value={values.product_dept_code || ''} onChange={handleChange}>
              <option value="">選択してください</option>
              {productDeptMaster.map((item) =>
                <option key={item.product_dept_code} value={item.product_dept_code} >
                  {item.product_dept_name}
                </option>
              )}
            </select>
          </div>
        </div> */}
        <div className="row mb-3">
          <div className="col-2 text-center">
            <label htmlFor="remarks">商品詳細</label>
          </div>
          <div className="col-5">
            <textarea className="form-control" rows="3" name="product_detail" id="product_detail" value={values.product_detail || ''} onChange={handleChange}></textarea>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center">
            <label htmlFor="remarks">商品画像</label>
          </div>
          <div className="col-5">
            {!(values.uploadfile) && <input type="file" className="form-control-file" name="productImage" id="productImage" value="" accept=".png, .jpg, .jpeg" onChange={handleChangeFile} onClick={e => (e.target.value = null)} />}
            {values.uploadfile && <object id="uploadfileview" data={values.uploadfile} type={values.uploadfiletype}><img src={values.uploadfile} alt='uploadfile' /></object>}
            {values.uploadfile && <input type="button" className="form-controll" name="deletefile" id="deletefile" onClick={handleDeleteFile} value="ファイル削除" />}
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center">
            <span className='needmark'>※</span>
            <span>税込単価</span>
          </div>
          <div className="col-2 text-center">
            <input type="number" className="form-control" id="price" name="price" value={values.price || ''} onChange={handleChange} />
          </div>
          <div className="col-1 text-center">
            <span className='needmark'>※</span>
            <span>税別単価</span>
          </div>
          <div className="col-2 text-center">
            <input type="number" className="form-control" id="price_before_tax" name="price_before_tax" value={values.price_before_tax || ''} onChange={handleChange} />
          </div>
          <div className="col-1 text-center">
            <span className='needmark'>※</span>
            <span>税率</span>
          </div>
          <div className="col-2 text-center">
            <input type="number" className="form-control" id="tax_rate" name="tax_rate" value={values.tax_rate || ''} onChange={handleChange} />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center">
            <span>送料が必要</span>
          </div>
          <div className="col-2 text-center">
            <input type="checkbox" className="form-control" id="shipping_fee_flag" name="shipping_fee_flag" checked={values.shipping_fee_flag === constClass.FLAG.ON} onChange={handleChange} />
          </div>
        </div>
        {/* <div className="row mb-3">
          <div className="col-2 text-center">
            <span>ポイント付与数</span>
          </div>
          <div className="col-2 text-center">
            <input type="text" className="form-control" id="point" name="point" value={values.point || ''} onChange={handleChange} />
          </div>
          <div className="col-1 text-center">
            <span>ポイント付与率</span>
          </div>
          <div className="col-2 text-center">
            <input type="text" className="form-control" id="point_rate" name="point_rate" value={values.point_rate || ''} onChange={handleChange} />
          </div>
        </div> */}
        <div className="row mb-3">
          <div className="col-2 text-center">
            <span className='needmark'>※</span>
            <label htmlFor="planStart">注文開始日</label>
          </div>
          <div className="col-2">
            {<DatePicker maxDate={values.orderLimit} className="form-control w-100" aria_labal="注文開始日" locale="ja" dateFormat="yyyyMMdd" monthsShown="1" id="orderStart" name="orderStart" selected={values.orderStart} onChange={date => handleChangeDate("orderStart", date)} autoComplete="off" />}
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center">
            <span className='needmark'>※</span>
            <label htmlFor="planStart">締め切り日</label>
          </div>
          <div className="col-2">
            {<DatePicker minDate={values.orderStart} className="form-control w-100" aria_labal="締め切り日" locale="ja" dateFormat="yyyyMMdd" monthsShown="1" id="orderLimit" name="orderLimit" selected={values.orderLimit} onChange={date => handleChangeDate("orderLimit", date)} autoComplete="off" />}
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center">
            <label htmlFor="planStart">発送予定日</label>
          </div>
          <div className="col-2">
            {<DatePicker minDate={values.orderStart} className="form-control w-100" aria_labal="発送予定日" locale="ja" dateFormat="yyyyMMdd" monthsShown="1" id="shippingDate" name="shippingDate" selected={values.shippingDate} onChange={date => handleChangeDate("shippingDate", date)} autoComplete="off" />}
          </div>
        </div>
        {/* <div className="row mb-3">
          <div className="col-2 text-center">
            <span className='needmark'>※</span>
            <label htmlFor="planEnd">{values.year_round_type === "1" ? "変更可能日数" : "変更可能日"}</label>
          </div>
          <div className="col-2">
            {values.year_round_type === "1" && <input type="text" className="form-control w-100" name="change_limit_days" id="change_limit_days" value={values.change_limit_days || ''} onChange={handleChange} />}
            {values.year_round_type !== "1" && <DatePicker minDate={limitDate.min} maxDate={limitDate.max} className="form-control w-100" locale="ja" dateFormat="yyyyMMdd" monthsShown="1" name="changeLimit" id="changeLimit" selected={values.changeLimit} onChange={date => handleChangeDate("changeLimit", date)} autoComplete="off" />}
          </div>
          {values.year_round_type === "1" && <div className="col-8">※通年のため、変更可能日数（受取日から●日前）の日数を指定してください。</div>}
        </div> */}
        {/* <div className="row mb-3">
          <div className="col-2 text-center">
            <span>早割割引率</span>
          </div>
          <div className="col-2 text-center">
            <input type="text" className="form-control" id="early_discount_rate" name="early_discount_rate" value={values.early_discount_rate || ''} onChange={handleChange} placeholder="10%の場合は0.1と入力してください" />
          </div>
          <div className="col-1 text-center">
            <label htmlFor="planStart">早割開始日</label>
          </div>
          <div className="col-2">
            {<DatePicker minDate={limitDate.min} maxDate={limitDate.max} className="form-control w-100" locale="ja" dateFormat="yyyyMMdd" monthsShown="1" name="earlyDiscountStart" id="earlyDiscountStart" selected={values.earlyDiscountStart} onChange={date => handleChangeDate("earlyDiscountStart", date)} autoComplete="off" />}
          </div>
          <div className="col-1">
            <label htmlFor="planEnd">早割終了日</label>
          </div>
          <div className="col-2">
            {<DatePicker minDate={limitDate.min} maxDate={limitDate.max} className="form-control w-100" locale="ja" dateFormat="yyyyMMdd" monthsShown="1" name="earlyDiscountEnd" id="earlyDiscountEnd" selected={values.earlyDiscountEnd} onChange={date => handleChangeDate("earlyDiscountEnd", date)} autoComplete="off" />}
          </div>
        </div> */}
        <div className="row mb-3">
          <div className="col-2 text-center">
            <span className='needmark'>※</span>
            <span>在庫数</span>
          </div>
          <div className="col-2 text-center">
            <input type="text" className="form-control" id="stock" name="stock" value={values.stock || ''} onChange={handleChange} />
          </div>
          <div className="col-2 text-center">
            <span>1人あたり注文上限数</span>
          </div>
          <div className="col-2 text-center">
            <input type="text" className="form-control" id="cart_limit" name="cart_limit" value={values.cart_limit || ''} onChange={handleChange} />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center">
            <span>VIP限定</span>
          </div>
          <div className="col-2 text-center">
            <input type="checkbox" className="form-control" id="vip_flag" name="vip_flag" checked={values.vip_flag === constClass.FLAG.ON} onChange={handleChange} />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center">
            <span>備考入力</span>
          </div>
          <div className="col-2 text-center">
            <input type="checkbox" className="form-control" id="notice_flag" name="notice_flag" checked={values.notice_flag === constClass.FLAG.ON} onChange={handleChange} />
          </div>
        </div>
        {/* <div className="row mb-3">
          <div className="col-2 text-center">
            <span>JANコード</span>
          </div>
          <div className="col-2 text-center">
            <input type="text" className="form-control" id="jan_code" name="jan_code" value={values.jan_code || ''} onChange={handleChange} />
          </div>
        </div> */}
        {/* {values.year_round_type === "1" &&
          <div className="row mb-3">
            <div className="col-2 text-center">
              <span className='needmark'>※</span>
              <span>受取可能日数</span>
            </div>
            <div className="col-2 text-left">
              <input type="text" className="form-control w-100" name="receipt_limit_days" id="receipt_limit_days" value={values.receipt_limit_days || ''} onChange={handleChange} />
            </div>
            <div className="col-8">※通年のため、受取可能日数（注文日から●日後）の日数を指定してください。</div>
          </div>
        }
        {values.year_round_type !== "1" &&
          <div className="row mb-3">
            <div className="col-2 text-center">
              <span>受け取り可能日</span>
            </div>
            <div className="col-10"><CreatableSelect
              name="revice_date" id="revice_date"
              isMulti
              options={receiveDate}
              value={receiveDate}
              components={{ DropdownIndicator: null }}
              onChange={handleChangeReviceDate}
              placeholder="受け取り可能日を8桁で入力してください"
            /></div>
          </div>
        } */}
        {/* <div className="row mb-3">
          <div className="col-2 text-center">
            <span>受け取り不可店舗</span>
          </div>
          <div className="col-10 text-left">
            <Select options={shopMaster} isMulti closeMenuOnSelect={false} value={ngShop} onChange={handleChangeNgshop} placeholder="受け取り不可店舗を選択してください" />
          </div>
        </div> */}
        {/* {Common.getSettingValue(settings, 'USE_RECEIVE_NG_TIME', "0") === "1" &&
          <div className="row mb-3">
            <div className="col-2 text-center">
              <span>受け取り不可時間帯</span>
            </div>
            <div className="col-10 text-left">
              <Select options={timeMaster} isMulti closeMenuOnSelect={false} value={ngTime} onChange={handleChangeNgtime} placeholder="受け取り不可時間帯を選択してください" />
            </div>
          </div>
        } */}
        <div className="row mb-2 pb-4">
          <div className="col-3 text-center">
            {renderRegistButton(values)}
          </div>
          <div className="col-3 text-center">
            {renderDeleteButton(values)}
          </div>
          <div className="col-3 text-center">
            {renderResetButton(values)}
          </div>
        </div>
      </div>
    );
  }
  const renderList = () => {
    return (
      <div className="row mb-3 p-0">
        <div className="row d-none"><div className="col-12">{util.inspect(productData)}</div></div>
        <div className="col-12 p-0">
          <table className="table table-bordered table-striped">
            <thead className={`table-${constClass.COLOR[user.userClass]}`}>
              <tr>
                <td className="text-center align-middle">
                  商品コード
                </td>
                <td className="text-center align-middle">
                  種別
                </td>
                <td className="text-center align-middle">
                  商品名
                </td>
                <td className="text-center align-middle">
                  カテゴリ
                </td>
                {/* <td className="text-center align-middle">
                  商品デプト
                </td> */}
                <td className="text-center align-middle">
                  注文開始日
                </td>
                <td className="text-center align-middle">
                  締め切り日
                </td>
                <td className="text-center align-middle">
                  発送予定日
                </td>
                {/* <td className="text-center align-middle">
                  変更可能日
                </td> */}
                <td className="text-center align-middle">
                  在庫数 / 注文数
                </td>
                <td className="text-center align-middle">
                  税込み
                </td>
                <td className="text-center align-middle">
                  税別
                </td>
                <td className="text-center align-middle">
                  税率
                </td>
                <td className="text-center align-middle">
                  更新日
                </td>
                <td className="text-center align-middle">
                  処理
                </td>
              </tr>
            </thead>
            <tbody>
              {productData.map((data, idx) => (
                <tr key={data.product_id}>
                  {/* 商品ID */}
                  <td className="text-center align-middle">
                    {data.product_code}
                  </td>
                  {/* 企画 */}
                  <td className="text-center align-middle">
                    {data.plan_name}
                  </td>
                  {/* 商品名 */}
                  <td className="text-center align-middle">
                    {data.product_name}
                  </td>
                  {/* 商品カテゴリ名 */}
                  <td className="text-center align-middle">
                    {data.product_category_id}:{data.product_category_name}
                  </td>
                  {/* 商品デプト名 */}
                  {/* <td className="text-center align-middle">
                    {data.product_dept_code}:{data.product_dept_name}
                  </td> */}
                  {/* 注文開始日 */}
                  <td className="text-center align-middle">
                    {data.order_start}
                  </td>
                  {/* 締め切り日 */}
                  <td className="text-center align-middle">
                    {data.order_limit}
                  </td>
                  {/* 発送予定日 */}
                  <td className="text-center align-middle">
                    {data.shipping_date}
                  </td>
                  {/* 変更可能日 */}
                  {/* <td className="text-center align-middle">
                    {data.change_limit}
                  </td> */}
                  {/* 在庫数/注文数 */}
                  <td className="text-center align-middle">
                    {data.stock} / {data.order_count}
                  </td>
                  {/* 税込み */}
                  <td className="text-center align-middle">
                    {data.price.toLocaleString()}
                  </td>
                  {/* 税別 */}
                  <td className="text-center align-middle">
                    {data.price_before_tax.toLocaleString()}
                  </td>
                  {/* 税率 */}
                  <td className="text-center align-middle">
                    {data.tax_rate}
                  </td>
                  {/* 更新日時 */}
                  <td className="text-center align-middle">
                    {data.upd_date ? moment(data.upd_date).format('YYYY-MM-DD HH:mm:ss') : ''}
                  </td>
                  {/* 処理 */}
                  <td className="text-center align-middle text-nowrap">
                    {renderUpdateButton(data)}
                  </td>
                </tr>
              ))
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  // プレビュー表示：処理
  const renderPreview = () => {
    return (
      <div className="modal" id="exampleModal">
        <div className="modal-dialog productpreview-modal">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            </div>
            <div className="modal-body">
              {productData &&
                <ProductList
                  site_id={props.match.params.siteId}
                  products={productData}
                  disabled={lockData}
                  message={[]}
                  receive_shop={null}
                  displayCartCount={() => { return 0 }}
                  addCart={() => { }}
                  subCart={() => { }}
                  setting={settings}
                  getDetailNotice={() => { }}
                  setDetailNotice={() => { }}
                  message_notice={[]}
                />
              }
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="row my-2">
        <div className="col-3">
          <div className="row">
            <div className="col-auto text-center line-height-2-2">
              <span className="d-inline-block align-middle">種別</span>
            </div>
            <div className="col text-center line-height-2-2">
              <select className="custom-select w-100" aria-label="種別" name="plan_id" value={plan.plan_id || ''} onChange={handleChangePlan}>
                <option value="">- 全て -</option>
                {planMaster.map((item) =>
                  <option key={item.plan_id} value={item.plan_id}>{item.plan_name}</option>
                )}
              </select>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="row">
            <div className="col-auto text-center line-height-2-2">
              <span className="d-inline-block align-middle">カテゴリ</span>
            </div>
            <div className="col text-center line-height-2-2">
              <select className="custom-select w-100" aria-label="商品カテゴリ" name="product_category_id" value={plan.product_category_id || ''} onChange={handleChangeProductCategory}>
                <option value="">- 全て -</option>
                {productCategoryMaster.map((item) =>
                  <option key={item.product_category_id} value={item.product_category_id}>{item.product_category_name}</option>
                )}
              </select>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="row">
            <div className="col-auto text-center line-height-2-2">
              <span>過去のグッズを表示</span>
            </div>
            <div className="col text-center line-height-2-2">
              <input type="checkbox" className="form-control" id="product_past_flag" name="product_past_flag" checked={plan.product_past_flag} onChange={handleChangePastFlag} />
            </div>
          </div>
        </div>
        <div className="col-3 text-right line-height-2-2">
          <button type="button"
            disabled={lockData}
            className={`btn btn-primary mx-1 `}
            data-toggle="modal" data-target="#exampleModal"
            onClick={() => renderPreview}>
            プレビュー表示
          </button>
        </div>
      </div>

      <Modal isOpen={confirm} style={modalConfirmStyle} onRequestClose={() => setConfirm(false)}>
        <div className="">
          <div className="modal-header">
            商品データCSVインポート
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => clearImportData()}><span aria-hidden="true">&times;</span></button>
          </div>
          <div className="modal-body">
            {!importDisplayData && <input type="file" className="form-control-file" name="csv" id="csv" value="" accept=".csv" onChange={handleFile} onClick={e => (e.target.value = null)} />}
            {(importDisplayData && importDisplayData.length < 1) &&
              <div>
                <span>データが見つかりません。</span>
                <button type="button" className="btn btn-secondary" onClick={() => clearImportData()}>戻る</button>
              </div>
            }
            {(errMessage && errMessage.length > 0) &&
              errMessage.map((data, dataIdx) =>
                <div key={dataIdx} className="pl-4 text-danger">
                  <li>{data}</li>
                </div>
              )}
            {(importDisplayData && importDisplayData.length > 0) &&
              <div className="container-fluid">
                <div className="row">
                  <div className="col table-responsive">
                    <table className="table table-sm table-bordered">
                      <thead>
                        <tr className='text-nowrap bg-gray-alpha'>
                          <td className="text-center align-middle">
                            種別
                          </td>
                          <td className="text-center align-middle">
                            商品コード
                          </td>
                          <td className="text-center align-middle">
                            商品名
                          </td>
                          <td className="text-center align-middle">
                            商品カテゴリID
                          </td>
                          <td className="text-center align-middle">
                            商品詳細
                          </td>
                          <td className="text-center align-middle">
                            税込単価
                          </td>
                          <td className="text-center align-middle">
                            税別単価
                          </td>
                          <td className="text-center align-middle">
                            税率
                          </td>
                          <td className="text-center align-middle">
                            送料が必要
                          </td>
                          <td className="text-center align-middle">
                            注文開始日
                          </td>
                          <td className="text-center align-middle">
                            締め切り日
                          </td>
                          <td className="text-center align-middle">
                            発送予定日
                          </td>
                          <td className="text-center align-middle">
                            在庫数
                          </td>
                          <td className="text-center align-middle">
                            1人あたり注文上限数
                          </td>
                          <td className="text-center align-middle">
                            VIP限定
                          </td>
                          <td className="text-center align-middle">
                            備考入力
                          </td>
                          {Common.getSettingValue(settings, 'USE_RECEIVE_NG_TIME', "0") === "1" &&
                            <td className="text-center align-middle">
                              受取不可時間帯ID
                            </td>
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {importDisplayData.map((row, rowIdx) =>
                          <tr key={rowIdx}>
                            {row.map((data, idx) =>
                              <td key={rowIdx + '_' + idx} className={`text-nowrap`}>{data.trim()}</td>
                            )}
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row">
                  <div className="col-auto">
                    <button type="button" className="btn btn-secondary" onClick={() => handleBackClick()}>戻る</button>
                  </div>
                  <div className="col-auto">
                    <button type="button" className="btn btn-primary" disabled={(errMessage && errMessage.length > 0)} onClick={() => handleExecClick()}>{(importDisplayData.length)}件のデータを反映</button>
                  </div>
                </div>
              </div>
            }
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => clearImportData()}>Close</button>
          </div>
        </div>
      </Modal>

      {productData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {productData !== null && (renderList())}
      {renderInput()}
      {renderPreview()}
    </div>
  )
}

export default Product;