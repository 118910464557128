import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const Guide = ({ siteId, page, setting, my_customer, liff_access_token, liff_user_id, setBack, setNext, setMyCustomer }) => {
  const history = useHistory();

  useEffect(() => {
    setBack({
      title: '戻る',
      render: true,
      disabled: false,
      onClick: () => {
        if (history.length <= 1) {
          history.push(`/${siteId}/`);
        } else {
          history.goBack();
        }
      }
    });
    setNext({
      title: '次へ',
      render: false,
      disabled: false,
      onClick: () => { }
    });
  }, [history, setBack, setNext, siteId])

  return (
    <div id="specified_commercial" className="row mx-3 my-3 px-0-env">
      <div className="col bg-white px-0">
        <div>
          <div className="row mx-0 pt-2 pb-1">
            <div className="col py-0 px-0 align-self-center text-center">
              <h5>ご利用案内</h5>
            </div>
          </div>
          <div className="row mx-2 pt-2 pb-0">
            <div className="col py-0 px-0 align-self-center font-weight-bold">
              ・ご注文の流れ
            </div>
          </div>
          <div className="row mx-2 pt-1 pb-2">
            <div className="col py-2 px-2 align-self-center">
              ご注文方法は下記の通りです。<br /><br />
              &#9312;商品を選ぶ<br />
              <p className="pl-3 mb-0">
                お好きな商品を選び数量を記入してください。<br />
                数量記入後、「ご注文確認画面へ進む」ボタンを押してください。<br />
                備考欄が表示された場合は、備考欄を入力してください。
              </p><br />
              &#9313;ご注文内容の確認<br />
              <p className="pl-3 mb-0">
                ご注文内容をご確認後、「次へ」ボタンを押してください。
              </p><br />
              &#9314;お支払い方法の確定<br />
              <p className="pl-3 mb-0">
                クレジットを選択されたお客様はクレジット情報を入力後「次へ」ボタンを押してください。<br />
                クレジット情報確認画面で問題が無ければ、下部の「確定」ボタンを押してください。<br />
                PayPayを選択されたお客様は、「次へ」ボタンを押してください。<br />
                PayPayの画面で問題が無ければ「支払う」ボタンを押してください。
              </p>
            </div>
          </div>
          <div className="row mx-2 pt-2 pb-0">
            <div className="col py-0 px-0 align-self-center font-weight-bold">
              ・商品到着までの流れ
            </div>
          </div>
          <div className="row mx-2 pt-1 pb-2">
            <div className="col py-2 px-2 align-self-center">
              商品がご用意でき次第発送いたします。<br />
            </div>
          </div>
          <div className="row mx-2 pt-2 pb-0">
            <div className="col py-0 px-0 align-self-center font-weight-bold">
              ・お支払い方法
            </div>
          </div>
          <div className="row mx-2 pt-1 pb-2">
            <div className="col py-2 px-2 align-self-center">
              お支払い方法は「クレジットカード決済(VISA、MASTER、JCB、DINERS、AMEX)」、「PayPay」がご利用いただけます。<br />
            </div>
          </div>
          <div className="row mx-2 pt-2 pb-0">
            <div className="col py-0 px-0 align-self-center font-weight-bold">
              ・送料について
            </div>
          </div>
          <div className="row mx-2 pt-1 pb-2">
            <div className="col py-2 px-2 align-self-center">
              商品によって送料が必要な場合がございます。商品注文時にご確認ください。<br />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Guide;