import React, { useMemo, useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import axios from 'axios';
import NavBar from '../Components/NavBar/NavBar';
import UserSlice from '../Slices/User';
import UserOnly from '../Components/Auth/UserOnly';
import GuestOnly from '../Components/Auth/GuestOnly';
import Login from '../Components/Auth/Login';
import Base from '../Components/Auth/Base';
import DocumentMeta from 'react-document-meta';
import { ToastProvider } from 'react-toast-notifications';
import Customer from '../Components/Customer/Customer';
import CustomerDetail from '../Components/Customer/CustomerDetail';
import PaymentHistory from '../Components/Customer/PaymentHistory';
import Membership from '../Components/Master/Membership';
import SystemTab from '../Components/Master/SystemTab';
import BackyardCondition from '../Slices/BackyardCondition';
import BackyardOrderCondition from '../Slices/BackyardOrderCondition';
import BackyardOrderFinTab from '../../src/Components/BackyardOrder/BackyardOrderFinTab';
import BackyardOrderInput from '../../src/Components/BackyardOrder/BackyardOrderInput';
import constClass from '../Constants/Constants';
import ReceiveTimeMaster from '../../src/Components/Master/ReceiveTime'
import PlanMaster from '../../src/Components/Master/Plan'
import ProductMaster from '../../src/Components/Master/Product'
import ProductDept from '../../src/Components/Master/ProductDept'
import PlanCategoryMaster from '../../src/Components/Master/PlanCategory';
import ProductCategoryMaster from '../../src/Components/Master/ProductCategory';

const useActions = (actions, deps) => {
  const dispatch = useDispatch();
  return useMemo(
    () => {
      if (Array.isArray(actions)) {
        return actions.map(a => bindActionCreators(a, dispatch))
      }
      return bindActionCreators(actions, dispatch)
    },
    deps ? [dispatch, ...deps] : [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )
};

const BackApp = (props) => {
  const userActions = useActions(UserSlice.actions);
  const user = useSelector(state => state.User);
  const [siteName, setSiteName] = useState();
  const [meta, setMeta] = useState({});
  const [setting, setSetting] = useState(null);
  const conditionActions = useActions(BackyardCondition.actions);
  const condition = useSelector(state => state.BackyardCondition);
  const conditionOrderActions = useActions(BackyardOrderCondition.actions);
  const conditionOrder = useSelector(state => state.BackyardOrderCondition);

  const getSiteName = async (siteId) => {
    const site = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/site/search/`, { site_id: siteId })).data;
    const setting = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/setting/${siteId}/`)).data;
    setSiteName(site.site_name);
    const temp = {
      link: {
        rel: {
          icon: `/${siteId}/favicon.ico`,
          "apple-touch-icon": `/${siteId}/logo192.png`,
          manifest: `/${siteId}/manifest.json`,
          stylesheet: `/${siteId}/style.css`,
        },
      },
      title: site ? site.site_name : '',
      description: site ? site.site_name : '',
    }
    setMeta(temp);
    setSetting(setting);
  }

  useEffect(() => {
    getSiteName(props.match.params.siteId);
  }, [props.match.params.siteId]);

  const _userProps = { user, userActions, condition, conditionActions, siteName, setting, ...props };
  const _orderProps = { user, userActions, condition: conditionOrder, conditionActions: conditionOrderActions, siteName, settings: setting, ...props };
  return (
    <div className='backyard-top'>
      <DocumentMeta {...meta} />
      <Route path={`${props.match.path}`} render={() =>
        <Base {..._userProps}>
          <NavBar {..._userProps} />
          <ToastProvider placement="bottom-center">
            <Route render={() =>
              <UserOnly {..._userProps} >
                <Route exact path={`${props.match.path}`} >
                  <Customer {..._userProps} />
                </Route>
                <Route exact path={`${props.match.path}customer/`} >
                  <CustomerDetail {..._userProps} />
                </Route>
                <Route exact path={`${props.match.path}customer/:id`} >
                  <CustomerDetail {..._userProps} />
                </Route>
                <Route exact path={`${props.match.path}paymenthistory`} >
                  <PaymentHistory {..._userProps} />
                </Route>
                <Route exact path={`${props.match.path}membership`} >
                  <Membership {..._userProps} />
                </Route>
                <Route exact path={`${props.match.path}system`} >
                  <SystemTab {..._userProps} />
                </Route>
                <Route exact path={`${props.match.path}order`} >
                  <BackyardOrderFinTab sts={constClass.ORDER_STATUS.REG} {..._orderProps} />
                </Route>
                <Route path={`${props.match.path}order/input/:id`}>
                  <BackyardOrderInput {..._orderProps} />
                </Route>
                <Route exact path={`${props.match.path}order/input`} >
                  <BackyardOrderInput {..._orderProps} />
                </Route>
                <Route path={`${props.match.path}plancategory`} >
                  <PlanCategoryMaster {..._orderProps} />
                </Route>
                <Route path={`${props.match.path}productcategory`} >
                  <ProductCategoryMaster {..._orderProps} />
                </Route>
                <Route exact path={`${props.match.path}plan`} >
                  <PlanMaster {..._orderProps} />
                </Route>
                <Route exact path={`${props.match.path}product`} >
                  <ProductMaster {..._orderProps} />
                </Route>
                <Route exact path={`${props.match.path}productdept`} >
                  <ProductDept {..._orderProps} />
                </Route>
                <Route exact path={`${props.match.path}receivetime`} >
                  <ReceiveTimeMaster {..._orderProps} />
                </Route>
              </UserOnly>
            } />
            <Route render={() =>
              <GuestOnly {..._userProps} >
                <Route path={`${props.match.path}login`} render={() =>
                  <Login {..._userProps} />
                } />
              </GuestOnly>
            } />
          </ToastProvider>
        </Base>
      } />
    </div>
  );
}

export default BackApp;