import { createSlice } from '@reduxjs/toolkit';
import constClass from '../Constants/Constants';

// Stateの初期状態
const initialState = {
  customer_no: '',
  customer_name: '',
  customer_kana: '',
  customer_tel: '',
  customer_mail: '',
  customer_birthday_y: '',
  customer_birthday_m: '',
  customer_birthday_d: '',
  status: [
    {value: constClass.STATUS.TMP, label: constClass.STATUS_NAME.TMP}, 
    {value: constClass.STATUS.REG, label: constClass.STATUS_NAME.REG},
    {value: constClass.STATUS.UPD, label: constClass.STATUS_NAME.UPD}
  ],
  selectStatus: [],
  customer_type: [],
  selectCustomerType: [],
  line_id: [],
  selectLineId: [],
  vip_flag: [],
  selectVipFlag: [],
  isReload: true,
  sortOrder: [],
};

const BackyardCondition = createSlice({
  name: "BackyardCondition",
  initialState: initialState,
  reducers: {
    setCondition: (state, action) => ({
      ...state,
      [action.payload.name]: action.payload.value,
    }),
    setIsReload: (state, action) => ({
      ...state,
      isReload: action.payload,
    }),
    setSortOrder: (state, action) => ({
      ...state,
      sortOrder: action.payload,
    }),
    clearCondition: (state) => ({
      ...initialState,
      isReload: state.isReload,
    }),
    init: () => ({
      ...initialState,
    })
  }
});

// 個別でも使えるようにActionCreatorsをエクスポートしておく
export const { setCondition, setIsReload, setSortOrder, clearCondition, init } = BackyardCondition.actions;

export default BackyardCondition;