import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Common from '../Common/common';
import constClass from '../../Constants/Constants';
import axios from 'axios';
import util from 'util';
import InputComponent from './InputComponent';

const AuthVerify = ({ siteId, page, setting, my_customer, liff_access_token, liff_user_id, setBack, setNext, setMyCustomer }) => {
  const [lockData, setLockData] = useState(false);
  const history = useHistory();

  const checkInvalid = useCallback(() => {
    return false;
  }, []);

  const submit = useCallback(async () => {
    if (checkInvalid() || lockData) {
      return;
    }
    // API実行
    setLockData(true);
    try {
      const params = { ...my_customer, line_id: liff_access_token };
      const data = (await axios.put(`${process.env.REACT_APP_BACKEND_URL}/customer/line/${my_customer.customer_id}`, params)).data;
      alert('登録が完了しました。');
      setMyCustomer(data);
      history.push(`/${siteId}/?page=${constClass.MENU}`);
    } catch (err) {
      console.log(util.inspect(err));
      if (err.response && err.response.data) {
        alert(err.response.data);
      } else {
        alert('登録に失敗しました。');
      }
    } 
  }, [checkInvalid, lockData, history, liff_access_token, siteId, setMyCustomer, my_customer]);

  useEffect(() => {
    setBack({
      title: '戻る',
      render: true,
      disabled: lockData,
      onClick: () => {
        if (history.length <= 1) {
          history.push(`/${siteId}/`);
        } else {
          history.goBack();
        }
      }
    });
    setNext({
      title: '登録',
      render: true,
      disabled: checkInvalid() || lockData,
      onClick: submit
    });
  }, [history, setBack, setNext, siteId, checkInvalid, lockData, submit])

  return (
    <div id="authverify" className="row mx-3 my-3 px-0-env">
      <div className="col bg-white px-0">
        <div>
          {(setting['AUTHVERIFY_HEADER']) &&
            <div className="customer-header row mx-0 pt-2 pb-1 text-left">
              <div className="col py-0 px-3 align-self-center">
                {Common.getBrString(setting['AUTHVERIFY_HEADER'])}
              </div>
            </div>
          }
          <div className="customer-input row mx-0 pt-2 pb-1">
            <div className="col py-0 px-0 align-self-center">
              {InputComponent.renderLabel('会員番号', my_customer.customer_no)}
              {InputComponent.renderLabel('お名前', my_customer.customer_name)}
              {InputComponent.renderLabel('携帯番号', my_customer.customer_tel)}
              {InputComponent.renderLabel('メールアドレス', my_customer.customer_mail)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AuthVerify;