import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
// import util from 'util';
import constClass from '../../Constants/Constants';
import { useParams } from 'react-router';
import Common from '../Common/common';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import ja from 'date-fns/locale/ja';
registerLocale('ja', ja);

const Membership = (props) => {
  const { siteId } = useParams();
  const [year, setYear] = useState(String(Common.getFiscalYear() + 1));
  const [membershipData, setMembershipData] = useState(null);
  const [errMsg, setErrMsg] = useState({});
  const [lockData, setLockData] = useState(false);
  const { addToast } = useToasts();

  const refreshData = useCallback(async () => {
    const jwt = localStorage.getItem('jwt');
    setLockData(true);
    if (year.length > 3) {
      try {
        const reg_params = { site_id: siteId, membership_year: year };
        // お客様データ
        const data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/membership/search/`, reg_params, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        })).data;
        var membership = data.find(() => true);
        if (!membership) {
          membership = {
            site_id: siteId,
            membership_year: year,
            membership_start: moment(year + '0401').toDate(),
            membership_end: moment((Number(year) + 1) + '0331').toDate(),
            renewal_start: null,
            renewal_end: null,
            membership_fee: ''
          };
        } else {
          membership.membership_start = new Date(membership.membership_start);
          membership.membership_end = new Date(membership.membership_end);
          membership.renewal_start = new Date(membership.renewal_start);
          membership.renewal_end = new Date(membership.renewal_end);
        }
        setMembershipData(membership);
        setLockData(false);
      } catch (err) {
        console.log(err);
      }
    }
  }, [siteId, year]);

  useEffect(() => {
    refreshData();
  }, [refreshData]); //

  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? (target.checked ? constClass.FLAG.ON : constClass.FLAG.OFF) : target.value;
    const name = target.name;
    setMembershipData({ ...membershipData, [name]: value });
    setErrMsg({ ...errMsg, [name]: validator(name, value) });
  }

  const validator = (name, value) => {
    switch (name) {
      case 'membership_fee':
        return Common.numericValidation(value, false);
      default:
        return '';
    }
  }

  const handleChangeDate = (name, value) => {
    setMembershipData({ ...membershipData, [name]: value });
  }

  const checkInvalid = () => {
    var ret = false;

    // エラーメッセージのチェック
    Object.keys(errMsg).forEach(name => {
      if (errMsg[name]) {
        ret = true;
      }
    });
    return ret;
  }

  const handleRegister = async () => {
    const jwt = localStorage.getItem('jwt');
    // ボタン非活性時は即時終了
    if (checkInvalid() || lockData) {
      return;
    }

    // 必須項目チェック
    if (!membershipData.membership_start) {
      alert('会期（開始）を選択してください。');
      return;
    }
    if (!membershipData.membership_end) {
      alert('会期（終了）を選択してください。');
      return;
    }
    if (!membershipData.renewal_start) {
      alert('更新期間（開始）を選択してください。');
      return;
    }
    if (!membershipData.renewal_end) {
      alert('更新期間（終了）を選択してください。');
      return;
    }
    if (!membershipData.membership_fee) {
      alert('会費を入力してください。');
      return;
    }

    // 日付整合性チェック
    if (membershipData.membership_start > membershipData.membership_end) {
      alert('会期の開始日が終了日よりも後になっています。');
      return;
    }
    if (membershipData.renewal_end > membershipData.membership_start) {
      alert('更新期間の終了日が会期の開始日よりも後になっています。');
      return;
    }
    if (membershipData.renewal_start > membershipData.renewal_end) {
      alert('更新期間の開始日が終了日よりも後になっています。');
      return;
    }

    // 送信用データ作成
    const reg_params = {
      ...membershipData
    }
    // 不要なプロパティを削除
    delete reg_params.ins_name;
    delete reg_params.ins_date;
    delete reg_params.upd_name;
    delete reg_params.upd_date;

    // API実行
    setLockData(true);
    try {
      if (membershipData.ins_name) {
        await axios.put(`${process.env.REACT_APP_BACKEND_URL}/membership/${year}`, reg_params, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        });
        addToast('更新しました。', { appearance: 'success', autoDismiss: true });
      } else {
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/membership/`, reg_params, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        });
        addToast('登録しました。', { appearance: 'success', autoDismiss: true });
      }
      await refreshData();
      setLockData(false);
    } catch (err) {
      // alert(util.inspect(err));
      if (err.response && err.response.data) {
        alert(err.response.data);
      } else {
        alert('エラーが発生しました。');
      }
      setLockData(false);
    }
  }

  return (
    <div className="container">
      {(membershipData === null) && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {membershipData &&
        <div className="row mx-0 my-2 px-1-env">
          <div className="col">
            <div className="row my-2">
              <div className="col-4">
                <div className="row my-2">
                  <div className="col text-left align-self-center">
                    <div className="row h-100">
                      <div className="col text-left align-self-center">
                        <input className={`form-control`} type="text" id={'year'} name={'year'} value={year} onChange={(e) => setYear(e.target.value)}></input>
                      </div>
                      <div className="col-auto text-left align-self-center">
                        年度
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row my-2">
              <div className="col-6">
                <div className="row my-2">
                  <div className={`col-2 text-left align-self-center`}>
                    <span>会期</span>
                  </div>
                  <div className="col text-left align-self-center">
                    <div className="row h-100">
                      <div className="col text-left align-self-center">
                        <DatePicker 
                          className="form-control w-100" 
                          aria_labal="会期（開始）" 
                          locale="ja" 
                          dateFormat="yyyy/MM/dd" 
                          id="membership_start" 
                          name="membership_start" 
                          selected={membershipData.membership_start} 
                          onChange={date => handleChangeDate("membership_start", date)} 
                          showMonthDropdown 
                          maxDate={membershipData.membership_end}
                          autoComplete={"off"}
                          disabled={lockData} />
                      </div>
                      <div className="col-auto text-left align-self-center">
                        ～
                      </div>
                      <div className="col text-left align-self-center">
                        <DatePicker 
                          className="form-control w-100" 
                          aria_labal="会期（終了）" 
                          locale="ja" 
                          dateFormat="yyyy/MM/dd" 
                          id="membership_end" 
                          name="membership_end" 
                          selected={membershipData.membership_end} 
                          onChange={date => handleChangeDate("membership_end", date)} 
                          showMonthDropdown 
                          minDate={membershipData.membership_start}
                          autoComplete={"off"}
                          disabled={lockData} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row my-2">
              <div className="col-6">
                <div className="row my-2">
                  <div className={`col-2 text-left align-self-center`}>
                    <span>更新期間</span>
                  </div>
                  <div className="col text-left align-self-center">
                    <div className="row h-100">
                      <div className="col text-left align-self-center">
                        <DatePicker 
                          className="form-control w-100" 
                          aria_labal="更新期間（開始）" 
                          locale="ja" 
                          dateFormat="yyyy/MM/dd" 
                          id="renewal_start" 
                          name="renewal_start" 
                          selected={membershipData.renewal_start} 
                          onChange={date => handleChangeDate("renewal_start", date)} 
                          showMonthYearDropdown 
                          minDate={new Date((Number(year) - 1) + '-04-01')}
                          maxDate={membershipData.renewal_end || membershipData.membership_start}
                          autoComplete={"off"}
                          disabled={lockData} />
                      </div>
                      <div className="col-auto text-left align-self-center">
                        ～
                      </div>
                      <div className="col text-left align-self-center">
                        <DatePicker 
                          className="form-control w-100" 
                          aria_labal="更新期間（終了）" 
                          locale="ja" 
                          dateFormat="yyyy/MM/dd" 
                          id="renewal_end" 
                          name="renewal_end" 
                          selected={membershipData.renewal_end} 
                          onChange={date => handleChangeDate("renewal_end", date)} 
                          showMonthYearDropdown 
                          maxDate={membershipData.membership_start}
                          minDate={membershipData.renewal_start || new Date((Number(year) - 1) + '-04-01')}
                          autoComplete={"off"}
                          disabled={lockData} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row my-2">
              <div className="col-4">
                <div className="row my-2">
                  <div className={`col-2 text-left align-self-center`}>
                    <span>会費</span>
                  </div>
                  <div className="col text-left align-self-center">
                    <div className="row h-100">
                      <div className="col text-left align-self-center">
                        <input className={`form-control`} type="text" id={'membership_fee'} name={'membership_fee'} value={membershipData.membership_fee} onChange={handleChange} disabled={lockData}></input>
                      </div>
                      <div className="col-auto text-left align-self-center">
                        円
                      </div>
                    </div>
                    {errMsg.membership_fee &&
                      <div className="row h-100">
                        <div className="col text-left align-self-center text-danger">
                          {errMsg.membership_fee}
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="row my-2">
              <div className="col-3">
                <button type="button"
                  className={`btn btn-primary mx-1 w-100`}
                  disabled={checkInvalid() || lockData}
                  onClick={() => handleRegister()}>
                  登録
                </button>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default Membership;