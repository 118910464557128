import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { generatePath } from 'react-router';
import util from 'util';
import constClass from '../../Constants/Constants';
// import Common from '../Common/common';

const NavBar = (props) => {
  const { user, userActions, conditionActions, siteName } = props;
  const history = useHistory();

  const handleTitleClick = () => {
    userActions.refresh();
    history.replace(`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}`);
  }

  const handleLogoutClick = () => {
    localStorage.removeItem('jwt');
    userActions.logout();
    conditionActions.init();
    history.replace(`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}login`);
  }

  // const handlePlanCategoryClick = () => {
  //   userActions.refresh();
  //   history.replace(`${props.match.path}plancategory`);
  // }

  // const handleProductCategoryClick = () => {
  //   userActions.refresh();
  //   history.replace(`${props.match.path}productcategory`);
  // }

  // const handleOrderInputClick = () => {
  //   userActions.refresh();
  //   history.replace(`${props.match.path}order/input`);
  // }

  // const handlePlanClick = () => {
  //   userActions.refresh();
  //   history.replace(`${props.match.path}plan`);
  // }

  // const handleProductClick = () => {
  //   userActions.refresh();
  //   history.replace(`${props.match.path}product`);
  // }

  return (
    <nav className="navbar navbar-light navbar-expand-lg  bg-light fixed-top">
      <Link className="navbar-brand" onClick={handleTitleClick} to={`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}`}>
        {`${siteName}`}<div className='d-none'>{util.inspect(history)}</div>
      </Link>
      {
        user.isLoggedIn &&
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
      }
      {
        user.isLoggedIn &&
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className="nav-link" to={`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}`}>会員一覧</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to={`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}membership`}>会費管理</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to={`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}paymenthistory`}>決済履歴</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to={`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}order`}>注文一覧</Link>
            </li>
            {user.userClass === constClass.USER_CLASS.ADMIN &&
              <React.Fragment>
                <li className="nav-item">
                  <Link className="nav-link" to={`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}product`}>グッズ管理</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}productcategory`}>グッズカテゴリ管理</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}system`}>システム管理</Link>
                </li>
              </React.Fragment>
            }
          </ul>
        </div>
      }
      {
        user.isLoggedIn &&
        <div>
          <label className="mr-2 text-primary">{user.userName}</label>
          <button className="btn btn-dark" onClick={handleLogoutClick}>ログアウト</button>
        </div>
      }
    </nav>
  );
}

export default NavBar;