import React, { useEffect } from 'react';
import axios from 'axios';
import constClass from '../../Constants/Constants';
import BackyardOrderShop from './BackyardOrderShop'
import BackyardOrderList from './BackyardOrderList';
import BackyardOrderProduct from './BackyardOrderProduct';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import ja from 'date-fns/locale/ja';
import Select from 'react-select';
import moment from 'moment';
import BackyardOrderPaymentList from './BackyardOrderPaymentList';
import DatePicker from "react-datepicker"

const BackyardOrderFinTab = (props) => {
  const { user, condition, conditionActions } = props;
  registerLocale('ja', ja);

  // const [index, setIndex] = useState(1);
  const handleTabSelect = (idx, lastIndex, e) => {
    conditionActions.setIndex(idx);
    if (user.userClass === constClass.CLASS.SHOP) { //店舗ユーザは自店舗を設定
      conditionActions.setShop(user.shopId);
    }
  };

  // const [shopMaster, setShopMaster] = useState([]);
  // const [planMaster, setPlanMaster] = useState([]);
  // const [productMaster, setProductMaster] = useState([]);
  // const [shop, setShop] = useState('');
  // const [plan, setPlan] = useState('');
  // const [reciveDate, setReciveDate] = useState(null);
  // const [selectReciveDate, setSelectReciveDate] = useState(null);
  // const [selectStatus, setSelectStatus] = useState([{ value: constClass.ORDER_STATUS.UPAY, label: constClass.ORDER_STATUS_NAME.UPAY },
  // { value: constClass.ORDER_STATUS.REG, label: constClass.ORDER_STATUS_NAME.REG },
  // { value: constClass.ORDER_STATUS.PRE, label: constClass.ORDER_STATUS_NAME.PRE },
  // { value: constClass.ORDER_STATUS.FIN, label: constClass.ORDER_STATUS_NAME.FIN },
  // { value: constClass.ORDER_STATUS.DFIN, label: constClass.ORDER_STATUS_NAME.DFIN }]);
  // const [status, setStatus] = useState([constClass.ORDER_STATUS.UPAY, constClass.ORDER_STATUS.REG, constClass.ORDER_STATUS.PRE, constClass.ORDER_STATUS.FIN, constClass.ORDER_STATUS.DFIN]);
  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);
  // const [isReload, setisReload] = useState(true);

  // タブ変更イベント
  const handleChange = (event) => {
    if (event.target.name === 'shop') {
      conditionActions.setShop(event.target.value);
    } else if (event.target.name === 'plan') {
      conditionActions.setPlan(event.target.value);
      conditionActions.setSelectReceiveDate(null);
      // } else if (event.target.name === 'startdate') {
      //   setStartDate(event.target.value);
      //   if (endDate === null) setEndDate(event.target.value);
      // } else if (event.target.name === 'enddate') {
      //   setEndDate(event.target.value);
      //   if (startDate === null) setStartDate(event.target.value);
    }
  }

  // // 「受取日」変更イベント（セレクト）
  // const handleSelectReceiveDate = (data) => {
  //   if (data.length <= 0) {
  //     conditionActions.setSelectReceiveDate(null);
  //   } else {
  //     conditionActions.setSelectReceiveDate(data);
  //   }
  // }

  // // 「受取日」変更イベント（カレンダー）
  // const handleSelectReceiveDatePicker = (data) => {
  //   const val = moment(new Date(data)).format('YYYY/MM/DD');
  //   conditionActions.setSelectReceiveDate(data ? [{ value: val, lavel: val }] : null);
  // }

  // 「注文日」変更イベント（カレンダー）
  const handleSelectReceiptDateStart = (data) => {
    const val = moment(new Date(data)).format('YYYY/MM/DD');
    conditionActions.setReceiptDateStart(data ? val : null);
  }

  // 「注文日」変更イベント（カレンダー）
  const handleSelectReceiptDateEnd = (data) => {
    const val = moment(new Date(data)).format('YYYY/MM/DD');
    conditionActions.setReceiptDateEnd(data ? val : null);
  }

  // 「発送日」変更イベント（カレンダー）
  const handleSelectShippingDate = (data) => {
    const val = moment(new Date(data)).format('YYYYMMDD');
    conditionActions.setShippingDate(data ? val : null);
  }

  // 「ステータス」変更イベント（セレクト）
  const handleSelectStatus = (data) => {
    if (data.length <= 0) {
      conditionActions.setSelectStatus(null);
    } else {
      conditionActions.setSelectStatus(data);
    }
  }

  const handleIsReload = (data) => {
    conditionActions.setIsReload(data);
  }

  // 「会員番号」変更イベント（テキスト）
  const handleChangeCustomerNo = (e) => {
    conditionActions.setCustomerNo(e.target.value);
  }

  // 初期実行トリガー
  useEffect(() => {
    if (!condition.initFlag) {
      async function fetchData() {
        const jwt = localStorage.getItem('jwt');
        const reg_params = {
          "site_id": props.match.params.siteId,
          "operator": "and",
          "where": [{ "site_id": props.match.params.siteId }]
        }
        const shop = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/shop/search/`, reg_params, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        })).data;
        conditionActions.setShopMaster(shop);
        const plan = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/plan/search/`, reg_params, { // 企画情報取得 
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        })).data;
        conditionActions.setPlanMaster(plan);
        // if (plan.length > 0) {//企画が複数あったら、開催中企画を表示。なければ最新企画を表示
        //   const nowdate = moment().format("YYYYMMDD");
        //   const nowplan = plan.find((item) => item.plan_start <= nowdate && item.plan_end >= nowdate);
        //   const defaultplan = nowplan ? nowplan.plan_id : plan.slice(-1)[0].plan_id;
        //   conditionActions.setPlan(defaultplan);
        // }
        const product = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/product/search/`, reg_params, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        })).data;
        conditionActions.setProductMaster(product);
        if (user.userClass === constClass.CLASS.SHOP) { //店舗ユーザは自店舗を初期値に設定
          conditionActions.setShop(user.shopId);
        }
        conditionActions.setInitFlag();
      }
      fetchData();
    }
    return () => {
      conditionActions.setIsReload(true);
    }
  }, [props.match.params.siteId, user.shopId, user.userClass, conditionActions, condition.initFlag]);

  return (
    <div className="container">
      <div className="row mx-0 my-3 px-1-env">
        <div className="col">
          <div className="row">
            <div className="col-3 d-none">
              <div className="row">
                <div className="col-auto text-center align-self-center">
                  <span>店舗</span>
                </div>
                <div className="col text-center">
                  <select className="custom-select w-100" aria-label="店舗" name="shop" value={condition.shop} onChange={handleChange} disabled={(condition.index === 1 && user.userClass === constClass.CLASS.SHOP) || (condition.index === 2 && user.userClass === constClass.CLASS.SHOP) ? true : false}>
                    <option value="">- 全て -</option>
                    {condition.shopMaster.map((item) =>
                      <option key={item.shop_id} value={item.shop_id}>{item.shop_name}</option>
                    )}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="row">
                <div className="col-auto text-center align-self-center">
                  <span>種別</span>
                </div>
                <div className="col text-center">
                  <select className="custom-select w-100" aria-label="企画" name="plan" value={condition.plan} onChange={handleChange}>
                    <option value="">- 全て -</option>
                    {condition.planMaster.map((item) =>
                      <option key={item.plan_id} value={item.plan_id}>{item.plan_name}</option>
                    )}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-5">
              <div className="row">
                <div className="col-auto text-center align-self-center">
                  <span>注文日</span>
                </div>
                <div className="col text-center">
                  <DatePicker className="custom-select w-100 text-center border" aria_labal="注文日" locale="ja" dateFormat="yyyyMMdd" monthsShown="1" id="receiptDateStart" name="receiptDateStart"
                    popperPlacement='bottom'
                    popperModifiers={[
                      {
                        name: 'flip',
                        options: {
                          fallbackPlacements: ['bottom'],
                        },
                      }
                    ]}
                    selected={condition.receiptDateStart ? new Date(condition.receiptDateStart) : null}
                    onChange={handleSelectReceiptDateStart}
                    placeholderText="- 選択 -"
                    isClearable={true}
                    customInput={<button>{condition.receiptDateStart ? condition.receiptDateStart : "- 選択 -"}</button>}
                  />
                </div>
                <div className="col-auto text-center align-self-center">
                  <span>～</span>
                </div>
                <div className="col text-center">
                  <DatePicker className="custom-select w-100 text-center border" aria_labal="注文日" locale="ja" dateFormat="yyyyMMdd" monthsShown="1" id="receiptDateEnd" name="receiptDateEnd"
                    popperPlacement='bottom'
                    popperModifiers={[
                      {
                        name: 'flip',
                        options: {
                          fallbackPlacements: ['bottom'],
                        },
                      }
                    ]}
                    selected={condition.receiptDateEnd ? new Date(condition.receiptDateEnd) : null}
                    onChange={handleSelectReceiptDateEnd}
                    placeholderText="- 選択 -"
                    isClearable={true}
                    customInput={<button>{condition.receiptDateEnd ? condition.receiptDateEnd : "- 選択 -"}</button>}
                  />
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="row">
                <div className="col-auto text-center align-self-center">
                  <span>発送日</span>
                </div>
                <div className="col text-center">
                  <DatePicker className="custom-select w-100 text-center border" aria_labal="発送日" locale="ja" dateFormat="yyyyMMdd" monthsShown="1" id="shippingDate" name="shippingDate"
                    popperPlacement='bottom'
                    popperModifiers={[
                      {
                        name: 'flip',
                        options: {
                          fallbackPlacements: ['bottom'],
                        },
                      }
                    ]}
                    selected={condition.shippingDate ? moment(condition.shippingDate, 'YYYYMMDD').toDate() : null}
                    onChange={handleSelectShippingDate}
                    placeholderText="- 選択 -"
                    isClearable={true}
                    customInput={<button>{condition.shippingDate ? condition.shippingDate : "- 選択 -"}</button>}
                  />
                </div>
              </div>
            </div>
            {condition.index === 0 &&
              <div className="col-3"></div>
            }
            {(condition.index === 1 || condition.index === 2 || condition.index === 3 || condition.index === 4) &&
              <div className="col-8">
                <div className="row">
                  <div className="col-auto text-center align-self-center">
                    <span>ステータス</span>
                  </div>
                  <div className="col text-center">
                    <Select
                      styles={{ container: (base) => ({ ...base, zIndex: 500 }) }}
                      options={[
                        { value: constClass.ORDER_STATUS.REG, label: constClass.ORDER_STATUS_NAME.REG },
                        // { value: constClass.ORDER_STATUS.PRE, label: constClass.ORDER_STATUS_NAME.PRE },
                        // { value: constClass.ORDER_STATUS.FIN, label: constClass.ORDER_STATUS_NAME.FIN },
                        { value: constClass.ORDER_STATUS.DFIN, label: constClass.ORDER_STATUS_NAME.DFIN },
                        { value: constClass.ORDER_STATUS.CCL, label: constClass.ORDER_STATUS_NAME.CCL },
                        { value: constClass.ORDER_STATUS.UPAY, label: constClass.ORDER_STATUS_NAME.UPAY },
                        { value: constClass.ORDER_STATUS.CUPAY, label: constClass.ORDER_STATUS_NAME.CUPAY },
                      ]}
                      // isClearable={false}
                      isMulti
                      closeMenuOnSelect={false}
                      value={condition.selectStatus}
                      placeholder={'選択...'}
                      backspaceRemovesValue={false}
                      // controlShouldRenderValue={false}
                      hideSelectedOptions={false}
                      onChange={handleSelectStatus} />
                  </div>
                </div>
              </div>
            }
            <div className="col-3">
              <div className="row">
                <div className="col-auto text-center align-self-center">
                  <span>会員番号</span>
                </div>
                <div className="col text-center">
                  <input className={`form-control`} type="text" id="customerNo" name="customerNo" value={condition.customerNo || ''} onChange={handleChangeCustomerNo}></input>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-1 text-center">
          <button type="button"
            className={`btn btn-primary mx-1 w-100`}
            onClick={() => handleIsReload(true)}>
            検索</button>
        </div>
      </div>

      {/* ジャンル別一覧表示選択 */}
      <Tabs onSelect={handleTabSelect} defaultIndex={condition.index}>
        <TabList className="nav nav-pills mb-2 d-none">
          <Tab className="nav-item"><div className={`nav-link btn ${condition.index === 0 ? 'active' : ''}`} href="#">店舗別企画商品別</div></Tab>
          <Tab className="nav-item"><div className={`nav-link btn ${condition.index === 1 ? 'active' : ''}`} href="#">注文一覧(店舗受取)</div></Tab>
          <Tab className="nav-item"><div className={`nav-link btn ${condition.index === 2 ? 'active' : ''}`} href="#">注文一覧(商品別)</div></Tab>
          <Tab className="nav-item"><div className={`nav-link btn ${condition.index === 3 ? 'active' : ''}`} href="#">注文一覧(配送)</div></Tab>
          <Tab className="nav-item"><div className={`nav-link btn ${condition.index === 4 ? 'active' : ''}`} href="#">決済データ一覧</div></Tab>
        </TabList>
        <TabPanel>
          <BackyardOrderShop {...props} /> {/* 店舗別企画商品別 */}
        </TabPanel>
        <TabPanel>
          <BackyardOrderList {...props} delivery_flag={constClass.FLAG.OFF} /> {/* 注文一覧 */}
        </TabPanel>
        <TabPanel>
          <BackyardOrderProduct {...props} /> {/* 注文一覧（商品別） */}
        </TabPanel>
        <TabPanel>
          <BackyardOrderList {...props} delivery_flag={constClass.FLAG.ON} />
        </TabPanel>
        <TabPanel>
          <BackyardOrderPaymentList {...props} />
        </TabPanel>
      </Tabs>
    </div>
  )
}

export default BackyardOrderFinTab;