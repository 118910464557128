import React from 'react';
import constClass from '../../Constants/Constants';
import Common from '../Common/common.js';
import Popover from "react-popover";

const ProductList = ({ site_id, products, disabled, message, receive_shop, displayCartCount, addCart, subCart, setting, getDetailNotice, setDetailNotice, message_notice }) => {

  const getDateString = (date = null) => {
    const sysdate = new Date();
    if (date === null) {
      date = sysdate;
    }
    return String(date.getFullYear()) + (('00' + String(date.getMonth() + 1)).slice(-2)) + (('00' + String(date.getDate())).slice(-2));
  }

  const checkEarlyDiscount = (product) => {
    return product.early_discount_start <= getDateString() && product.early_discount_end >= getDateString();
  }

  const getShortDateString = (date) => {
    date = date.replace(/-/g, '');
    return Number(date.substr(4, 2)).toString() + "/" + Number(date.substr(6, 2)).toString();
  }

  return (
    <div className="row mx-0">
      <div className="col px-0">
        {products.map((item, idx) => // 早割対象フィルタ
          <div key={item.product_id}>
            <div className="row mx-0 bg-white px-3-env pt-2 pb-2 border-bottom">
              <div className="col bg-white py-1 px-1 align-self-center">
                <div className="row mx-0 text-center">
                  {item.product_image &&
                    <div className="col px-0 bg-white">
                      <img className="w-75" src={`/${site_id}${constClass.PRODUCT_IMAGE_DIR}${item.product_image}`} alt={item.product_name} />
                    </div>
                  }
                </div>
                {checkEarlyDiscount(item) &&
                  <div className="row mx-0 text-left">
                    <div className="col px-0 bg-white">
                      <span className="badge badge-warning p-1 align-middle">早割対象商品</span>
                      <span className="font-weight-normal px-2 align-middle">{getShortDateString(item.early_discount_end)}までのご注文で早割適用</span>
                    </div>
                  </div>
                }
                {item.vip_flag === constClass.FLAG.ON &&
                  <div className="row mx-0 text-left">
                    <div className="col px-0 bg-white">
                      <span className="badge badge-warning p-1 align-middle">エスペランサ限定</span>
                    </div>
                  </div>
                }
                <div className="row mx-0 text-left">
                  <div className="col-9 px-0 bg-white text-info">{item.product_name}</div>
                  <div className="col-3 px-0 bg-white text-right">
                    &yen;{Common.getSettingValue(setting, 'TAX_INCLUDED_FLAG', '0') === '1' ? Math.floor(item.price).toLocaleString() + '(税込)' : Math.floor(item.price_before_tax).toLocaleString()}<br />
                    {Common.getSettingValue(setting, 'TAX_INCLUDED_FLAG', '0') !== '1' && <small>&yen;{item.price.toLocaleString()}(税込)</small>}
                  </div>
                </div>
                <div className="row mx-0 text-left">
                  <div className="col px-0 bg-white font-weight-normal">{Common.getBrString(item.product_detail)}</div>
                </div>
                {/* ご購入時ポイント表示 */}
                {item.point > 0 &&
                  <div className="row mx-0 text-left">
                    <div className="col px-0 bg-white text-danger">{Common.getSettingValue(setting, 'POINT_BUY_FRONTSTRING', '')}{item.point}{Common.getSettingValue(setting, 'POINT_BUY_BACKSTRING', '')}</div>
                  </div>
                }
                <div className="row mx-4">
                  <div className="col-auto text-center p-1 ml-1">
                    <button
                      disabled={((item.year_round_type !== "1" || item.stock > 0) && (item.stock - item.order_count) <= 0) || disabled || item.m_ng_shop.map(s => s.shop_id).includes(receive_shop)}
                      className={`btn btn-digitalcard w-100 h-100 py-0 px-2`}
                      onClick={() => { subCart(item.product_id) }}>
                      －
                    </button>
                  </div>
                  <div className="col text-center py-1 px-1 align-self-center">
                    <div className="bg-white border-textbox">
                      <h4 className="my-1">
                        {displayCartCount(item.plan_id, item.product_id)}
                      </h4>
                    </div>
                  </div>
                  <div className="col-auto text-center p-1 mr-1">
                    <Popover
                      isOpen={message[item.product_id] !== undefined}
                      place='below'
                      body={<div className="section-white border text-danger px-3">{message[item.product_id]}</div>}>
                      <button
                        disabled={((item.year_round_type !== "1" || item.stock > 0) && (item.stock - item.order_count) <= 0) || disabled || item.m_ng_shop.map(s => s.shop_id).includes(receive_shop)}
                        className={`btn btn-digitalcard w-100 h-100 py-0 px-2`}
                        onClick={() => { addCart(item.product_id) }}>
                        ＋
                      </button>
                    </Popover>
                  </div>
                </div>

                {(item.notice_flag === constClass.FLAG.ON && Number(displayCartCount(item.plan_id, item.product_id)) > 0) &&
                  <React.Fragment>
                    <div className="row mx-0 text-left">
                      <div className="col-auto px-1 align-self-center">備考</div>
                      <div className="col px-2"><input type="text" className="form-control border-textbox" id={`notice_${item.product_id}`} name="notice[]" value={getDetailNotice(item.plan_id, item.product_id) || ""} onChange={(e) => setDetailNotice(item.plan_id, item.product_id, e.target.value)}></input></div>
                    </div>
                    {!!message_notice[item.product_id] &&
                      <div className="row mx-0 text-center">
                        <div className="col px-2 text-danger font-weight-normal">{message_notice[item.product_id]}</div>
                      </div>
                    }
                  </React.Fragment>
                }
                {/*<div className="row mx-0 text-center">
                  <div className="col px-0 bg-white font-weight-normal text-danger">{message[item.product_id]}</div>
              </div>*/}
              </div>
            </div>
            {/* <div className="row mx-0 bg-white px-3-env pt-1 pb-2">
              <div className="col">
              </div>
            </div> */}
          </div>
        )}
      </div>
    </div>
  );
}

export default ProductList;