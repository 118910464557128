import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import util from 'util';
import constClass from '../../Constants/Constants';
import { CSVLink } from "react-csv";
import Common from '../Common/common.js';

// 店舗別企画商品別
const BackyardOrderShop = (props) => {
  const { user, condition, conditionActions, settings } = props;
  const [shopData, setShopData] = useState(null);
  const [productData, setProductData] = useState(null);
  const [orderData, setOrderData] = useState(null);
  const [csvData, setCsvData] = useState(null);
  const [ready, setReady] = useState(false);
  
  // 「注文情報印刷」data
  const createCsvdata = useCallback((shop, product, order) => {
    const tax_included = (Common.getSettingValue(settings, 'TAX_INCLUDED_FLAG', '0') === '1');
    var data = [];
    var head = [];
    head.push({ label: "商品", key: "product" });
    head.push({ label: "合計", key: "product_count" });
    if (tax_included) {
      head.push({ label: "合計金額", key: "product_total" });
    } else {
      head.push({ label: "税抜合計金額", key: "product_total" });  
    }
    shop.forEach((s, idx) => {
      head.push({ label: s.shop_name.replace(/"/g, '""'), key: "shop" + idx });
    })
    product.forEach(p => {
      var count = { product: p.product_name.replace(/"/g, '""') };
      count = { ...count, product_count: order.filter(o => o.product_id === p.product_id).reduce((sum, o) => sum + Number(o.total_count), 0) };
      count = { ...count, product_total: order.filter(o => o.product_id === p.product_id).reduce((sum, o) => sum + (Number(o.total_count) * Math.floor(tax_included ? p.price : p.price_before_tax)), 0) };
      shop.forEach((shop, idx) => {
        const c = order.find(o => o.receive_shop === shop.shop_id && o.product_id === p.product_id) ? order.find(o => o.receive_shop === shop.shop_id && o.product_id === p.product_id).total_count : 0
        count = ({ ...count, ["shop" + idx]: c });
      });
      data.push(count);
    });
    var count = { product: '合計' };
    count = { ...count, product_count: order.reduce((sum, o) => sum + Number(o.total_count), 0) };
    count = { ...count, product_total: order.reduce((sum, o) => sum + (product.find(p => p.product_id === o.product_id)) ? (Number(o.total_count) * Math.floor(tax_included ? product.find(p => p.product_id === o.product_id).price : product.find(p => p.product_id === o.product_id).price_before_tax)) : 0 , 0) };
    shop.forEach((shop, idx) => {
      const c = order.filter(o => o.receive_shop === shop.shop_id).reduce((sum, o) => sum + Number(o.total_count), 0)
      count = ({ ...count, ["shop" + idx]: c });
    });
    data.push(count);
    if (tax_included) {
      count = { product: '合計金額' };
    } else {
      count = { product: '税抜合計金額' };
    }
    shop.forEach((shop, idx) => {
      const c = order.filter(o => o.receive_shop === shop.shop_id).reduce((sum, o) => sum + (Number(o.total_count) * Math.floor(tax_included ? product.find(p => p.product_id === o.product_id).price : product.find(p => p.product_id === o.product_id).price_before_tax)), 0).toLocaleString()
      count = ({ ...count, ["shop" + idx]: c });
    });
    data.push(count);
    setCsvData({ header: head, list: data });
  }, [settings]);

  const refreshOrder = useCallback(async () => {
    const jwt = localStorage.getItem('jwt');
    if (condition.isReload) {
      setReady(false);
      // console.log(props);
      var shopParams = { "site_id": props.match.params.siteId, "operator": "and", "where": [{ "site_id": props.match.params.siteId }] };
      var productParams = { "site_id": props.match.params.siteId, "operator": "and", "where": [{ "site_id": props.match.params.siteId }] };
      // 店舗
      if (condition.shop !== "") {
        shopParams.where.push({ "shop_id": condition.shop });
      }
      // 企画
      if (condition.plan !== "") {
        productParams.where.push({ "plan_id": condition.plan });
      }
      const params = {
        ...condition,
        site_id: props.match.params.siteId,
        receiveDate: condition.receiveDate,
      }
      const order = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/order/shop_product/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      })).data;
      //data.sort((a, b) => a.order_id - b.order_id);
      setOrderData(order);

      const shop = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/shop/search/`, shopParams, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      })).data;
      shop.sort((a, b) => a.shop_id - b.shop_id);
      setShopData(shop);

      const product = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/product/search/`, productParams, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      })).data;
      product.sort((a, b) => a.plan_id - b.plan_id ? a.plan_id - b.plan_id : a.product_id - b.product_id);
      setProductData(product);
      //getNewNumber();
      setReady(true);

      createCsvdata(shop, product, order);
      conditionActions.setIsReload(false);
    }
  }, [props.match.params.siteId, condition, conditionActions, createCsvdata]);

  // 初期実行トリガー
  useEffect(() => {
    refreshOrder();
  }, [refreshOrder]);

  return (
    <div className="container">
      <div className="row d-none"><div className="col-12">{util.inspect(orderData)}</div></div>
      <div className="row d-none"><div className="col-12">{util.inspect(user)}</div></div>
      {(shopData === null || productData === null || orderData === null || !ready) && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {(shopData !== null && productData !== null && orderData !== null && ready) && (
        <React.Fragment>
          {csvData && <div className="row mb-3 p-0">
            <div className="col-12 p-0 text-lef talign-self-end">
              <CSVLink className="btn btn-primary mb-1 px-4" data={csvData.list} headers={csvData.header} filename={"店舗別企画商品別.csv"}>
                CSVダウンロード
              </CSVLink>
            </div>
          </div>
          }
          <div className="row mb-3 p-0">
            <div className="col-12 p-0">
              <table className="table table-bordered table-striped sticky_table">
                <thead className={`table-${constClass.COLOR.BUS}`}>
                  <tr>
                    <th>
                    </th>
                    {(props.condition.shop === "") &&
                      <th className="total">
                        <span>合計</span>
                      </th>
                    }
                    <th className="total">
                      <span>{Common.getSettingValue(settings, 'TAX_INCLUDED_FLAG', '0') === '1' ? '合計金額' : '税抜合計金額'}</span>
                    </th>
                    {shopData.map((shop, idx) => (
                      <th key={'shop' + shop.shop_id}>
                        <span>{shop.shop_name}</span>
                      </th>
                    ))
                    }
                  </tr>
                </thead>
                <tbody>
                  {productData.map((product, idx) => (
                    <tr key={'product' + product.product_id}>
                      {/* 商品名 */}
                      <th>
                        {product.product_name}
                      </th>
                      {(props.condition.shop === "") &&
                        <td className="text-center total">
                          {orderData.filter(o => o.product_id === product.product_id) ? orderData.filter(o => o.product_id === product.product_id).reduce((sum, element) => sum + Number(element.total_count), 0) : 0}
                        </td>
                      }
                      <td className="text-center total">
                        {orderData.filter(o => o.product_id === product.product_id) ? orderData.filter(o => o.product_id === product.product_id).reduce((sum, element) => sum + Number(element.total_count) * Math.floor(Common.getSettingValue(settings, 'TAX_INCLUDED_FLAG', '0') === '1' ? product.price : product.price_before_tax), 0).toLocaleString() : 0}
                      </td>
                      {shopData.map((shop, idx) => (
                        <td key={'order' + product.product_id + '_' + shop.shop_id} className="text-center">
                          {orderData.find(o => o.receive_shop === shop.shop_id && o.product_id === product.product_id) ? orderData.find(o => o.receive_shop === shop.shop_id && o.product_id === product.product_id).total_count : 0}
                        </td>
                      ))
                      }
                    </tr>
                  ))
                  }
                  <tr key={'product_count'}>
                    <th className="text-right">
                      合計
                    </th>
                    {(props.condition.shop === "") &&
                      <td className="text-center total">
                        {orderData.reduce((sum, element) => sum + Number(element.total_count), 0)}
                      </td>
                    }
                      <td className="text-center total">
                        {orderData.reduce((sum, element) => sum + Number(element.total_count) * Math.floor((!productData) && productData.find(p => p.product_id === element.product_id).price_before_tax), 0).toLocaleString()}
                      </td>
                    {shopData.map((shop, idx) => (
                      <td key={'order_total_' + shop.shop_id} className="text-center">
                        {orderData.find(o => o.receive_shop === shop.shop_id) ? orderData.find(o => o.receive_shop === shop.shop_id).total_count : 0}
                      </td>
                    ))
                    }
                  </tr>
                  <tr key={'product_total'}>
                    <th className="text-right">
                      {Common.getSettingValue(settings, 'TAX_INCLUDED_FLAG', '0') === '1' ? '合計金額' : '税抜合計金額'}
                    </th>
                    {(props.condition.shop === "") &&
                      <td className="text-center total">

                      </td>
                    }
                    <td className="text-center total">

                    </td>
                    {shopData.map((shop, idx) => (
                      <td key={'order_total_' + shop.shop_id} className="text-center">
                        {orderData.filter(o => o.receive_shop === shop.shop_id).reduce((sum, o) => sum + (Number(o.total_count) * Math.floor(Common.getSettingValue(settings, 'TAX_INCLUDED_FLAG', '0') === '1' ? productData.find(p => p.product_id === o.product_id).price : productData.find(p => p.product_id === o.product_id).price_before_tax)), 0).toLocaleString()}
                      </td>
                    ))
                    }
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  )
}

export default BackyardOrderShop;