import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Setting from './Setting';
import User from './User';

const SystemTab = (props) => {

  const [index, setIndex] = useState(0);
  const handleTabSelect = (idx, lastIndex, e) => {
    setIndex(idx);
  };

  // 初期実行トリガー

  return (
    <div className="container">

      <Tabs onSelect={handleTabSelect} defaultIndex={index}>
        <TabList className="nav nav-pills mb-2">
          <Tab className="nav-item"><div className={`nav-link btn ${index === 0 ? 'active' : ''}`} href="#">システム管理</div></Tab>
          <Tab className="nav-item"><div className={`nav-link btn ${index === 1 ? 'active' : ''}`} href="#">ユーザ管理</div></Tab>
        </TabList>
        <TabPanel>
          <Setting {...props} /> {/* システム管理 */}
        </TabPanel>
        <TabPanel>
          <User {...props} /> {/* ユーザ管理 */}
        </TabPanel>
      </Tabs>
    </div>
  )
}

export default SystemTab;